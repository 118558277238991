import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
 Card, CardBody, Badge, UncontrolledTooltip,
} from 'reactstrap';

import { Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { formatCurrency, getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';
import { getCls } from '@redux/actions/clsActions';

import CashLedgerTableSearch from './CashLedgerTableSearch';

const Currency = ({ value, id }) => (
  <div className="column-end">
    <span className={`${value < 0 ? 'text-danger' : ''}`} id={id}>
      {formatCurrency(Math.abs(value), 2)}
    </span>
  </div>
);

const renderHeaderName = headerName => (
  <span className="column-end">{headerName}</span>
);

const CashLedgerTable = ({ refresh, setRefresh, setPendingRecords }) => {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const history = useHistory();
  const cashledgerData = useSelector(state => state.cls);
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [submitSearch, setSubmitSearch] = useState(false);
  const [dateRangeInput, setDateRangeInput] = useState('Today');
  const page = getQueryParam('page');
  const [effectiveStartDateInput, setEffectiveStartDateInput] = useState(
    getQueryParam('effectiveStartDate'),
  );
  const [effectiveEndDateInput, setEffectiveEndDateInput] = useState(
    getQueryParam('effectiveEndDate'),
  );
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const { offset, pageNumber } = getPagination(pageSize, page);
  const {
    requesting,
    err,
    cls: { dailysummary },
  } = cashledgerData || {};

  useEffect(() => {
    setPendingRecords(dailysummary?.numPending);
  }, [dailysummary]);

  const redirectUrl = useCallback(
    (id = '') => {
      const query = buildQueryParams({
        dateRangeInput,
        effectiveStartDate: effectiveStartDateInput,
        effectiveEndDate: effectiveEndDateInput,
        pageSize,
      });
      return `/cash-ledger${
        id ? `/${id}/edit?from=${pageNumber}` : '?page=1'
      }${query}`;
    },
    [
      dateRangeInput,
      effectiveEndDateInput,
      effectiveStartDateInput,
      pageNumber,
      pageSize,
    ],
  );

  useEffect(() => {
    if (submitSearch) {
      const pageReload = redirectUrl();
      history.push(pageReload);
    } else if (token) {
      dispatch(
        getCls(
          token,
          pageSize,
          offset,
          dateRangeInput,
          effectiveStartDateInput,
          effectiveEndDateInput,
        ),
      );
    }
    setRefresh(false);
    setSubmitSearch(false);
  }, [token, pageSize, offset, submitSearch, refresh]);

  const pageProps = useMemo(
    () => ({
      total: dailysummary?.recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [dailysummary?.recordCount, pageSize, pageNumber],
  );

  const cashLedgerColumn = useMemo(
    () => [
      {
        id: 'effectiveDate',
        header: 'Date',
        accessorFn: row => formatDate(row?.effectiveDate),
      },
      {
        id: 'summaryCashIn',
        header: () => renderHeaderName('Cash In'),
        cell: (tableRow) => {
          const { summaryCashIn } = tableRow.row.original || {};
          return <Currency value={summaryCashIn} />;
        },
      },
      {
        id: 'summaryCashOut',
        header: () => renderHeaderName('Cash Out'),
        cell: (tableRow) => {
          const { summaryCashOut } = tableRow.row.original || {};
          return <Currency value={summaryCashOut} />;
        },
      },
      {
        id: 'summaryAmount',
        header: () => renderHeaderName('Total'),
        cell: (tableRow) => {
          const { summaryAmount } = tableRow.row.original || {};
          return <Currency value={summaryAmount} />;
        },
      },
      {
        id: 'adjustmentAmount',
        header: () => renderHeaderName('Adjustment'),
        cell: (tableRow) => {
          const { adjustmentAmount, cashSummaryID, adjustmentNotes } = tableRow.row.original || {};
          return (
            <div>
              <Currency
                id={`tooltip_${cashSummaryID}`}
                value={adjustmentAmount}
              />
              {adjustmentNotes ? (
                <UncontrolledTooltip
                  placement="top"
                  target={`tooltip_${cashSummaryID}`}
                >
                  {adjustmentNotes}
                </UncontrolledTooltip>
              ) : null}
            </div>
          );
        },
      },
      {
        id: 'beginningBalance',
        header: () => renderHeaderName('Beginning'),
        cell: (tableRow) => {
          const { beginningBalance } = tableRow.row.original || {};
          return <Currency value={beginningBalance} />;
        },
      },
      {
        id: 'endingBalance',
        header: () => renderHeaderName('Ending'),
        cell: (tableRow) => {
          const { endingBalance } = tableRow.row.original || {};
          return <Currency value={endingBalance} />;
        },
      },
      {
        id: 'Edit-View',
        header: '',
        size: 250,
        cell: (tableRow) => {
          const { cashSummaryID } = tableRow.row.original || {};
          const navigateToEditPage = redirectUrl(cashSummaryID);
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToEditPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View/Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [redirectUrl],
  );

  return (
    <Spinner requesting={requesting}>
      <Card className="pb-2">
        <CardBody className="py-2 bg-green">
          <CashLedgerTableSearch
            effectiveStartDateInput={effectiveStartDateInput}
            effectiveEndDateInput={effectiveEndDateInput}
            setEffectiveStartDateInput={setEffectiveStartDateInput}
            setEffectiveEndDateInput={setEffectiveEndDateInput}
            setSubmitSearch={setSubmitSearch}
            dateRangeInput={dateRangeInput}
            setDateRangeInput={setDateRangeInput}
          />
        </CardBody>
      </Card>
      <Table
        columns={cashLedgerColumn}
        data={dailysummary?.cashLedgerSummaryResponse}
        pageProps={pageProps}
        errStatus={err}
      />
    </Spinner>
  );
};

export default CashLedgerTable;
