import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { getSingleWft1008 } from '@redux/actions/wft1008Actions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const additionalFormFields = [
  { label: 'withdrawal Amount', name: 'withdrawalAmount' },
  { label: 'withdrawal Percent', name: 'withdrawalPercent' },
  { label: 'withdrawal Type', name: 'withdrawalType' },
  { label: 'withdrawal Method', name: 'withdrawalMethod' },
  { label: 'Terminate Policy', name: 'terminatePolicy' },
  { label: 'Net Amount', name: 'netAmount' },
  { label: 'Fee Amount', name: 'feeAmount' },
  { label: 'MVA Amount', name: 'mvaAmount' },
];

const WorkflowTypeID1008 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, logout } = useAuth();
  const workflowId1008 = useSelector(state => state.wft1008);
  const { showErrorToast, showSuccessToast } = useToast();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getSingleWft1008(token, workflowId));
    }
    setValid(true);
    dispatch(reset());
  }, [workflowId]);

  const data = workflowId1008?.wft1008 || null;

  useEffect(() => {
    if (valid) {
      if (workflowId1008?.err) {
        if (unauthorizedError(workflowId1008)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(workflowId1008.err || msgs.error);
      } else if (workflowId1008?.success) {
        showSuccessToast();
        history.push('/workflows');
      }
    }
  }, [valid, workflowId1008]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Premium Withdrawal</h3>

      <FormSpinner requesting={workflowId1008?.requesting} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={data}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1008;
