const RKChannelsTableData = () => {
  const header = [
    { id: 1, title: '#' },
    { id: 2, title: 'First Name' },
    { id: 3, title: 'Last Name' },
    { id: 4, title: 'Username' },
    { id: 5, title: 'Status' },
  ];

  const headerResponsive = [
    { id: 1, title: '#' },
    { id: 2, title: 'RK Channel Name' },
    { id: 3, title: 'Accept new Record Keepers' },
    { id: 4, title: 'Accept New Plans' },
    { id: 5, title: 'Active' },
    { id: 6, title: '' },
  ];

  const rows = [{
    id: 1,
    rkChannelName: 'Maria',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: false,
  }, {
    id: 2,
    rkChannelName: 'Bobby',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: false,
  }, {
    id: 3,
    rkChannelName: 'Alexander',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: true,
  }, {
    id: 4,
    rkChannelName: 'Vera',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: true,
  }, {
    id: 5,
    rkChannelName: 'Maria',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: true,
  }, {
    id: 6,
    rkChannelName: 'Bobby',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: true,
  }, {
    id: 7,
    rkChannelName: 'Alexander',
    acceptNewRecordKeepers: true,
    acceptNewPlans: true,
    active: false,
  }];

  const rkChannelsTableData = {
    tableHeaderData: header, tableHeaderResponsiveData: headerResponsive, tableRowsData: rows,
  };
  return rkChannelsTableData;
};

export default RKChannelsTableData;
