import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getSinglePlan } from '@redux/actions/plansActions';
import { createPlanProduct } from '@redux/actions/ppsActions';
import { getProducts } from '@redux/actions/productsActions';

import PlanProductForm from '../components/PlanProductForm';

const defaultValues = {
  productID: localStorage.getItem('productID'),
};

const AddPlanProduct = (props) => {
  const {
    match: {
      params: { planId },
    },
  } = props;

  const addPlanProductForm = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { token, logout } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const [submitting, setSubmitting] = useState(false);

  const plans = useSelector(state => state.plans);
  const products = useSelector(state => state.products);
  const planProducts = useSelector(state => state.pps);

  const pageNumber = getQueryParam('from');
  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');
  const pageSize = getQueryParam('pageSize');

  const isLoading = plans?.requesting || products?.requesting;
  const { authError, error, success } = msgs || {};
  const dataProps = {
      plansData: plans,
      productsData: products,
    } || {};

  useEffect(() => {
    if (token) {
      dispatch(getSinglePlan(planId, token));
      dispatch(getProducts(token));
    }
  }, [planId]);

  const queryParams = buildQueryParams({
    search,
    sortPlanName,
    sortStatus,
    pageSize,
  });

  const navigatePlanDashboard = `/plans?page=${pageNumber}&id=${planId}${queryParams}`;

  useEffect(() => {
    if (submitting) {
      if (planProducts?.err) {
        if (unauthorizedError(planProducts)) {
          logout();
          enqueueSnackbar(authError, {
            variant: 'error',
            autoHideDuration: 5000,
          });
          history.push('/');
        } else {
          enqueueSnackbar(planProducts.err || error, {
            variant: 'error',
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
          setSubmitting(false);
        }
      } else if (planProducts?.success) {
        enqueueSnackbar(success, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push(navigatePlanDashboard);
      }
    }
  }, [navigatePlanDashboard, planProducts, submitting]);

  useEffect(() => {
    if (plans?.err || products?.err) {
      if (unauthorizedError(plans) || unauthorizedError(products)) {
        logout();
        enqueueSnackbar(authError, {
          variant: 'error',
          persist: true,
          action: key => (
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              OK
            </Button>
          ),
        });
        history.push('/');
      }
      enqueueSnackbar(plans.err || products.err || error, {
        variant: 'error',
        persist: true,
        preventDuplicate: true,
        action: key => <Button onClick={() => closeSnackbar(key)}>OK</Button>,
      });
    }
  }, [plans, products]);

  const handleFormSubmit = useCallback(
    (values) => {
      values.status = 'Active';
      values.carrierPlanIdentifier = sanitizeValue(
        values.carrierPlanIdentifier,
      );
      setSubmitting(true);
      dispatch(createPlanProduct(planId, values, token));
    },
    [planId],
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Product Detail</h3>
      <PlanProductForm
        form={addPlanProductForm}
        data={dataProps}
        planId={planId}
        onSubmit={handleFormSubmit}
        loadingResponse={isLoading}
      />
    </Container>
  );
};

export default AddPlanProduct;
