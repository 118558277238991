import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  accountSubaccounts: {},
};

const accountSubaccountsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.ACCOUNT_SUBACCOUNTS_REQUESTING:
    return {
      ...state, requesting: true, err: '', accountSubaccounts: '',
    };

  case ActionTypes.ACCOUNT_SUBACCOUNTS_ERROR:
    return {
      ...state,
      requesting: false,
      err: payload,
      accountSubaccounts: '',
    };

  case ActionTypes.ACCOUNT_SUBACCOUNTS_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      accountSubaccounts: payload,
    };

  default:
    return state;
  }
};

export default accountSubaccountsReducer;
