import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';

import appConfigReducer from '@redux/reducers/appConfigReducer';
import dailySettlementSummaryReducer from '@redux/reducers/dailySettlementSummaryReducer';
import {
  themeReducer,
  rtlReducer,
  cryptoTableReducer,
  customizerReducer,
  newOrderTableReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  rkChannelsReducer,
  carriersReducer,
  productsReducer,
  productTypeReducer,
  custodiansReducer,
  usersReducer,
  custodianPlansReducer,
  configReducer,
  formFieldsReducer,
  loginReducer,
  annuityReducer,
  rksReducer,
  plansReducer,
  cpReducer,
  epsReducer,
  ppsReducer,
  wfsReducer,
  countriesReducer,
  wftsReducer,
  wft1001Reducer,
  wft1002Reducer,
  pioReducer,
  productFactorReducer,
  pppiosReducer,
  planProductFactorReducer,
  recReducer,
  accountsReducer,
  wft1003Reducer,
  wft1004Reducer,
  wft10061007Reducer,
  wft1008Reducer,
  pawsReducer,
  accountSubaccountsReducer,
  accountAddressesReducer,
  accountPioasReducer,
  accountVaultReducer,
  clsReducer,
  wrsReducer,
  accountPipRatesReducer,
} from '@redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  roundBorders: roundBordersReducer,
  blocksShadows: blocksShadowsReducer,
  appConfig: appConfigReducer,
  cryptoTable: cryptoTableReducer,
  customizer: customizerReducer,
  newOrder: newOrderTableReducer,
  sidebar: sidebarReducer,
  user: authReducer,
  rkChannels: rkChannelsReducer,
  carriers: carriersReducer,
  products: productsReducer,
  productType: productTypeReducer,
  custodians: custodiansReducer,
  users: usersReducer,
  custodianPlans: custodianPlansReducer,
  config: configReducer,
  formFields: formFieldsReducer,
  login: loginReducer,
  annuity: annuityReducer,
  rks: rksReducer,
  plans: plansReducer,
  cp: cpReducer,
  eps: epsReducer,
  pps: ppsReducer,
  wfs: wfsReducer,
  countries: countriesReducer,
  wfts: wftsReducer,
  wft1001: wft1001Reducer,
  wft1002: wft1002Reducer,
  wft1003: wft1003Reducer,
  wft1004: wft1004Reducer,
  wft10061007: wft10061007Reducer,
  wft1008: wft1008Reducer,
  pio: pioReducer,
  productFactor: productFactorReducer,
  pppios: pppiosReducer,
  ppFactor: planProductFactorReducer,
  rec: recReducer,
  accounts: accountsReducer,
  paws: pawsReducer,
  cls: clsReducer,
  accountSubaccounts: accountSubaccountsReducer,
  accountAddresses: accountAddressesReducer,
  accountPioas: accountPioasReducer,
  accountVault: accountVaultReducer,
  wrs: wrsReducer,
  dailySettlementSummary: dailySettlementSummaryReducer,
  accountPipRates: accountPipRatesReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
