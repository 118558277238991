import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  data: '',
  annuityUserToken: '',
  allocationsUpdated: false,
  requestingDocuments: false,
};

const annuityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.ANNUITY_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.ANNUITY_FETCHED:
    return {
      ...state, requesting: false, err: '', data: payload,
    };

  case ActionTypes.ANNUITY_ALLOCATIONS_UPDATED:
    return {
      ...state, allocationsUpdated: true, err: '', data: payload,
    };

  case ActionTypes.ANNUITY_ERROR:
    return {
      ...state, requesting: false, err: payload, data: '',
    };

  case ActionTypes.LOGIN_MC_USER_ERROR:
    return { ...state, err: payload, token: '' };

  case ActionTypes.LOGIN_MC_USER_SUCCESS:
    return { ...state, err: '', annuityUserToken: payload };

  case ActionTypes.LOGOUT_MC_USER_SUCCESS:
    return { ...state, err: '', annuityUserToken: '' };

  case ActionTypes.LOGIN_MC_USER_LOGOUT:
    return { ...state, err: '', annuityUserToken: '' };

  case ActionTypes.ANNUITY_REQUESTING_DOCUMENTS:
    return {
      ...state, requestingDocuments: true, err: '', success: '',
    };

  case ActionTypes.ANNUITY_FETCHED_DOCUMENTS:
    return {
      ...state, requestingDocuments: false, err: '', data: payload,
    };

  case ActionTypes.ANNUITY_ERROR_DOCUMENTS:
    return {
      ...state, requestingDocuments: false, err: payload, data: '',
    };

  default:
    return state;
  }
};

export default annuityReducer;
