import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import RKChannelsTableData from '../components/RKChannelsTableData';
import RKChannelForm from '../RKChannelForm/RKChannelForm';

const { tableRowsData } = RKChannelsTableData();

const EditRKChannel = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const inputData = tableRowsData.find(row => row.id === parseInt(id, 10));
  const data = {
    ...inputData,
    type: 'edit',
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Edit RK Channel</h3>

      <RKChannelForm data={data} />
    </Container>
  );
};

EditRKChannel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default EditRKChannel;
