import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getAccountPipRates = (
  token,
  id, // (token, id, offset = 0, limit = 0) =>
) => async (dispatch) => {
  dispatch({ type: ActionTypes.ACCOUNT_PIP_RATES_REQUESTING });

  await axios
    .get(
      conf.serverURL + `reaccounts/pipRates/${id}`, // ?Offset=${offset}&Limit=${limit}`,
      // `rkc/reaccounts/personalIncomePercent/${reAccountNumber}`,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.ACCOUNT_PIP_RATES_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.ACCOUNT_PIP_RATES_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
