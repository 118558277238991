import { Container } from 'reactstrap';

import WorkflowTypeID1005Form from './components/WorkflowTypeID1005Form';

const WorkflowTypeID1005 = (props) => {
  const {
    match: {
      params: { workflowId },
    },
  } = props;
  return (
    <Container className="form_wrapper">
      <h3 className="text-center mb-3">
        Purchases & Withdrawals Processing Summary
      </h3>
      <WorkflowTypeID1005Form workflowId={workflowId} />
    </Container>
  );
};

export default WorkflowTypeID1005;
