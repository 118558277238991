import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  wft10061007: {},
};

const wft10061007Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WFT10061007_REQUESTING:
    return {
      ...state, requesting: true, err: '', wft10061007: '',
    };

  case ActionTypes.WFT10061007_ERROR:
    return {
      ...state, requesting: false, err: payload, wft10061007: '',
    };

  case ActionTypes.WFT10061007_FETCHED:
    return {
      ...state, requesting: false, err: '', wft10061007: payload,
    };

  default:
    return state;
  }
};

export default wft10061007Reducer;
