import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getCarriers = (token, limit = 0, offset = 0) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CARRIERS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `carriers/get?Offset=${offset}&limit=${limit}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CARRIERS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CARRIERS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const getSingleCarrier = (token, id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CARRIERS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `carriers/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CARRIER_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CARRIERS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const createCarrier = (token, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CARRIERS_REQUESTING,
  });

  await axios
    .post(conf.serverURL + 'carriers/create', data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CARRIER_ADDED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CARRIERS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updateCarrier = (token, id, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CARRIERS_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `carriers/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CARRIER_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CARRIERS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
