import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { sanitizeValue } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { createRecordKeeper } from '@redux/actions/rksActions';

import RecordKeepersForm from '../RecordKeepersForm/RecordKeepersForm';

const rkChannelId = localStorage.getItem('rkChannelID');

const defaultData = {
  rkChannelUserID: rkChannelId,
  recordkeeperName: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  status: 'Active',
  recordkeeperCode: '',
  extRecordkeeperIdentifier: '',
};

const AddRecordKeeper = () => {
  const dispatch = useDispatch();
  const recordKeepers = useSelector(state => state.rks);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const { token, logout } = useAuth();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (valid) {
      if (recordKeepers?.err) {
        if (recordKeepers.err === 401) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(recordKeepers.err || msgs.error);
        }
      } else if (recordKeepers?.success) {
        showSuccessToast();
        history.push('/recordkeepers');
      }
    }
  }, [recordKeepers, valid]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { recordkeeperName, extRecordkeeperIdentifier } = values || {};

    const isValid = validateRequiredField({
      'RecordKeeper Name': recordkeeperName,
      'External RecordKeeper Identifier': extRecordkeeperIdentifier,
    });

    if (!isValid) {
      return;
    }

    dispatch(createRecordKeeper(token, values));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="text-center mb-3">Recordkeeper Detail</h3>
      <RecordKeepersForm
        data={defaultData}
        redirectUrl="/recordkeepers"
        onSubmit={handleForm}
      />
    </Container>
  );
};

export default AddRecordKeeper;
