import { documentsBaseDomain } from '@config/url/documents';

import { getHostEnvironment } from './env';

export const navigateToUrl = (path = '') => {
  const hostEnvironment = getHostEnvironment();
  const currentUrl = hostEnvironment === 'production'
      ? documentsBaseDomain.production
      : documentsBaseDomain.nonProduction;
  return `${currentUrl}/${path}`;
};

export const getDocumentUrl = (path = '') => (path ? `${navigateToUrl('docs')}/${path}` : null);
export const getImageUrl = (path = '') => (path ? `${process.env.PUBLIC_URL}/img/${path}` : null);
