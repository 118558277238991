import { useState } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { conf, msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';

import useToast from './useToast';

const { authError, error } = msgs || {};

const useApiFetch = () => {
  const {
 token, logout, annuityToken, annuityLogout,
} = useAuth();
  const history = useHistory();
  const { showErrorToast, showSuccessToast } = useToast();
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const path = window.location.pathname;
  const isViewAccount = path.includes('/View/Account');

  const handleUnauthorizedError = () => {
    if (isViewAccount) {
      sessionStorage.setItem('validToken', false);
      sessionStorage.removeItem('annuityAuthToken');
      annuityLogout();
    } else {
      logout();
      showErrorToast(authError);
      history.push('/');
    }
  };

  const request = async (method, url, values, redirectUrl) => {
    try {
      setIsPending(true);
      const response = await axios({
        method,
        url: `${conf.serverURL}${url}`,
        data: values,
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      });
      if (response.status === 200) {
        showSuccessToast();
        redirectUrl ? history.push(redirectUrl) : null;
        setIsSuccess(true);
      }
      setIsPending(false);
      return response;
    } catch (err) {
      setIsPending(false);
      if (err.response?.status === 401) {
        handleUnauthorizedError();
      } else {
        showErrorToast(err?.response?.data?.message || error);
      }
    }
  };

  const post = (url, values, redirectUrl) => request('post', url, values, redirectUrl);
  const put = (url, values, redirectUrl) => request('put', url, values, redirectUrl);

  const get = async (url, queryParams) => {
    const fullUrl = `${conf.serverURL}${url}`;

    if (token || annuityToken) {
      try {
        setIsPending(true);
        const response = await axios({
          method: 'get',
          url: fullUrl,
          params: queryParams,
          headers: {
            'Content-Type': 'application/json',
            accessToken: token || annuityToken,
          },
        });
        if (response.status === 200) {
          setIsPending(false);
          return response?.data;
        }
      } catch (err) {
        setIsPending(false);
        if (err.response.status === 401) {
          handleUnauthorizedError();
        } else {
          showErrorToast(err?.response?.data?.message || error);
        }
      }
    } else {
      handleUnauthorizedError();
    }
  };

  return {
    post,
    get,
    put,
    isPending,
    isSuccess,
  };
};

export default useApiFetch;
