import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableDashboard } from '@atoms/index';
import { reset } from '@redux/reducers/formFieldsReducer';

import PurchasesWithdrawalsTable from './components/PurchasesWithdrawalsTable';

const PurchasesWithdrawals = () => {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  // There are no updates to show import-purchase-withdrawal page
  return (
    <TableDashboard
      pageTitle="Purchases &amp; Withdrawals"
      setRefresh={setRefresh}
      // navigateLabel="Import"
      // navigatePath="/purchases-and-withdrawals/import"
    >
      <PurchasesWithdrawalsTable refresh={refresh} setRefresh={setRefresh} />
    </TableDashboard>
  );
};

export default PurchasesWithdrawals;
