import { taxIdFormat } from '@containers/components/helpers';

export const NUMERIC_PATTERN = /^[+-]?(\d*\.\d+|\d+)$/;

export const addCommasToNumber = (value) => {
  if (!value) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const sanitizeNumber = (val, formatOptions = {}) => {
  const { allowDecimal = true, allowNegative = false } = formatOptions;
  const regexPattern = allowNegative
    ? /[^\d.-]|(?!^)-/g
    : allowDecimal
    ? /[^\d.]/g
    : /[^\d]/g;

  let cleanedNumber = String(val).replace(regexPattern, '');

  const leadingZeroPattern = allowDecimal ? /^(-?)0+(?!\.|$)/ : /^(-?)0+(?!$)/;
  cleanedNumber = cleanedNumber.replace(leadingZeroPattern, '');

  if (allowDecimal) {
    const [integerPart, ...decimalParts] = cleanedNumber.split('.');
    cleanedNumber = decimalParts.length > 0
        ? `${integerPart || 0}.${decimalParts.join('')}`
        : integerPart;
  }

  return cleanedNumber;
};

export const formatFixedDigits = (val, len, format = '0') => {
  const valStr = val?.toString() || '';
  return valStr.padStart(len, format);
};

export const maskSSN = (ssn) => {
  const ssnLength = ssn.length;
  if (ssnLength >= 4) {
    return `***-**-${ssn.substr(ssnLength - 4)}`;
  }
  if (ssnLength === 4) {
    return `***-**-${ssn}`;
  }
  return '';
};

export const formatNumber = (value) => {
  if (!value) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getNumberFormat = (value, formatter) => {
  const { shouldPercentage, shouldAmount, formatType } = formatter || {};
  const decimalIndex = value.indexOf('.');
  if (decimalIndex !== -1) {
    value = value.substring(0, decimalIndex + 1)
      + value.substring(decimalIndex + 1).replace(/\./g, '');
  }
  const parts = value.split('.');
  if (parts.length > 1) {
    parts[1] = parts[1].substring(0, 2);
    value = parts.join('.');
  }
  if (value !== '') {
    shouldPercentage && !value.endsWith('%') ? (value += '%') : '';
    shouldAmount && !value.startsWith('$') ? (value = '$ ' + value) : '';
    formatType === 'taxId' ? (value = taxIdFormat(value)) : '';
  }
  return value;
};
