import React, { useState, useEffect } from 'react';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { loginGenericAnnuitantUser } from '@redux/actions/loginActions';

export const AuthorizeExternal = () => {
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loginRes = useSelector(state => state.login);

  useEffect(() => {
    const credentials = {
      loginName: 'GenericAnnuitantUser',
      password: 'fgLGvAuNo$U!n@W2(XhF',
      reAccountNumber: user.REAccountNumber,
    };
    dispatch(loginGenericAnnuitantUser(credentials));
  }, []);

  if (loginRes?.err) {
    enqueueSnackbar(loginRes.err, {
      variant: 'error',
      autoHideDuration: 5000,
    });
  }

  if (loginRes?.token) {
    const reAccountNumber = user.REAccountNumber;

    sessionStorage.setItem('reAccountNumber', user.REAccountNumber);
    sessionStorage.setItem('managedFlag', user.ManagedFlag);
    sessionStorage.setItem('terminationFlag', user.TerminationFlag);
    sessionStorage.setItem('accessToken', loginRes.token);

    // window.location.replace(`http://localhost:3001/View/GenericUserAccount/Summary/${reAccountNumber}?token=${loginRes.token}`);

    const host = window.location.host.toLowerCase();
    const tgt = host === 'localhost:3000'
      ? host.replace('localhost:3000', 'localhost:3000')
      : `${host}`;

    /// /https://happy-grass-0e0eb9f10.1.azurestaticapps.net/

    // window.location.replace(`${window.location.protocol}//${tgt}/View/GenericUserAccount/Summary/${reAccountNumber}?token=${loginRes.token}`);
    window.location.replace(`${window.location.protocol}//${tgt}/View/GenericUserAccount/Summary/${reAccountNumber}`);
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        {/* <Col md={2}>
              <img
                src={user.picture}
                alt="Profile"
                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
              />
            </Col> */}
        <Col md>
          {/* <h6>My App Metadata</h6> */}
          <p className="lead text-muted">My App Metadata: {user.myAppMetadata}</p>
        </Col>
      </Row>

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.ClientID}</h2> */}
          <p className="lead text-muted">Client ID: {user.ClientID}</p>
        </Col>
      </Row>

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.ManagedFlag}</h2> */}
          <p className="lead text-muted">Managed Flag: {user.ManagedFlag}</p>
        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md>
          {/* <h2>{user.REAccountNumber}</h2> */}
          <p className="lead text-muted">Account Number: {user.REAccountNumber}</p>
        </Col>
      </Row>
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">

        <Col md>
          {/* <h2>{user.TerminationFlag}</h2> */}
          <p className="lead text-muted">Termination Flag: {user.TerminationFlag}</p>
        </Col>

      </Row>

      {/*
          <Row>
            {user["idpAccountNumberPassed"]}
          </Row>
          */}

    </Container>
  );
};

export default withAuthenticationRequired(AuthorizeExternal, {
  onRedirecting: () => <><span className="spinner-border spinner-border-sm" /></>,
});
