import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  plans: [],
  plan: {},
  success: '',
};

const plansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.PLANS_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.PLANS_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.PLANS_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      plans: payload,
      plan: '',
      success: '',
    };

  case ActionTypes.PLAN_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      plan: payload,
      success: '',
    };

  case ActionTypes.PLAN_ADDED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  case ActionTypes.PLAN_UPDATED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  default:
    return state;
  }
};

export default plansReducer;
