import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { getSingleWft1003 } from '@redux/actions/wft1003Actions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const additionalFormFields = [
  { label: 'Address Type', name: 'addressType' },
  { label: 'Address 1', name: 'address1' },
  { label: 'Address 2', name: 'address2' },
  { label: 'City', name: 'city' },
  { label: 'State', name: 'stateCode' },
  { label: 'Zip', name: 'zip' },
  { label: 'Country', name: 'countryCode' },
];

const WorkflowTypeID1003 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, logout } = useAuth();
  const workflowId1003 = useSelector(state => state.wft1003);
  const { showErrorToast, showSuccessToast } = useToast();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getSingleWft1003(token, workflowId));
    }
    setValid(true);
    dispatch(reset());
  }, [workflowId]);

  const data = workflowId1003?.wft1003 || {};

  useEffect(() => {
    if (valid) {
      if (workflowId1003?.err) {
        if (unauthorizedError(workflowId1003)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(workflowId1003.err || msgs.error);
      } else if (workflowId1003?.success) {
        showSuccessToast();
        history.push('/workflows');
      }
    }
  }, [valid, workflowId1003]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant Address Update</h3>

      <FormSpinner requesting={workflowId1003?.requesting} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={data}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1003;
