import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  accountAddresses: {},
};

const accountAddressesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.ACCOUNT_ADDRESSES_REQUESTING:
    return {
      ...state, requesting: true, err: '', accountAddresses: '',
    };

  case ActionTypes.ACCOUNT_ADDRESSES_ERROR:
    return {
      ...state,
      requesting: false,
      err: payload,
      accountAddresses: '',
    };

  case ActionTypes.ACCOUNT_ADDRESSES_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      accountAddresses: payload,
    };

  default:
    return state;
  }
};

export default accountAddressesReducer;
