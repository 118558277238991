import React, { useEffect, useMemo, useState } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'reactstrap';

import {
 Spinner, Table, TableDashboard, FormFooterActions,
} from '@atoms';
import {
  displayNumberFormat,
  splitStringyNumber,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useApiFetch from '@hooks/useApiFetch';
import useQueryParams from '@hooks/useQueryParams';
import { sanitizeNumber } from '@lib/number';
import { getWithdrawalRates } from '@redux/actions/wrsActions';

const zeroValue = displayNumberFormat(0);
const defaultValue = {
  rateID: '',
  minAge: zeroValue,
  singleWithdrawalRate: zeroValue,
  jointWithdrawalRate: zeroValue,
};

const WithdrawalRates = ({
  match: {
    params: { id: productId },
  },
}) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const wRateData = useSelector(state => state.wrs);
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const { put } = useApiFetch();

  const [refresh, setRefresh] = useState(false);

  const withdrawalRateForm = useForm();
  const { control, setValue, getValues } = withdrawalRateForm;
  const { append, remove } = useFieldArray({
    control,
    name: 'withdrawalRatesTable',
  });

  const { withdrawalRatesTable } = getValues();

  const sortProductName = getQueryParam('sortProductName');
  const sortCarrierName = getQueryParam('sortCarrierName');
  const pageSize = getQueryParam('pageSize');
  const page = getQueryParam('from');

  const {
    requesting,
    err,
    wrs: { result },
  } = wRateData || {};

  useEffect(() => {
    if (token && productId) {
      dispatch(getWithdrawalRates(token, productId));
    }
    setRefresh(false);
  }, [productId, refresh]);

  useEffect(() => {
    if (token && result) {
      const data = result?.map(
        ({ minAge, singleWithdrawalRate, jointWithdrawalRate }) => ({
          minAge: displayNumberFormat(minAge),
          singleWithdrawalRate: displayNumberFormat(singleWithdrawalRate * 100),
          jointWithdrawalRate: displayNumberFormat(jointWithdrawalRate * 100),
        }),
      );

      setValue('withdrawalRatesTable', data);
    }
  }, [result]);

  const handleBlur = (rowIndex, columnId) => {
    const fieldName = `withdrawalRatesTable.${rowIndex}.${columnId}`;
    const activeIndexValue = getValues(fieldName);
    const numericTypedValue = splitStringyNumber(activeIndexValue);
    if (columnId === 'minAge') {
      const value = numericTypedValue > 150
          ? displayNumberFormat(150)
          : numericTypedValue.toFixed(2);
      setValue(fieldName, value);
    } else setValue(fieldName, numericTypedValue.toFixed(2));
  };

  const column = [
    {
      id: 'minAge',
      header: 'Minimum Age',
      minWidth: 300,
      accessorFn: ({ minAge }) => displayNumberFormat(minAge),
    },
    {
      id: 'singleWithdrawalRate',
      header: 'Single Rate',
      alignRight: true,
      minWidth: 300,
      accessorFn: ({ singleWithdrawalRate }) => displayNumberFormat(singleWithdrawalRate),
    },
    {
      id: 'jointWithdrawalRate',
      header: 'Joint Rate',
      alignRight: true,
      minWidth: 300,
      accessorFn: ({ jointWithdrawalRate }) => displayNumberFormat(jointWithdrawalRate),
    },
    {
      id: 'actions',
      header: '',
      size: '40%',
      cell: ({ row: { id: rowIndexId } = {} }) => {
        const handleDelete = () => remove(rowIndexId);

        return (
          <div className="pr-2 column-end">
            <Badge
              color="danger"
              className="pointer my-2"
              onClick={handleDelete}
            >
              Delete
            </Badge>
          </div>
        );
      },
    },
  ];

  const queryParams = buildQueryParams({
    sortProductName,
    sortCarrierName,
    pageSize,
  });

  const navigateToProducts = `/products?page=${page}${queryParams}`;

  const handleSubmit = async () => {
    const payloadValues = withdrawalRatesTable.map((row) => {
      const { jointWithdrawalRate, minAge, singleWithdrawalRate } = row || {};
      return {
        minAge: Number(minAge),
        jointWithdrawalRate: sanitizeNumber(jointWithdrawalRate) / 100,
        singleWithdrawalRate: sanitizeNumber(singleWithdrawalRate) / 100,
      };
    });
    await put(`products/wr/${productId}`, payloadValues);
  };

  const handleAddWRates = () => {
    append(defaultValue);
  };

  const tableFooter = useMemo(() => {
    const buttonActions = {
      buttonProps: [
        { labelName: 'Add', onClick: handleAddWRates },
        { onClick: handleSubmit },
      ],
    };
    return (
      <FormFooterActions
        linkProps={[{ path: navigateToProducts }]}
        {...buttonActions}
      />
    );
  }, [handleSubmit, navigateToProducts]);

  return (
    <TableDashboard pageTitle="Withdrawal Rates" setRefresh={setRefresh}>
      <Spinner requesting={requesting}>
        <Table
          columns={column}
          data={withdrawalRatesTable}
          hidePagination
          footer={tableFooter}
          errStatus={err}
          isEditable
          editableProps={{
            form: withdrawalRateForm,
            tableName: 'withdrawalRatesTable',
            onBlur: handleBlur,
            maxLength: 20,
            shouldPercentage: true,
            numericOnly: true,
            alignRight: true,
          }}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default WithdrawalRates;
