import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  products: [],
  product: {},
  success: '',
};

const productsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.PRODUCTS_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };
  case ActionTypes.PRODUCTS_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.PRODUCTS_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      products: payload,
      product: '',
      success: '',
    };

  case ActionTypes.PRODUCT_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      product: payload,
      success: '',
    };

  case ActionTypes.PRODUCT_ADDED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  case ActionTypes.PRODUCT_UPDATED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  default:
    return state;
  }
};

export default productsReducer;
