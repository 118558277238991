import { useContext, useMemo } from 'react';

import { Controller } from 'react-hook-form';

import { connectTheme } from '@contexts/Context';

const renderOption = (value, className, label) => (
  <option value={value} className={className}>
    {label}
  </option>
);

const Select = ({
  name = '',
  label = '',
  required = false,
  isLoading = false,
  loadingText = 'Loading Options...',
  errorMessage,
  placeholder = '',
  defaultValue = '',
  options = [],
  control,
  rules,
  disabled = false,
}) => {
  const { isLight } = useContext(connectTheme);

  const itemClassName = useMemo(
    () => `select_item ${isLight ? 'bg_light' : 'bg_dark'}`,
    [isLight],
  );

  const selectClassName = useMemo(
    () => `react-select select select_menu ${
        isLight ? 'border_light color_light' : 'border_dark color_dark'
      }`,
    [isLight],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <div className="form__form-group-input-wrap--error-above form__form-group">
          {label ? (
            <div className="form__form-group-label">
              {label}
              {required && <span className="text-danger"> *</span>}
            </div>
          ) : null}
          <div className="form__form-group-field">
            <select name={name} className={selectClassName} disabled={disabled} {...field}>
              {placeholder ? renderOption('', itemClassName, placeholder) : ''}
              {isLoading
                ? renderOption('', itemClassName, loadingText)
                : defaultValue && renderOption('', itemClassName, defaultValue)}
              {errorMessage && renderOption('', itemClassName, errorMessage)}
              {options?.map(({ value, label }, index) => (
                <option
                  value={value}
                  key={`${value}-${index}`}
                  className={itemClassName}
                >
                  {label}
                </option>
              ))}
            </select>
            {errors?.[name] && (
              <span className="form__form-group-error">
                {errors[name]?.message}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
};
export default Select;
