import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { getSingleWFT1001 } from '@redux/actions/wft1001Actions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const additionalFormFields = [
  { label: 'First Name', name: 'firstName' },
  { label: 'Middle Name', name: 'middleName' },
  { label: 'Last Name', name: 'lastName' },
  { label: 'Email Address', name: 'emailAddress' },
  { label: 'Primary Phone', name: 'homePhone' },
  { label: 'Mobile Phone', name: 'mobilePhone' },
];

const WorkflowTypeID1001 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const { token, logout } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const workflowId1001 = useSelector(state => state.wft1001);
  const { showErrorToast, showSuccessToast } = useToast();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getSingleWFT1001(token, workflowId));
    }
    setValid(true);
    dispatch(reset());
  }, [workflowId]);

  const data = workflowId1001?.wft1001 || {};

  useEffect(() => {
    if (valid) {
      if (workflowId1001?.err) {
        if (unauthorizedError(workflowId1001)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(workflowId1001.err || msgs.error);
      } else if (workflowId1001?.success) {
        showSuccessToast();
        history.push('/workflows');
      }
    }
  }, [valid, workflowId1001]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant Information Update</h3>

      <FormSpinner requesting={workflowId1001?.requesting} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={data}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID1001;
