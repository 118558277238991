import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { TableDashboard } from '@atoms';
import { cpStateReset } from '@redux/actions/cpActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import ProductsTable from './components/ProductsTable';

const Products = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
    setRefresh(true);
  }, [dispatch]);

  return (
    <TableDashboard
      pageTitle="Products"
      setRefresh={setRefresh}
      navigatePath="/products/add"
    >
      <ProductsTable refresh={refresh} setRefresh={setRefresh} />
    </TableDashboard>
  );
};

export default Products;
