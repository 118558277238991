import { useCallback, useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Input, PasswordInput } from '@atoms/index';
import { msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { login } from '@redux/actions/loginActions';

const loginName = localStorage.getItem('loginName') || '';

const credentials = {
  loginName,
  password: '',
  remember: true,
};

const LogInForm = () => {
  const { control, handleSubmit, reset } = useForm();
  const [isRequesting, setIsRequesting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { showErrorToast } = useToast();
  const loginRes = useSelector(state => state.login);
  const { setToken, setPayload } = useAuth();

  const handleLogin = (values) => {
    setIsRequesting(true);
    localStorage.setItem('loginName', values.loginName);
    dispatch(login(values));
  };

  const getData = useCallback(() => {
    reset(credentials);
    if (loginRes?.err) {
      setIsRequesting(false);
      showErrorToast(loginRes.err);
    }

    if (loginRes?.token) {
      setIsRequesting(false);
      localStorage.setItem('authToken', loginRes.token);
      setToken(loginRes.token);
      setPayload(jwt_decode(loginRes.token));

      history.push('/workflows');
    }
  }, [reset, loginRes]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <form
      className="form form--horizontal login-form form_spacing"
      onSubmit={handleSubmit(handleLogin)}
    >
      <Input
        control={control}
        name="loginName"
        label="Login Name"
        rules={{ required: msgs.fieldError }}
        startIcon={<AccountOutlineIcon />}
      />
      <PasswordInput
        control={control}
        name="password"
        label="Password"
        type="password"
        wrapperClassName="mt-2"
        rules={{ required: msgs.fieldError }}
        startIcon={<KeyVariantIcon />}
      />

      <div className="account__btns pl-2">
        <button
          className="account__btn btn btn-primary"
          type="submit"
          disabled={isRequesting}
        >
          {isRequesting ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            'Sign In'
          )}
        </button>
      </div>
    </form>
  );
};

export default LogInForm;
