import React, { useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import AnnuityPolicyDetail from './AnnuityPolicyDetail';
import Summary from './Summary';
import TabPanel from './TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const annuityDetailsTabPanel = ['PreTax', 'Roth', 'AfterTax'];
const annuityDetailsTabs = [
  { label: 'Pre-Tax Policy', taxCode: 'PreTax' },
  { label: 'Roth Policy', taxCode: 'Roth' },
  { label: 'After-Tax Policy', taxCode: 'AfterTax' },
];

export default function AnnuityTabs(props) {
  const { data, tabShown } = props;
  const classes = useStyles();
  const [value, setValue] = useState(tabShown);

  const handleChange = (event, newValue) => {
    sessionStorage.setItem('activeTab', newValue);
    setValue(newValue);
  };

  const handleChangeTab = (newValue) => {
    sessionStorage.setItem('activeTab', newValue);
    setValue(newValue);
  };

  const filteredData = data?.filter(item => item.policyNumber !== 'Pending');

  const { tableData } = useMemo(() => {
    let tableData = filteredData || [];
    if (!filteredData?.length) {
      tableData = data;
    }
    return { tableData };
  }, [filteredData, data]);

  const showTab = (taxCode) => {
    if (tableData?.map(m => m.taxCode).find(tc => tc === taxCode) === undefined) return false;
    return true;
  };

  return (
    <div className={classes.root}>
      <img
        style={{
          margin: '10px',
          width: '10%',
          height: '10%',
          float: 'right',
        }}
        src={process.env.PUBLIC_URL + '/img/azl.logo.png'}
      />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="participant's annuity information"
        TabIndicatorProps={{ style: { backgroundColor: 'gray' } }}
      >
        <Tab label="Summary" {...a11yProps(0)} />
        {annuityDetailsTabs?.map(({ label, taxCode }, index) => {
          const conditionalTabs = showTab(taxCode);

          if (!conditionalTabs) {
            return null;
          }
          return (
            <Tab
              label={label}
              {...a11yProps(index + 1)}
              key={label}
            />
          );
        })}
      </Tabs>
      <TabPanel value={value} index={0}>
        <Summary data={tableData} tabClick={handleChangeTab} />
      </TabPanel>
      {annuityDetailsTabPanel?.map((item, index) => (
        <TabPanel value={value} index={index + 1} key={`${item}-${index}`}>
          <AnnuityPolicyDetail taxSource={item} data={data} key={item} />
        </TabPanel>
      ))}
    </div>
  );
}
