import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getWfts = token => async (dispatch) => {
  dispatch({
    type: ActionTypes.WFTS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + 'workflowtypes/distinctwt', {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      // console.log("WFTS in Action", resp.data);

      dispatch({
        type: ActionTypes.WFTS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.WFTS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
