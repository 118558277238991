import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const login = credentials => async (dispatch) => {
  await axios
    .post(conf.serverURL + 'login', credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (resp) => {
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: resp.data.token,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.LOGIN_ERROR,
        payload: err.response?.data.message || err.message,
      });
    });
};

export const logout = () => (dispatch) => {
  const token = localStorage.getItem('authToken');
  const loginName = localStorage.getItem('loginName');

  logOffUser(token, loginName);

  localStorage.removeItem('authToken');

  dispatch({
    type: ActionTypes.LOGIN_LOGOUT,
  });
};

const logOffUser = (token, loginName) => {
  const credentials = {
    token,
    loginName,
    userType: 'admin',
  };

  axios.post(conf.serverURL + 'logout', credentials, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const loginGenericAnnuitantUser = credentials => async (dispatch) => {
  await axios
    .post(conf.serverURL + 'loginGenericAnnuitantUser', credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (resp) => {
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: resp.data.token,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.LOGIN_ERROR,
        payload: err.response?.data.message || err.message,
      });
    });
};
