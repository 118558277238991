import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Table, Spinner } from '@atoms';
import { pagination } from '@config/conf';
import { formatDate, formatToPercentage, getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getAccountPioas } from '@redux/actions/accountPioasActions';

const accountPiosColumns = [
  {
    id: 'pioName',
    header: 'PIO Name',
    accessorKey: 'pioName',
    enableSorting: false,
    size: '300px',
  },
  {
    id: 'carrierPIOCode',
    header: 'PIO Code',
    accessorKey: 'carrierPIOCode',
    enableSorting: false,
    size: '100px',
  },
  {
    id: 'allocationRate',
    header: 'Allocation Rate',
    cell: (tableRow) => {
      const { allocationRate } = tableRow?.row?.original || {};

      return (
        <div className="text-right">
          {allocationRate ? formatToPercentage(allocationRate * 100) : ''}
        </div>
      );
    },
    alignRight: true,
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    enableSorting: false,
  },
  {
    id: 'lastUpdated',
    header: 'Last Updated',
    cell: (tableRow) => {
      const { lastUpdated } = tableRow?.row?.original || {};
      return lastUpdated ? formatDate(lastUpdated) : '';
    },
  },
];

const PioElectionsTable = ({ id }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { getQueryParam } = useQueryParams();
  const accountPioas = useSelector(state => state.accountPioas);

  const pageQuery = getQueryParam('pioasPage');
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );

  const { offset, pageNumber } = getPagination(pageSize, pageQuery);

  const { data: tableData = [], recordCount } = accountPioas?.accountPioas || {};

  useEffect(() => {
    if (token) {
      dispatch(getAccountPioas(token, id, offset, pageSize));
    }
  }, [offset, pageSize, id, token]);

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
    fromPage: pageNumber,
    pageName: 'pioasPage',
  };

  return (
    <Spinner requesting={accountPioas?.requesting}>
      <Table
        columns={accountPiosColumns}
        data={tableData}
        pageProps={pageProps}
        status={accountPioas?.err}
        noPadding
        noBottomBorder
      />
    </Spinner>
  );
};

export default PioElectionsTable;
