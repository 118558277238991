import { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import {
  getSingleAccount,
  updateAccount,
} from '@redux/actions/accountsActions';
import { getAnnuityUserToken } from '@redux/actions/annuityActions';
import { getTarget } from '@utils/env';

import EditAccountForm from './components/EditAccountForm';

const targetLink = getTarget();

const EditAccount = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { token, logout } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [valid, setValid] = useState(false);
  const accounts = useSelector(state => state.accounts);
  const annuity = useSelector(state => state.annuity);
  const { showErrorToast, showSuccessToast } = useToast();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const page = getQueryParam('from');
  const search = getQueryParam('search');

  const sort = {
    sortAccountNumber: getQueryParam('sortAccountNumber') || '',
    sortParticipantName: getQueryParam('sortParticipantName') || '',
    sortPolicyNumber: getQueryParam('sortPolicyNumber') || '',
  };

  const pageSize = getQueryParam('pageSize') || '';

  const data = accounts?.account || {};

  const { sortAccountNumber, sortParticipantName, sortPolicyNumber } = sort || {};

  const commonPath = useMemo(
    () => buildQueryParams({
        search,
        sortAccountNumber,
        sortParticipantName,
        sortPolicyNumber,
        pageSize,
      }),
    [search, sortAccountNumber, sortParticipantName, sortPolicyNumber, pageSize],
  );

  useEffect(() => {
    if (token) {
      dispatch(getSingleAccount(token, id));
      setValid(true);
    }
  }, [id]);

  useEffect(() => {
    const credentials = {
      Token: token,
      loginName: 'annuitysystemuser',
      userType: 'Annuitant',
    };
    dispatch(getAnnuityUserToken(credentials));
  }, []);

  const handleForm = (values) => {
    const payloadValues = {
      notes: sanitizeValue(values?.notes),
    };

    dispatch(updateAccount(token, id, payloadValues));
    setValid(true);
  };

  useEffect(() => {
    if (valid) {
      if (accounts?.err) {
        if (unauthorizedError(accounts)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(accounts.err || msgs.error);
      } else if (accounts?.success) {
        showSuccessToast();
        history.push(`/accounts?page=${page}${commonPath}`);
      }
    }
  }, [valid, accounts, logout, commonPath]);

  const isIpxHosted = data?.ipxHosted;

  // https://happy-grass-0e0eb9f10.1.azurestaticapps.net/
  const lnkhref = `${
    window.location.protocol
  }//${targetLink}/View/Account/Summary/${
    data.reAccountNumber
  }?token=${encodeURI(annuity.annuityUserToken)}`;

  return (
    <Container>
      <h3 className="mb-3 text-center">
        RE Account Detail
        {isIpxHosted ? (
          <a
            href={lnkhref}
            target="_blank"
            rel="noreferrer"
            className="mb-3 btn btn-primary float-right"
          >
            Accounts Detail
          </a>
        ) : null}
      </h3>

      <FormSpinner requesting={accounts?.requesting} />

      <div
        style={{
          margin: '0px auto',
          paddingBottom: '50px',
        }}
      >
        <EditAccountForm
          data={data}
          from={page}
          commonPath={commonPath}
          onSubmit={handleForm}
          id={id}
        />
      </div>
    </Container>
  );
};

export default EditAccount;
