import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getSinglePIO = (token, id) => async (dispatch) => {
  dispatch({ type: ActionTypes.PIO_REQUESTING });

  await axios
    .get(conf.serverURL + `products/pios/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PIO_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PIO_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const createPIO = (token, values) => async (dispatch) => {
  dispatch({ type: ActionTypes.PIO_REQUESTING });

  await axios
    .post(conf.serverURL + 'products/pios', values, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PIO_ADDED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PIO_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updatePIO = (token, id, values) => async (dispatch) => {
  dispatch({ type: ActionTypes.PIO_REQUESTING });

  await axios
    .put(conf.serverURL + `products/pios/${id}`, values, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PIO_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PIO_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
