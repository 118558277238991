import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getCustodians = (token, offset = 0, limit = 0) => async (dispatch) => {
  // const authToken = localStorage.getItem("authToken");

  dispatch({
    type: ActionTypes.CUSTODIANS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `custodians/get?Limit=${limit}&Offset=${offset}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CUSTODIANS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CUSTODIANS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const getSingleCustodian = (token, id) => async (dispatch) => {
  // const authToken = localStorage.getItem("authToken");

  dispatch({
    type: ActionTypes.CUSTODIANS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `custodians/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CUSTODIAN_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CUSTODIANS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const createCustodian = (token, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CUSTODIANS_REQUESTING,
  });

  await axios
    .post(conf.serverURL + 'custodians/create', data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CUSTODIAN_ADDED,
        payload: resp.data.custodianID,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CUSTODIANS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updateCustodian = (token, id, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CUSTODIANS_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `custodians/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CUSTODIAN_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CUSTODIANS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
