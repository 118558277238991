import React, { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { Spinner, Table, TableDashboard } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { cpStateReset } from '@redux/actions/cpActions';
import { getCustodians } from '@redux/actions/custodiansActions';
import { reset } from '@redux/reducers/formFieldsReducer';

const Custodians = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const custodiansData = useSelector(state => state.custodians);
  const { getQueryParam } = useQueryParams();

  const page = getQueryParam('page');
  const [refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const { offset, pageNumber } = getPagination(pageSize, page);
  const { err, requesting, custodians } = custodiansData || {};
  const { data, recordCount } = custodians || {};

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getCustodians(token, offset, pageSize));
    }
    setRefresh(false);
  }, [pageSize, offset, refresh]);

  const column = useMemo(
    () => [
      {
        id: 'custodianName',
        header: 'Custodian Name',
        size: 200,
        accessorFn: row => row?.custodianName || '-',
      },
      {
        id: 'custodianCode',
        header: 'Custodian Code',
        size: 300,
        accessorFn: row => row?.custodianCode || '-',
      },
      {
        id: 'contactName',
        header: 'Contact Name',
        accessorFn: row => row?.contactName || '-',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: row => row?.contactPhone || '-',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: row => row?.contactEmail || '-',
      },
      {
        id: 'editAction',
        header: '',
        cell: (tableRow) => {
          const { custodianID } = tableRow.row.original || {};
          const navigateToEditPage = `/custodians/${custodianID}/edit?from=${pageNumber}${
            pageSize ? `&pageSize=${pageSize}` : ''
          }`;
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToEditPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [pageNumber, pageSize],
  );

  const pageProps = useMemo(
    () => ({
      total: recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [pageNumber, pageSize, recordCount],
  );

  return (
    <TableDashboard
      pageTitle="Custodians"
      setRefresh={setRefresh}
      navigatePath="/custodians/add"
    >
      <Spinner requesting={requesting}>
        <Table
          columns={column}
          data={data}
          pageProps={pageProps}
          errStatus={err}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default Custodians;
