import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Container } from 'reactstrap';

import { reset } from '@redux/reducers/formFieldsReducer';

import CustodiansPlansTable from './components/CustodiansPlansTable';

const CustodiansPlans = (props) => {
  const { match: { params: { id } } } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <Container>
      <h3 className="mb-3">Plans</h3>

      <CustodiansPlansTable custId={id} />
    </Container>
  );
};

CustodiansPlans.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default CustodiansPlans;
