import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const changePassword = (uid, token, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.CP_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `users/${uid}/passwordUpdate`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.CP_SUCCESS,
        payload: 'success',
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.CP_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const cpStateReset = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CP_REQUESTING,
  });
};
