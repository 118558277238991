import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  rec: {},
  success: '',
};

const recReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.REC_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.REC_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.REC_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      rec: payload,
      success: '',
    };

  case ActionTypes.REC_UPDATED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  default:
    return state;
  }
};

export default recReducer;
