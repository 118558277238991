import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  wrs: {},
  success: '',
};

const wrsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WRS_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.WRS_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.WRS_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      wrs: payload,
      success: '',
    };

  case ActionTypes.WRS_SAVED:
    return {
      ...state,
      requesting: false,
      err: '',
      wrs: '',
      success: payload,
    };

  default:
    return state;
  }
};

export default wrsReducer;
