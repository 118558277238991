import React from 'react';

import { Typography, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: '"Manrope", "-apple-system", "BlinkMacSystemFont", sans-serif',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    color: 'white',
    height: '55px',
  },
  abar: {
    backgroundColor: '#7199BB',
    height: '30px',
    Align: 'center',
    borderRadius: '5px',
  },
  tbar: {
    minHeight: '32px',
    alignItems: 'center',
  },
  bx: {
    fontFamily: '"Manrope", "-apple-system", "BlinkMacSystemFont", sans-serif',
  },
  sup: {
    top: '-0.5',
  },
  btnfr: {
    float: 'right',
  },
}));

const ServiceCenter = props => (
  <>
    <Grid container direction="row" spacing={5}>
      <Grid item xs={10} sm={6}>
        <Typography variant="h6" style={{ display: 'inline-block' }}>
          Need Help?
        </Typography>
        <Typography variant="caption" component="div">
          Have a question about the annuity or the lifetime income guarantee?
        </Typography>
        <Typography variant="h6" component="div">
          Call (888) 909-9380
        </Typography>
        <Typography variant="caption" component="div">
          Mon - Fri 8:30am to 5:30pm CT
        </Typography>
      </Grid>
    </Grid>
  </>
);

export default ServiceCenter;
