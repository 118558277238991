import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import {
  getSingleCarrier,
  updateCarrier,
} from '@redux/actions/carriersActions';

import CarrierForm from '../CarrierForm/CarrierForm';

const EditCarrier = (props) => {
  const {
    match: {
      params: { carrierId },
    },
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const carriers = useSelector(state => state.carriers);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const { getQueryParam } = useQueryParams();
  const { token, logout } = useAuth();

  const page = getQueryParam('from');

  const pageSize = getQueryParam('pageSize');

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getSingleCarrier(token, carrierId));
    }

    setValid(true);
  }, [carrierId]);

  const redirectUrl = useMemo(
    () => `/carriers?page=${page}${pageSize ? `&pageSize=${pageSize}` : ''}`,
    [pageSize, page],
  );

  useEffect(() => {
    if (valid) {
      if (carriers?.err) {
        if (unauthorizedError(carriers)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        const errorMessage = carriers.err || msgs.error;
        showErrorToast(errorMessage);
      } else if (carriers?.success) {
        showSuccessToast();
        history.push(redirectUrl);
      }
    }
  }, [redirectUrl, carriers, valid]);

  const handleForm = (values) => {
    const { carrierName, extCarrierCode, endpointSetID } = values || {};

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const isValid = validateRequiredField({
      'Carrier Name': carrierName,
      'Carrier Code': extCarrierCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      endpointSetID: Number(endpointSetID),
    };

    dispatch(updateCarrier(token, carrierId, payloadValues));
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Carrier Details</h3>

      <FormSpinner requesting={carriers?.requesting} />

      <CarrierForm
        carriers={carriers?.carrier}
        onSubmit={handleForm}
        redirectUrl={redirectUrl}
      />
    </Container>
  );
};

export default EditCarrier;
