import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  success: '',
};

const cpReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.CP_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.CP_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.CP_SUCCESS:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  default:
    return state;
  }
};

export default cpReducer;
