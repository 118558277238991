import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getRecordKeepers = (token, limit = 0, offset = 0, orderBy = null) => async (dispatch) => {
  dispatch({
    type: ActionTypes.RKS_REQUESTING,
  });

  await axios
    .get(
      conf.serverURL
          + `recordkeepers/get?Offset=${offset}&limit=${limit}${
            orderBy === null ? '' : '&OrderBy=' + orderBy
          }`,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.RKS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.RKS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const getSingleRecordKeeper = (token, id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.RKS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `recordkeepers/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.RK_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.RKS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const createRecordKeeper = (token, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.RKS_REQUESTING,
  });

  await axios
    .post(conf.serverURL + 'recordkeepers/create', data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.RK_ADDED,
        payload: resp.data.recordkeeperID,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.RKS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updateRecordKeeper = (token, data, id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.RKS_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `recordkeepers/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.RK_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.RKS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
