import React from 'react';

import { Route } from 'react-router-dom';

import ChangePassword from '@containers/Account/ChangePassword/ChangePassword';
import Accounts from '@containers/Accounts/Accounts';
import EditAccount from '@containers/Accounts/EditAccount/EditAccount';
import NewTransactionWorkflow from '@containers/Accounts/NewTransactionWorkflow/NewTransactionWorkflow';
import AddCarrier from '@containers/Carriers/AddCarrier/AddCarrier';
import Carriers from '@containers/Carriers/Carriers';
import AddCarrierProduct from '@containers/Carriers/CarriersProduct/AddCarrierProduct/AddCarrierProduct';
import CarriersProduct from '@containers/Carriers/CarriersProduct/CarriersProduct';
import CarrierSubaccounts from '@containers/Carriers/CarriersProduct/CarrierSubaccounts/CarrierSubaccounts';
import EditCarrierProduct from '@containers/Carriers/CarriersProduct/EditCarrierProduct/EditCarrierProduct';
import EditCarrier from '@containers/Carriers/EditCarrier/EditCarrier';
import CashLedger from '@containers/CashLedger/CashLedger';
import EditCashLedger from '@containers/CashLedger/EditCashLedger/EditCashLedger';
import Config from '@containers/Config/Config';
import REConfig from '@containers/Config/REConfig';
import AddCustodian from '@containers/Custodians/AddCustodian/AddCustodian';
import Custodians from '@containers/Custodians/Custodians';
import CustodiansPlans from '@containers/Custodians/CustodiansPlans/CustodiansPlans';
import EditCustodianPlan from '@containers/Custodians/CustodiansPlans/EditCustodianPlan/EditCustodianPlan';
import EditCustodian from '@containers/Custodians/EditCustodian/EditCustodian';
import DailySettlementReport from '@containers/DailySettlementReport/DailySettlementReport';
import OnLineMarketingDashboard from '@containers/Dashboards/OnLineMarketing/index';
import Layout from '@containers/Layout/index';
import AddPlan from '@containers/Plans/AddPlan/AddPlan';
import EditPlans from '@containers/Plans/EditPlans/EditPlans';
import AddPlanProduct from '@containers/Plans/PlanProducts/AddPlanProduct/AddPlanProduct';
import EditPlanProduct from '@containers/Plans/PlanProducts/EditPlanProduct/EditPlanProduct';
import EditPIOs from '@containers/Plans/PlanProducts/PIOs/EditPIOs';
import PlanFactors from '@containers/Plans/PlanProducts/PlanFactors/PlanFactors';
import Plans from '@containers/Plans/Plans';
import AddProduct from '@containers/Products/AddProduct/AddProduct';
import EditProduct from '@containers/Products/EditProduct/EditProduct';
import AddPIO from '@containers/Products/PIO/AddPIO/AddPIO';
import EditPIO from '@containers/Products/PIO/EditPIO/EditPIO';
import AddProductFactor from '@containers/Products/ProductFactor/AddProductFactor';
import EditProductFactor from '@containers/Products/ProductFactor/EditProductFactor';
import Products from '@containers/Products/Products';
import WithdrawalRates from '@containers/Products/WR/WithdrawalRates';
import EditPandWs from '@containers/Purchases&Withdrawals/EditPandWs/EditPandWs';
import ImportPandWs from '@containers/Purchases&Withdrawals/ImportPandWs/ImportPandWs';
import PurchasesWithdrawals from '@containers/Purchases&Withdrawals/PurchasesWithdrawals';
import AddRecordKeeper from '@containers/RecordKeepers/AddRecordKeepers/AddRecordKeeper';
import EditRecordKeepers from '@containers/RecordKeepers/EditRecordKeepers/EditRecordKeepers';
import RecordKeepers from '@containers/RecordKeepers/RecordKeepers';
import Reports from '@containers/Reports/Reports';
import AddRKChannel from '@containers/RKChannels/AddRKChannel/AddRKChannel';
import EditRKChannel from '@containers/RKChannels/EditRKChannel/EditRKChannel';
import RKChannels from '@containers/RKChannels/RKChannels';
import AddUser from '@containers/Users/AddUser/AddUser';
import Edituser from '@containers/Users/EditUser/Edituser';
import Users from '@containers/Users/Users';
import EditWorkflow from '@containers/Workflows/EditWorkfow/EditWorkflow';
import ResubmitWFT1001 from '@containers/Workflows/ResubmitWFT1001/ResubmitWFT1001';
import ResubmitWFT1002 from '@containers/Workflows/ResubmitWFT1002/ResubmitWFT1002';
import WorkflowTypeID1001 from '@containers/Workflows/WorkflowIdForms/WorkflowTypeID1001';
import WorkflowTypeID1002 from '@containers/Workflows/WorkflowIdForms/WorkflowTypeID1002';
import WorkflowTypeID1003 from '@containers/Workflows/WorkflowIdForms/WorkflowTypeID1003';
import WorkflowTypeID1004 from '@containers/Workflows/WorkflowIdForms/WorkflowTypeID1004';
import WorkflowTypeID10061007 from '@containers/Workflows/WorkflowIdForms/WorkflowTypeID10061007';
import WorkflowTypeID1008 from '@containers/Workflows/WorkflowIdForms/WorkflowTypeID1008';
import Workflows from '@containers/Workflows/Workflows';
import ProcessingLog from '@containers/Workflows/WorkflowTypeID1005/ProcessingLog/ProcessingLog';
import WorkflowTypeID1005 from '@containers/Workflows/WorkflowTypeID1005/WorkflowTypeID1005';
import PrivateRoute from '@routes/PrivateRoute';

// import Account from './Account';
// import Tables from './Tables';
// import UI from './UI';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      {/* <Route path="/e_commerce_dashboard" component={Commerce} /> */}
      {/* <Route path="/online_marketing_dashboard" component={OnLineMarketingDashboard} /> */}
      <PrivateRoute
        exact
        path="/dashboard"
        component={OnLineMarketingDashboard}
      />
      {/* <Route exact path="/app_dashboard" component={AppDashboard} />
      <Route path="/booking_dashboard" component={BookingDashboard} />
      <Route path="/finance_dashboard" component={Finance} />
      <Route path="/fitness_dashboard" component={FitnessDashboard} />
      <Route path="/ui" component={UI} />
      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/account" component={Account} />
      <Route path="/e-commerce" component={ECommerce} />
      <Route path="/default_pages" component={DefaultPages} />
      <Route path="/documentation" component={Documentation} /> */}

      {/* Dashboard Routes */}
      <PrivateRoute exact path="/password/change" component={ChangePassword} />

      <PrivateRoute exact path="/workflows" component={Workflows} />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1001/:workflowId"
        component={WorkflowTypeID1001}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1002/:workflowId"
        component={WorkflowTypeID1002}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1003/:workflowId"
        component={WorkflowTypeID1003}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1004/:workflowId"
        component={WorkflowTypeID1004}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1006/:workflowId"
        component={WorkflowTypeID10061007}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1007/:workflowId"
        component={WorkflowTypeID10061007}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1005/:workflowId"
        component={WorkflowTypeID1005}
      />
      <PrivateRoute
        exact
        path="/workflows/workflowdetail1008/:workflowId"
        component={WorkflowTypeID1008}
      />

      <PrivateRoute
        exact
        path="/workflows/workflowdetail1005/:workflowId/processing-log"
        component={ProcessingLog}
      />

      <PrivateRoute exact path="/workflows/:id/edit" component={EditWorkflow} />
      <PrivateRoute
        exact
        path="/workflows/:id/workflowdetail1001/resubmit"
        component={ResubmitWFT1001}
      />
      <PrivateRoute
        exact
        path="/workflows/:id/workflowdetail1002/resubmit"
        component={ResubmitWFT1002}
      />

      <PrivateRoute
        exact
        path="/purchases-and-withdrawals"
        component={PurchasesWithdrawals}
      />
      <PrivateRoute
        exact
        path="/purchases-and-withdrawals/import"
        component={ImportPandWs}
      />
      <PrivateRoute
        exact
        path="/purchases-and-withdrawals/:id/edit"
        component={EditPandWs}
      />

      <PrivateRoute exact path="/cash-ledger" component={CashLedger} />
      <PrivateRoute
        exact
        path="/cash-ledger/:id/edit"
        component={EditCashLedger}
      />

      <PrivateRoute exact path="/accounts" component={Accounts} />
      <PrivateRoute exact path="/accounts/:id/edit" component={EditAccount} />
      <PrivateRoute
        exact
        path="/accounts/:id/withdrawal/create"
        component={NewTransactionWorkflow}
      />

      {/* <PrivateRoute exact path="/reports" component={Reports} /> */}
      <PrivateRoute exact path="/dailysettlementreport" component={DailySettlementReport} />

      <PrivateRoute exact path="/rk_channels" component={RKChannels} />
      <PrivateRoute exact path="/rk_channels/add" component={AddRKChannel} />
      <PrivateRoute
        exact
        path="/rk_channels/:id/edit"
        component={EditRKChannel}
      />

      <PrivateRoute exact path="/carriers" component={Carriers} />
      <PrivateRoute exact path="/carriers/add" component={AddCarrier} />
      <PrivateRoute
        exact
        path="/carriers/products/add"
        component={AddCarrierProduct}
      />
      <PrivateRoute
        exact
        path="/carriers/:carrierId/edit"
        component={EditCarrier}
      />
      <PrivateRoute
        exact
        path="/carriers/:carrierId/products"
        component={CarriersProduct}
      />
      <PrivateRoute
        exact
        path="/carriers/:carrierId/products/:productId/edit"
        component={EditCarrierProduct}
      />
      <PrivateRoute
        exact
        path="/carriers/:carrierId/products/:productId/subaccounts"
        component={CarrierSubaccounts}
      />

      <PrivateRoute exact path="/products" component={Products} />
      <PrivateRoute exact path="/products/add" component={AddProduct} />
      <PrivateRoute exact path="/products/:id/edit" component={EditProduct} />
      <PrivateRoute exact path="/products/:id/wr" component={WithdrawalRates} />
      <PrivateRoute exact path="/products/:id/pio/add" component={AddPIO} />
      <PrivateRoute exact path="/products/:id/factor/add" component={AddProductFactor} />
      <PrivateRoute
        exact
        path="/products/:id/pio/:pioId/edit"
        component={EditPIO}
      />
      <PrivateRoute
        exact
        path="/products/:id/factor/:productFactorID/edit"
        component={EditProductFactor}
      />
      <PrivateRoute exact path="/custodians" component={Custodians} />
      <PrivateRoute exact path="/custodians/add" component={AddCustodian} />
      <PrivateRoute
        exact
        path="/custodians/:id/edit"
        component={EditCustodian}
      />
      <PrivateRoute
        exact
        path="/custodians/:id/plans"
        component={CustodiansPlans}
      />
      <PrivateRoute
        exact
        path="/custodians/:custId/plans/:planId/edit"
        component={EditCustodianPlan}
      />

      <PrivateRoute exact path="/users" component={Users} />
      <PrivateRoute exact path="/users/add" component={AddUser} />
      <PrivateRoute exact path="/users/:id/edit" component={Edituser} />

      <PrivateRoute exact path="/plans" component={Plans} />
      <PrivateRoute exact path="/plans/add" component={AddPlan} />
      <PrivateRoute exact path="/plans/:id/edit" component={EditPlans} />
      {/* <PrivateRoute exact path="/plans/:id/products" component={PlanProducts} /> */}
      <PrivateRoute
        exact
        path="/plans/:planId/products/add"
        component={AddPlanProduct}
      />
      <PrivateRoute
        axact
        path="/plans/:planId/product/:productId"
        component={EditPlanProduct}
      />
      {/* <PrivateRoute exact path="/plans/:planId/policies/:policyId/subaccounts" component={PlanSubaccounts} /> */}
      <PrivateRoute
        exact
        path="/plan/:planProductId/product/:productId/pios"
        component={EditPIOs}
      />
      <PrivateRoute
        exact
        path="/plan/:planProductId/product/:productId/factors"
        component={PlanFactors}
      />
      <PrivateRoute exact path="/recordkeepers" component={RecordKeepers} />
      <PrivateRoute
        exact
        path="/recordkeepers/add"
        component={AddRecordKeeper}
      />
      <PrivateRoute
        exact
        path="/recordkeepers/:id/edit"
        component={EditRecordKeepers}
      />

      <PrivateRoute exact path="/config" component={Config} />
      <PrivateRoute exact path="/config/re" component={REConfig} />
    </div>
  </div>
);
