import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  pp: {},
  success: '',
};

const ppsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.PPS_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.PPS_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.PP_FETCHED:
    return {
      ...state, requesting: false, err: '', pp: payload, success: '',
    };

  case ActionTypes.PP_ADDED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  case ActionTypes.PP_UPDATED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  default:
    return state;
  }
};

export default ppsReducer;
