import { Input } from '@atoms';

const policyInformationFields = [
  { label: 'Policy Status', name: 'policyStatus' },
  { label: 'Policy Number', name: 'policyNumber' },
  { label: 'POV Date', name: 'policyPOVDate' },
  { label: 'Application Date', name: 'policyApplicationDate' },
  { label: 'Issue Date', name: 'policyIssueDate' },
  { label: 'Surrender Date', name: 'policySurrenderDate' },
  { label: 'Surrender Fee Exp Date', name: 'policySurrenderFeeExpDate' },
  { label: 'Termination Date', name: 'policyTerminationDate' },
  { label: 'Maturity Date', name: 'policyMaturityDate' },
  { label: 'Max Annuitization Date', name: 'policyMaxAnnuitizationDate' },
  { label: 'Annuitization Date', name: 'policyAnnuitizationDate' },
  { label: 'GLWB Activation Date', name: 'glwbActivationDate' },
  { label: 'GLWB Amount', name: 'glwbAmount' },
  { label: 'Repatriation Date', name: 'policyRepatriationDate' },
  { label: 'Accumulation Value', name: 'policyAccumulationValue' },
  { label: 'Surrender Value', name: 'policySurrenderValue' },
  { label: 'Year End Value', name: 'policyYearEndValue' },
  { label: 'RMD Value', name: 'policyRMDValue' },
  { label: 'Actuarial Present Value', name: 'policyActuarialPV' },
  { label: 'Guaranteed Benefit Base', name: 'policyGuaranteedBenefitBase' },
  { label: 'Total Premiums Paid', name: 'policyTotalPremiumPaid' },
  { label: 'Total Withdrawals', name: 'policyTotalWithdrawals' },
  { label: 'POV Adjustment', name: 'povAdjustment' },
];

const PolicyInformation = ({ control }) => (
  <>
    {policyInformationFields?.map(({ label, name }) => (
      <Input
        key={label}
        className="mb-2"
        control={control}
        name={name}
        isReadOnly
        label={label}
      />
    ))}
  </>
);

export default PolicyInformation;
