import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { UncontrolledTooltip, Button } from 'reactstrap';

import { TableDashboard } from '@atoms';
import useApiFetch from '@hooks/useApiFetch';
import { cpStateReset } from '@redux/actions/cpActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import CashLedgerTable from './components/CashLedgerTable';

const CashLedger = () => {
  const dispatch = useDispatch();
  const { post, isPending } = useApiFetch();

  const [refresh, setRefresh] = useState(false);
  const [pendingRecords, setPendingRecords] = useState(null);

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
  }, [dispatch]);

  const handleUpdate = useCallback(async () => {
    await post('operations/cashledger/updatedailysummary');
    setRefresh(true);
  }, [refresh]);

  const renderUpdateButton = useMemo(
    () => (
      <>
        {pendingRecords ? (
          <>
            <Button color="primary" id="update_record" onClick={handleUpdate}>
              {isPending ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                'Update'
              )}
            </Button>
            <UncontrolledTooltip placement="left" target="update_record">
              {pendingRecords} records pending.
            </UncontrolledTooltip>
          </>
        ) : null}
      </>
    ),
    [handleUpdate, isPending, pendingRecords],
  );

  return (
    <TableDashboard
      pageTitle="Cash Ledger"
      setRefresh={setRefresh}
      additionalChild={renderUpdateButton}
    >
      <CashLedgerTable
        refresh={refresh}
        setRefresh={setRefresh}
        setPendingRecords={setPendingRecords}
      />
    </TableDashboard>
  );
};

export default CashLedger;
