import React, { useEffect, useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  splitStringyNumber,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { getSingleCl, updateCl } from '@redux/actions/clsActions';

import EditCashLedgerForm from './components/EditCashLedgerForm';

const EditCashLedger = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const history = useHistory();
  const { showErrorToast, showSuccessToast } = useToast();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const cashLedgers = useSelector(state => state.cls);

  const [valid, setValid] = useState(false);

  const page = getQueryParam('from');
  const dateRangeInput = getQueryParam('dateRangeInput');
  const effectiveStartDate = getQueryParam('effectiveStartDate');
  const effectiveEndDate = getQueryParam('effectiveEndDate');
  const pageSize = getQueryParam('pageSize');

  useEffect(() => {
    if (token) {
      dispatch(getSingleCl(token, id));
    }

    setValid(true);
  }, [id]);

  const data = cashLedgers.cl?.response || {};

  const redirectUrl = useMemo(() => {
    const queryParams = buildQueryParams({
      dateRangeInput,
      effectiveStartDate,
      effectiveEndDate,
      pageSize,
    });

    return `/cash-ledger?page=${page}${queryParams}`;
  }, [
    page,
    dateRangeInput,
    effectiveStartDate,
    effectiveEndDate,
    pageSize,
    id,
  ]);

  useEffect(() => {
    if (valid) {
      if (cashLedgers?.err) {
        if (unauthorizedError(cashLedgers)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        const errorMessage = cashLedgers.err || msgs.error;
        showErrorToast(errorMessage);
      } else if (cashLedgers?.success) {
        showSuccessToast();
        history.push(redirectUrl);
      }
    }
  }, [valid, cashLedgers, history]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const payloadValues = {
      ...values,
      adjustmentAmount: Number(splitStringyNumber(values.adjustmentAmount)),
    };

    dispatch(updateCl(token, id, payloadValues));
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Cash Ledger Summary Detail</h3>

      <FormSpinner requesting={cashLedgers?.requesting} />

      <EditCashLedgerForm
        data={data}
        onSubmit={handleForm}
        redirectUrl={redirectUrl}
      />
    </Container>
  );
};

export default EditCashLedger;
