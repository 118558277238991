import React, { useEffect } from 'react';

import { useDispatch, connect } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';

import { cpStateReset } from '@redux/actions/cpActions';
import { reset } from '@redux/reducers/formFieldsReducer';
import { RTLProps } from '@shared/prop-types/ReducerProps';

import ABTestingAnalytics from './components/ABTestingAnalytics';
import AudienceByCountry from './components/AudienceByCountry';
import BestSellingRegions from './components/BestSellingRegions';
import BounceRate from './components/BounceRate';
import BounceRateArea from './components/BounceRateArea';
import BudgetStatistic from './components/BudgetStatistic';
import GoalsCompletion from './components/GoalsCompletion';
import NewUsers from './components/NewUsers';
import SalesStatistic from './components/SalesStatistic';
import TotalPageViews from './components/TotalPageViews';
import VisitorsSessions from './components/VisitorsSessions';
import Visits from './components/Visits';

// const { t } = useTranslation('common');

const OnLineMarketingDashboard = ({ rtl }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
  }, [dispatch]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          {/* <h3 className="page-title">{t('online_marketing_dashboard.page_title')}</h3> */}
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <Visits />
        <TotalPageViews />
        <NewUsers />
        <BounceRate />
      </Row>
      <Row>
        <ABTestingAnalytics dir={rtl.direction} />
        <BounceRateArea dir={rtl.direction} />
        <VisitorsSessions dir={rtl.direction} />
        <SalesStatistic />
        <BudgetStatistic />
        <AudienceByCountry />
        <BestSellingRegions />
        <GoalsCompletion />
      </Row>
    </Container>
  );
};

OnLineMarketingDashboard.propTypes = {
  rtl: RTLProps.isRequired,
};

export default compose(
  connect(state => ({
    rtl: state.rtl,
  })),
)(OnLineMarketingDashboard);
