import { useEffect, useMemo, useState } from 'react';

import CSVReader from 'react-csv-reader';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import { Select } from '@atoms';
import { msgs } from '@config/conf';
import { filterOptions } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { getCustodians } from '@redux/actions/custodiansActions';

const ImportPandWsForm = ({ data }) => {
  const {
 control, reset, handleSubmit, watch,
} = useForm();
  const dispatch = useDispatch();
  const custodians = useSelector(state => state.custodians);
  const history = useHistory();
  const { token, logout } = useAuth();
  const { showErrorToast } = useToast();

  const [valid, setValid] = useState();
  const [readerKey, setReaderKey] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileData, setFileData] = useState([]);

  useEffect(() => {
    reset(data);
    if (token) {
      dispatch(getCustodians(token));
      setValid(true);
    }
  }, [data]);

  useEffect(() => {
    if (valid) {
      if (custodians?.err === 401) {
        logout();
        showErrorToast(msgs.authError);
        history.push('/');
      }
    }
  }, [custodians, valid]);

  const requiredProps = {
    control,
    required: true,
    rules: {
      required: msgs.fieldError,
    },
  };

  const custodianOptions = useMemo(
    () => filterOptions(
        custodians?.custodians?.data,
        'custodianID',
        'custodianName',
      ),
    [custodians?.custodians?.data],
  );

  const onSubmit = (values) => {
    let error = false;
    const indexes = [];
    if (fileData.length > 0 && fileName) {
      fileData.forEach((row, index) => {
        if (Object.keys(row).length > 5) {
          error = true;
          indexes.push(index + 1);
        } else {
          if (
            !row.sourceReference
            || !row.reAccountNumber
            || !row.activityDate
            || !row.activityAmount
          ) {
            error = true;
            indexes.push(index + 1);
          }
          if (row.reAccountNumber !== Number(row.reAccountNumber)) {
            error = true;
            indexes.push(index + 1);
          }
          if (row.activityAmount !== Number(row.activityAmount)) {
            error = true;
            indexes.push(index + 1);
          }
          row.activityDate = row.activityDate.split(' ')[0];
          row.effectiveDate = row.effectiveDate.split(' ')[0];
          if (new Date(row.activityDate).toString() === 'Invalid Date') {
            error = true;
            indexes.push(index + 1);
          }
          if (row.effectiveDate) {
            if (new Date(row.effectiveDate).toString() === 'Invalid Date') {
              error = true;
              indexes.push(index + 1);
            }
          }
          row.reAccountNumber = Number(row.reAccountNumber);
          row.activityAmount = Number(row.activityAmount);
        }
      });
      if (!error) {
        setReaderKey(Math.random().toString(36));
        error = false;
      } else {
        setReaderKey(Math.random().toString(36));
        showErrorToast(`Schema violation record ${indexes[0]}`);
      }
    } else {
      showErrorToast('Choose a file first');
    }
  };

  const onFileLoaded = (data, fileInfo) => {
    if (fileInfo.name.split('.')[1] === 'csv') {
      setFileName(fileInfo.name);
      setFileData(data);
    } else {
      showErrorToast('File format not supported');
    }
  };

  return (
    <div>
      <Card className="container_width">
        <CardBody>
          <div className="form form--horizontal form_spacing">
            <Select
              {...requiredProps}
              label="Custodian"
              name="custodianID"
              options={custodianOptions}
              isLoading={custodians?.requesting}
              errorMessage={custodians?.err}
            />
            <div className="form__form-group">
              <label className="form__form-group-label">
                Select File <span className="text-danger">*</span>
              </label>
              <div className="form__form-group-file">
                <label htmlFor="csv-file">Choose the file</label>
                {fileName ? <span>{fileName}</span> : null}
              </div>

              <div key={readerKey}>
                <CSVReader
                  inputId="csv-file"
                  cssClass="form-control-file"
                  inputStyle={{ width: '100%', display: 'none' }}
                  parserOptions={{ header: true }}
                  onFileLoaded={onFileLoaded}
                />
              </div>
            </div>
            <div className="form__form-btns w-100 flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
              >
                Import
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ImportPandWsForm;
