import { useCallback, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { displayNumberFormat } from '@containers/components/helpers';

import AnnuityDocumentsPage from './AnnuityDocumentsPage';

const taxSourceData = { PreTax: 1, Roth: 2, AfterTax: 3 };

const Summary = (props) => {
  const { data, tabClick } = props;

  const total = useMemo(() => data?.reduce((res, element) => (res += element.policyAccumulationValue), 0), [data]);

  const handleTaxTab = useCallback(
    (item) => {
      const tabNumber = taxSourceData[item?.taxCode];
      if (tabNumber) {
        tabClick(tabNumber);
      }
    },
    [tabClick],
  );

  return (
    <Grid container spacing={3} className="annuity_summary_wrapper">
      <Grid item xs={12} sm={6}>
        <Paper className="annuity_provider">
          <Typography className="text-white font-weight-bold">
            Allianz Annuity
          </Typography>

          <div>
            <Typography className="text-white font-weight-bold">
              Total Accumulation Value
            </Typography>

            <Typography className="text-white font-weight-bold text-right">
              <span className="MuiTypography-h6">
                {'$' + displayNumberFormat(total, 2).toString().split('.')[0]}
              </span>
              <sup className="sc-fotOHu dfigil">
                {displayNumberFormat(total, 2).toString().split('.')[1]}
              </sup>
            </Typography>
          </div>
        </Paper>

        {data.map(item => (
          <Grid
            key={item.reAccountNumber}
            container
            spacing={1}
            className="border-bottom border-dark mt-2"
          >
            <Grid item xs={6} sm={4}>
              <span className="pl-3 align-middle">{item.taxCode}</span>
            </Grid>
            <Grid item xs={6} sm={4}>
              <button onClick={() => handleTaxTab(item)} className="btn">
                View Policy Info
                <div>{item.policyNumber}</div>
              </button>
            </Grid>

            <Grid item xs={6} sm={4} className="pl-5">
              <div className="container">Accumulation Value</div>
              <div className="container font-weight-bold">
                <span className="MuiTypography-h6">
                  {'$'
                    + displayNumberFormat(item.policyAccumulationValue, 2)
                      .toString()
                      .split('.')[0]}
                </span>
                <sup className="sc-fotOHu dfigil">
                  {
                    displayNumberFormat(item.policyAccumulationValue, 2)
                      .toString()
                      .split('.')[1]
                  }
                </sup>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper className="annuity_provider">
          <Typography className="text-white font-weight-bold">
            Annuity Provider/Product
          </Typography>
        </Paper>
        <AnnuityDocumentsPage />
        {/* <Grid item xs={6} sm={12} >
              <a className="button button-primary" style={{float: 'right', marginRight:'20px', verticalAlign:'middle', backgroundColor:'#7199bb', borderRadius: '5px', display: 'block', width: '25px', height: '25px', textAlign: 'center', color: 'white', fontWeight: 'bold'}} target="_blank" rel="noreferrer"  href="https://www.allianzlife.com/what-we-offer/annuities/fixed-index-annuities/lifetime-income-plus-ijoin">{String.fromCharCode(8594)}</a>
            </Grid>          */}
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Summary;
