import React from 'react';

import { Form, Input } from 'reactstrap';

const AccountsTableSearch = ({
  searchInput,
  setSearchInput,
  submitSearch,
  setSubmitSearch,
  setExportSearch,
}) => {
  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setSearchInput('');
    setExportSearch('');
  };
  return (
    <div className="table__search mb-0">
      <Form
        inline
        className="w-100"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          // alert(JSON.stringify({ searchInput, wfType, fromDate, toDate }));
          setSubmitSearch(true);
        }}
      >
        <Input
          type="text"
          placeholder="First, Last, SS4, Account # or Policy #"
          className="table__search table__search-input mb-0"
          value={searchInput}
          onChange={(e) => {
            const val = e.target.value;
            setSearchInput(val);
            setExportSearch(val);
          }}
          style={{ maxWidth: '350px', width: '100%' }}
          maxLength="50"
        />
        <Input
          type="submit"
          className="btn-blue btn-w text-light table__search table__search-input mb-0"
          value="Filter"
        />
        {searchInput && (
          <Input
            type="reset"
            className="btn-w text-light table__search table__search-input mb-0"
            value="Clear"
            onClick={handleClear}
          />
        )}
      </Form>
    </div>
  );
};

export default AccountsTableSearch;
