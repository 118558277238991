import React from 'react';

import {
 Card, CardBody, Form, Input,
} from 'reactstrap';

const UsersTableSearch = ({ searchInput, setSearchInput, setSubmitSearch }) => {
  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setSearchInput('');
  };

  return (
    <Card className="pb-2">
      <CardBody className="py-2 bg-green table__search mb-0">
        <Form
          inline
          className="w-100"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitSearch(true);
          }}
        >
          <Input
            type="text"
            placeholder="User Name or Login Name"
            className="table__search table__search-input mb-0 w-100"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            maxLength="50"
          />
          <Input
            type="submit"
            className="btn-blue btn-w text-light table__search table__search-input mb-0"
            value="Filter"
          />
          {searchInput ? (
            <Input
              type="reset"
              className="btn-w text-light table__search table__search-input mb-0"
              value="Clear"
              onClick={handleClear}
            />
          ) : null}
        </Form>
      </CardBody>
    </Card>
  );
};

export default UsersTableSearch;
