import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getWorkflows = (
    token,
    limit = 0,
    offset = 0,
    orderBy = null,
    search = null,
    workflowType = null,
    fromDate = null,
    toDate = null,
    statusInput = null,
  ) => async (dispatch) => {
    dispatch({
      type: ActionTypes.WFS_REQUESTING,
    });

    await axios
      .get(
        conf.serverURL
          + `workflows-and-log/get?Limit=${limit}&Offset=${offset}${
            !orderBy ? '' : '&OrderBy=' + orderBy
          }${
            !workflowType
              ? ''
              : '&workflowType=' + encodeURIComponent(workflowType)
          }${!search ? '' : '&search=' + search}${
            !fromDate ? '' : '&fromDate=' + fromDate
          }${!toDate ? '' : '&toDate=' + toDate}${
            !statusInput ? '' : '&status=' + statusInput
          }`,
        {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        },
      )
      .then((resp) => {
        dispatch({
          type: ActionTypes.WFS_FETCHED,
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.WFS_ERROR,
          payload: err.response
            ? err.response.status === 401
              ? err.response.status
              : err.response.data.message
            : err.message,
        });
      });
  };

export const getSingleWorkflow = (token, id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.WFS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `workflows/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.WF_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.WFS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
        // payload: err,
      });
    });
};

export const updateWorkflow = (token, id, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.WFS_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `workflows/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.WF_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.WFS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
