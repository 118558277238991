import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getSingleProductFactor = (token, id) => async (dispatch) => {
    dispatch({ type: ActionTypes.PRODUCT_FACTOR_REQUESTING });

    await axios
      .get(`${conf.serverURL}products/productFactors/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      })
      .then((resp) => {
        dispatch({
          type: ActionTypes.PRODUCT_FACTOR_FETCHED,
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.PRODUCT_FACTOR_ERROR,
          payload: err.response
            ? err.response.status === 401
              ? err.response.status
              : err.response.data.message
            : err.message,
        });
      });
  };

  export const createProductFactor = (token, values) => async (dispatch) => {
    dispatch({ type: ActionTypes.PRODUCT_FACTOR_REQUESTING });

    await axios
      .post(`${conf.serverURL}products/productfactors`, values, {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      })
      .then((resp) => {
        dispatch({
          type: ActionTypes.PRODUCT_FACTOR_ADDED,
          payload: resp.status,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.PRODUCT_FACTOR_ERROR,
          payload: err.response
            ? err.response.status === 401
              ? err.response.status
              : err.response.data.message
            : err.message,
        });
      });
  };

  export const updateProductFactor = (token, id, values) => async (dispatch) => {
    dispatch({ type: ActionTypes.PRODUCT_FACTOR_REQUESTING });

    await axios
      .put(`${conf.serverURL}products/productFactor/${id}`, values, {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      })
      .then((resp) => {
        dispatch({
          type: ActionTypes.PRODUCT_FACTOR_UPDATED,
          payload: resp.status,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.PRODUCT_FACTOR_ERROR,
          payload: err.response
            ? err.response.status === 401
              ? err.response.status
              : err.response.data.message
            : err.message,
        });
      });
  };
