import { useEffect, useMemo, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';

import { Input, FormFooterActions } from '@atoms';
import {
  formatCurrency,
  formatDate,
  formatFixedDigits,
  formatToPercentage,
} from '@containers/components/helpers';

import WorkflowModal from './WorkflowModal';

const validWorkflowTypeIDs = [1001, 1002, 1003, 1004, 1006, 1007, 1008];

const WorkflowsForm = ({
  id,
  data,
  goBackUrl,
  gotoWorkflowTypeIdUrl,
  onSubmit,
}) => {
  const modalRef = useRef(null);
  const { control, reset, handleSubmit } = useForm();

  const workflowFormFields = useMemo(
    () => [
      { name: 'workflowNumber', label: 'Workflow Number' },
      { name: 'rootWorkflowNumber', label: 'Root Workflow #' },
      { name: 'workflowType', label: 'Workflow Type' },
      { name: 'workflowStartDate', label: 'Start Date' },
      { name: 'workflowEndDate', label: 'End Date' },
      { name: 'activityPercent', label: 'Activity Percent' },
      {
        name: 'activityAmount',
        label: 'Activity Amount',
        className: data?.activityAmount < 0 ? 'text-danger' : '',
      },
      {
        name: 'settledAmount',
        label: 'Settled Amount',
        className: data?.settledAmount < 0 ? 'text-danger' : '',
      },
      { name: 'workflowDescription', label: 'Workflow Description' },
      { name: 'reAccountNumber', label: 'Re Account #' },
      { name: 'policyNumber', label: 'Policy #' },
      { name: 'carrierName', label: 'Carrier' },
      { name: 'productName', label: 'Product' },
      { name: 'planName', label: 'Plan' },
      { name: 'participantName', label: 'Participant' },
      { name: 'status', label: 'Status' },
      { name: 'subStatus', label: 'Sub Status' },
      { name: 'statusDate', label: 'Status Updated' },
      { name: 'statusMessage', label: 'Status Message' },
      { name: 'userName', label: 'Created By' },
    ],
    [data?.settledAmount, data?.activityAmount],
  );

  useEffect(() => {
    const {
      alertDate,
      workflowNumber,
      rootWorkflowNumber,
      workflowStartDate,
      workflowEndDate,
      activityPercent,
      activityAmount,
      settledAmount,
      statusDate,
      reAccountNumber,
    } = data || {};
    reset({
      ...data,
      alertDate: alertDate ? formatDate(alertDate) : '',
      workflowNumber: workflowNumber
        ? formatFixedDigits(12, workflowNumber)
        : '',
      rootWorkflowNumber: rootWorkflowNumber
        ? formatFixedDigits(12, rootWorkflowNumber)
        : '',
      workflowStartDate: workflowStartDate ? formatDate(workflowStartDate) : '',
      workflowEndDate: workflowEndDate ? formatDate(workflowEndDate) : '',
      activityPercent: activityPercent
        ? formatToPercentage(Math.abs(activityPercent))
        : '0.00%',
      activityAmount: activityAmount
        ? formatCurrency(Math.abs(activityAmount), 2)
        : '$0.00',
      settledAmount: settledAmount
        ? formatCurrency(Math.abs(settledAmount), 2)
        : '$0.00',
      statusDate: statusDate ? formatDate(statusDate) : '',
      reAccountNumber: reAccountNumber !== 0 ? reAccountNumber : '',
    });
  }, [reset, data]);

  const buttonActions = {
    buttonProps: [
      data?.status === 'Error'
        ? [
            {
              labelName: 'Resubmit',
              onClick: modalRef.current?.toggleResubmitModal,
            },
            {
              labelName: 'Cancel',
              onClick: modalRef.current?.toggleFailModal,
            },
          ]
        : [],

      data?.alertSet
        ? {
            labelName: 'Clear Alert',
            onClick: modalRef.current?.toggleAlertModal,
          }
        : null,

      validWorkflowTypeIDs.includes(data?.workflowTypeID)
        ? {
            labelName: 'Detail',
            linkUrl: gotoWorkflowTypeIdUrl,
          }
        : null,

      { onClick: handleSubmit(onSubmit) },
    ],
  };

  return (
    <div>
      <WorkflowModal
        id={id}
        goBackUrl={goBackUrl}
        workflowTypeID={data?.workflowTypeID}
        ref={modalRef}
      />
      <Card className="container_width">
        <CardBody>
          <div className="form form--horizontal form_spacing">
            {data?.alertSet ? (
              <>
                <Input
                  control={control}
                  name="alertDate"
                  label="Alert Date"
                  isReadOnly
                  labelClassName="text-danger"
                />
                <Input
                  control={control}
                  name="alertMessage"
                  label="Alert Message"
                  isReadOnly
                  labelClassName="text-danger"
                />
              </>
            ) : null}
            {workflowFormFields?.map(({ label, name, className }) => (
              <Input
                key={name}
                control={control}
                name={name}
                className={className}
                label={label}
                isReadOnly
              />
            ))}

            <Input
              type="textarea"
              control={control}
              name="notes"
              label="Notes"
              maxLength="5000"
            />
            <FormFooterActions
              linkProps={[{ path: goBackUrl }]}
              {...buttonActions}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default WorkflowsForm;
