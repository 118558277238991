import React, { useState, useEffect } from 'react';

import DownIcon from 'mdi-react/ChevronDownIcon';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Collapse } from 'reactstrap';

// const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
import { useAuth } from '@contexts/AuthContext';
import { cpStateReset } from '@redux/actions/cpActions';

import TopbarMenuLink from './TopbarMenuLink';

// const TopbarProfile = ({ auth0, user }) => {
const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, logout } = useAuth();
  //  HEAD
  // const { user } = useAuth();
  // const [payload, setPayload] = useState("");
  // useEffect(async () => {
  //   setPayload(
  //     (await localStorage.getItem("authToken"))
  //       ? jwt_decode(await localStorage.getItem("authToken"))
  //       : null
  //   );
  //   // console.log(localStorage.getItem('authToken'), 'tokennnn')
  // }, []);

  // console.log("====================================");
  // console.log("Profile User", user);
  // console.log("====================================");
  // console.log("Profile Token", token);
  // console.log("====================================");

  // useEffect(() => {
  //   dispatch(cpStateReset());
  // }, [dispatch]);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <div className="topbar__profile mr-3 align-items-center">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        {/* <img
          className="topbar__avatar-img"
          src={(auth0.user && auth0.user.picture) || (user && user.avatar) || Ava}
          alt="avatar"
        /> */}
        <p style={{ minWidth: '105px', paddingTop: '23px' }}>
          {/* { auth0.loading ? 'Loading...' : (auth0.user && auth0.user.name) || (user && user.fullName)} */}
          {user ? user.UserName : 'User'}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {/* <TopbarMenuLink
            title="My Profile"
            icon="user"
            path="/account/profile"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Calendar"
            icon="calendar-full"
            path="/default_pages/calendar"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Tasks"
            icon="list"
            path="/todo"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Inbox"
            icon="inbox"
            path="/mail"
            onClick={toggleProfile}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Account Settings"
            icon="cog"
            path="/account/profile"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Lock Screen"
            icon="lock"
            path="/lock_screen"
            onClick={toggleProfile}
          /> */}

          {/* <TopbarMenuLink
            title="Update Profile"
            icon="user"
            path="/profile/update"
            // onClick={logout}
          /> */}
          <TopbarMenuLink
            title="Change Password"
            icon="lock"
            path="/password/change"
            // onClick={logout}
          />
          <TopbarMenuLink
            title="Sign out"
            icon="exit"
            path="/"
            onClick={handleLogout}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
