import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  loading: true,
  err: null,
  result: null,
};

const custodianPlansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.CUSTODIAN_PLANS_LOADING:
    return {
      ...state, loading: true, err: null, result: null,
    };

  case ActionTypes.CUSTODIAN_PLANS_ERROR:
    return {
      ...state, loading: false, err: payload, result: null,
    };

  case ActionTypes.CUSTODIAN_PLANS_LOADED:
    return {
      ...state, loading: false, err: null, result: payload,
    };

  default:
    return state;
  }
};

export default custodianPlansReducer;
