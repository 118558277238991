import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import CarrierProductsForm from '../CarrierProductsForm/CarrierProductsForm';
import CarrierProductsTableData from '../components/CarrierProductsTableData';

const { tableRowsData } = CarrierProductsTableData();

const EditCarrierProduct = (props) => {
  const {
    match: {
      params: { carrierId, productId },
    },
  } = props;
  const inputData = tableRowsData.find(
    row => row.id === parseInt(productId, 10),
  );
  const data = {
    ...inputData,
    type: 'edit',
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Edit Product</h3>

      <CarrierProductsForm data={data} carrierId={carrierId || 0} />
    </Container>
  );
};

EditCarrierProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      carrierId: PropTypes.number.isRequired,
      productId: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default EditCarrierProduct;
