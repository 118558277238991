import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Badge } from 'reactstrap';

import { Input, Modal } from '@atoms';
import { conf, msgs } from '@config/conf';
import {
  formatDate,
  formatFixedDigits,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';

const purchaseWsFormFields = [
  { label: 'Workflow Number', name: 'workflowNumber' },
  { label: 'Start Date', name: 'workflowStartDate' },
  { label: 'End Date', name: 'workflowEndDate' },
  { label: 'Status', name: 'status' },
  { label: 'SubStatus', name: 'subStatus' },
  { label: 'Status Updated', name: 'statusDate' },
  { label: 'Status Message', name: 'statusMessage' },
  { label: 'Custodian', name: 'custodianName' },
  { label: 'File Identifier', name: 'fileIdentifier' },
  { label: 'Accepted', name: 'totAccepted' },
  { label: 'Rejected', name: 'totRejected' },
  { label: 'Purchases', name: 'totPurchases' },
  { label: 'Withdrawals', name: 'totWithdrawals', className: 'text-danger' },
];

const EditPandWsForm = ({ data, redirectUrl }) => {
  const { control, reset } = useForm();
  const csvClickRef = useRef();
  const history = useHistory();
  const { token, logout } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();

  const [rejectModal, setRejectModal] = useState(false);
  const [firstSubmitModal, setFirstSubmitModal] = useState(false);
  const [secondSubmitModal, setSecondSubmitModal] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const rejectToggle = () => setRejectModal(!rejectModal);
  const firstSubmitToggle = () => setFirstSubmitModal(!firstSubmitModal);
  const secondSubmitToggle = () => setSecondSubmitModal(!secondSubmitModal);

  const rejectWorkflow = async () => {
    await axios
      .post(
        conf.serverURL + `operations/pandw/reject/${data && data.workflowID}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        },
      )
      .then((resp) => {
        if (resp.data.workflowID) {
          showSuccessToast();
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(err.response?.data.message || msgs.error);
        }
      });
  };

  const submitWorkflow = async () => {
    await axios
      .post(
        conf.serverURL + `operations/pandw/submit/${data && data.workflowID}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        },
      )
      .then((resp) => {
        if (resp.data.workflowID) {
          showSuccessToast();
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(err.response?.data.message || msgs.error);
        }
      });
  };

  const onExport = async () => {
    setExporting(true);
    await axios
      .get(conf.serverURL + 'operations/pandw/' + (data && data.workflowID), {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      })
      .then((resp) => {
        setCsvData(resp.data.data);
      })
      .catch((err) => {
        setExporting(false);
        if (err.response?.status === 401) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(err.response?.data.message || err.message);
        }
      });
  };

  useEffect(() => {
    reset({
      ...data,
      alertDate: formatDate(data.alertDate),
      workflowNumber: formatFixedDigits(12, data.workflowNumber) || '',
      workflowStartDate: formatDate(data.workflowStartDate),
      workflowEndDate: formatDate(data.workflowEndDate),
      totPurchases: data.totPurchases || '$0.00',
      totWithdrawals: data.totWithdrawals || '$0.00',
    });
  }, [reset, data]);

  useEffect(() => {
    if (csvData.length && exporting) {
      csvClickRef.current.link.click();
      setExporting(false);
    }
  }, [csvData, exporting]);

  const onConformRejectModal = () => {
    rejectToggle();
    rejectWorkflow();
  };

  const onConformFirstSubmitModal = () => {
    firstSubmitToggle();
    secondSubmitToggle();
  };

  const onConformSecondSubmitModal = () => {
    secondSubmitToggle();
    submitWorkflow();
  };

  const modalButtons = (onCancel, onConform) => [
    {
      name: 'Cancel',
      color: 'danger',
      onClick: onCancel,
    },
    {
      name: 'Confirm',
      color: 'primary',
      onClick: onConform,
    },
  ];

  return (
    <div>
      <Modal
        isModalOpen={rejectModal}
        setIsModalOpen={setRejectModal}
        content="Are you sure you wish to reject this workflow?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={modalButtons(rejectToggle, onConformRejectModal)}
      />
      <Modal
        isModalOpen={firstSubmitModal}
        setIsModalOpen={setFirstSubmitModal}
        content="Are you sure you wish to submit this workflow?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={modalButtons(
          firstSubmitToggle,
          onConformFirstSubmitModal,
        )}
      />
      <Modal
        isModalOpen={secondSubmitModal}
        setIsModalOpen={setSecondSubmitModal}
        content="Please confirm funds have been received and verified?"
        title="Attention"
        variant="danger"
        iconName="lnr-flag"
        modalButtons={modalButtons(
          secondSubmitToggle,
          onConformSecondSubmitModal,
        )}
      />
      <Card className="container_width">
        <CardBody>
          <div className="form form--horizontal form_spacing">
            {data?.alertSet === 'Yes' ? (
              <>
                <Input
                  control={control}
                  label="Alert Date"
                  name="alertDate"
                  isReadOnly
                  labelClassName="text-danger"
                />
                <Input
                  control={control}
                  label="Alert Message"
                  name="alertMessage"
                  isReadOnly
                  labelClassName="text-danger"
                />
              </>
            ) : null}
            {purchaseWsFormFields?.map(({ label, name, className }) => (
              <Input
                control={control}
                key={name}
                className={className}
                label={label}
                name={name}
                isReadOnly
              />
            ))}
            <div className="form__form-btns w-100 flex justify-content-between">
              <Link to={redirectUrl} className="btn btn-outline-danger">
                Cancel
              </Link>
              <div>
                {data.status === 'In Progress'
                && data.subStatus === 'Accepted' ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={rejectToggle}
                    >
                      Reject
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={firstSubmitToggle}
                    >
                      Submit
                    </button>
                  </>
                ) : null}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onExport}
                >
                  {exporting ? (
                    <span className="spinner-border spinner-border-sm" />
                  ) : (
                    'Export'
                  )}
                </button>
                <CSVLink
                  filename={
    formatFixedDigits(12, data && data.workflowNumber) + '.csv'
  }
                  style={{ display: 'none' }}
                  ref={csvClickRef}
                  data={csvData}
                >
                  <Badge color="primary">Export</Badge>
                </CSVLink>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditPandWsForm;
