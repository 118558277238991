import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getProducts = (token, limit = 0, offset = 0, orderBy = null) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PRODUCTS_REQUESTING,
  });

  await axios
    .get(
      conf.serverURL
          + `products-and-pios/get?limit=${limit}&offset=${offset}${
            orderBy === null ? '' : '&OrderBy=' + orderBy
          }`,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.PRODUCTS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PRODUCTS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const createProduct = (token, data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PRODUCTS_REQUESTING,
  });

  await axios
    .post(conf.serverURL + 'products/create', data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PRODUCT_ADDED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PRODUCTS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const getSingleProduct = (id, token) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PRODUCTS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `products/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PRODUCT_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PRODUCTS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updateProduct = (id, data, token) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PRODUCTS_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `products/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PRODUCT_UPDATED,
        payload: resp.data,
      });
    })
    .catch(err => dispatch({
      type: ActionTypes.PRODUCTS_ERROR,
      payload: err.response
        ? err.response.status === 401
          ? err.response.status
          : err.response.data.message
        : err.message,
    }));
};
