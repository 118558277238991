import { useCallback, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const buildQueryParams = (params) => {
    const queryParams = Object.entries(params) || [];
    if (queryParams.length) {
      return queryParams
        .filter(([_, value]) => value)
        .map(([key, value]) => `&${key}=${value}`)
        .join('');
    }
    return '';
  };

  const getQueryParam = useCallback(
    paramName => (paramName ? query.get(paramName) : ''),
    [query],
  );

  return {
    getQueryParam,
    buildQueryParams,
  };
};

export default useQueryParams;
