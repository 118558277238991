import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import {
  getSingleCustodian,
  updateCustodian,
} from '@redux/actions/custodiansActions';

import CustodiansForm from '../CustodiansForm/CustodiansForm';

const EditCustodian = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const dispatch = useDispatch();
  const custodians = useSelector(state => state.custodians);
  const { token, logout } = useAuth();
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const { getQueryParam } = useQueryParams();
  const history = useHistory();
  const [valid, setValid] = useState(false);

  const page = getQueryParam('from');
  const pageSize = getQueryParam('pageSize');

  useEffect(() => {
    if (token) {
      dispatch(getSingleCustodian(token, id));
    }
    setValid(true);
  }, [id]);

  const data = custodians?.custodian || {};
  const redirectUrl = useMemo(
    () => `/custodians?page=${page}${pageSize ? '&pageSize=' + pageSize : ''}`,
    [page, pageSize],
  );

  useEffect(() => {
    if (valid) {
      if (custodians?.err) {
        if (unauthorizedError(custodians)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(custodians.err || msgs.error);
      } else if (custodians?.success) {
        showSuccessToast();
        history.push(redirectUrl);
      }
    }
  }, [valid, custodians, history]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { custodianName, custodianCode } = values || {};

    const isValid = validateRequiredField({
      'Custodian Name': custodianName,
      'Custodian Code': custodianCode,
    });

    if (!isValid) {
      return;
    }

    dispatch(updateCustodian(token, id, values));
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Custodian Detail</h3>

      {custodians?.requesting ? (
        <p className="text-center text-primary mb-3">
          <span className="spinner-border spinner-border-sm" />
        </p>
      ) : null}

      <CustodiansForm
        data={data}
        onSubmit={handleForm}
        redirectUrl={redirectUrl}
      />
    </Container>
  );
};

export default EditCustodian;
