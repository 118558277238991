import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useApiFetch from '@hooks/useApiFetch';
import useToast from '@hooks/useToast';
import { createCarrier } from '@redux/actions/carriersActions';

import CarrierForm from '../CarrierForm/CarrierForm';

const defaultValues = {
  carrierName: '',
  extCarrierCode: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  endpointSetID: 1,
};

const AddCarrier = () => {
  const dispatch = useDispatch();
  const carriers = useSelector(state => state.carriers);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const { get } = useApiFetch();
  const { token, logout } = useAuth();
  const [valid, setValid] = useState(false);

  const getCarriersData = async () => {
    const response = await get('carriers/get');
    if (response?.data?.data?.[0]?.carrierID) {
      localStorage.setItem('carrierID', response?.data?.data?.[0]?.carrierID);
    }
  };

  useEffect(() => {
    if (valid) {
      if (carriers?.err) {
        if (unauthorizedError(carriers)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          const errorMessage = carriers.err || msgs.error;
          showErrorToast(errorMessage);
        }
      } else if (carriers?.success) {
        getCarriersData();
        showSuccessToast();
        history.push('/carriers');
      }
    }
  }, [carriers, valid, getCarriersData]);

  const handleForm = (values) => {
    const { carrierName, extCarrierCode, endpointSetID } = values || {};

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const isValid = validateRequiredField({
      'Carrier Name': carrierName,
      'Carrier Code': extCarrierCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      endpointSetID: Number(endpointSetID),
    };

    dispatch(createCarrier(token, payloadValues));

    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Carrier Detail</h3>

      <CarrierForm
        carriers={defaultValues}
        redirectUrl="/carriers"
        onSubmit={handleForm}
      />
    </Container>
  );
};

export default AddCarrier;
