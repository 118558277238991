import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { getSingleUser, updateUsers } from '@redux/actions/usersActions';

import UserForm from '../UsersForm/UserForm';

const Edituser = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const { token, logout } = useAuth();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const fromPage = getQueryParam('from');
  const search = getQueryParam('search');

  const sortUserType = getQueryParam('sortUserType');
  const sortUserName = getQueryParam('sortUserName');
  const sortStatus = getQueryParam('sortStatus');
  const pageSize = getQueryParam('pageSize');

  const queryParams = useMemo(
    () => buildQueryParams({
        search,
        sortUserType,
        sortUserName,
        sortStatus,
        pageSize,
      }),
    [pageSize, search, sortStatus, sortUserName, sortUserType],
  );

  const navigateToUsers = `/users?page=${fromPage}${queryParams}`;

  useEffect(() => {
    if (token) {
      dispatch(getSingleUser(token, id));
    }
    setValid(true);
  }, [id]);

  const handelEditUser = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { userName, loginName } = values || {};

    const isValid = validateRequiredField({
      'User Name': userName,
      'Login Name': loginName,
    });

    if (!isValid) {
      return;
    }
    dispatch(updateUsers(token, id, values));
  };

  const data = users?.user?.data || {};

  useEffect(() => {
    if (valid) {
      if (users?.err) {
        if (unauthorizedError(users)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(users.err || msgs.error);
      } else if (users?.success) {
        showSuccessToast();
        history.push(navigateToUsers);
      }
    }
  }, [users, valid, history]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">User Detail</h3>

      <FormSpinner requesting={users?.requesting} />

      <UserForm
        data={data}
        id={id}
        redirectUrl={navigateToUsers}
        onSubmit={handelEditUser}
      />
    </Container>
  );
};

export default Edituser;
