import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getPppios = (token, planProductId, productId) => async (dispatch) => {
  dispatch({ type: ActionTypes.PPPIOS_REQUESTING });

  await axios
    .get(
      conf.serverURL
          + `plans/planproducts/planproductpios?planProductID=${planProductId}&productID=${productId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.PPPIOS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PPPIOS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updatePppios = (token, id, values) => async (dispatch) => {
  dispatch({ type: ActionTypes.PPPIOS_REQUESTING });

  await axios
    .post(
      conf.serverURL + `plans/planproducts/planproductpios?planproductid=${id}`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.PPPIOS_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PPS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
