import React, {
 useEffect, useState, useMemo, useCallback,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card } from 'reactstrap';

import { Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate } from '@lib/date';
import { getProducts } from '@redux/actions/productsActions';

const ProductsTable = ({ refresh, setRefresh }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const products = useSelector(state => state.products);
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const queryPageSize = getQueryParam('pageSize') || '';
  const sortCarrierName = getQueryParam('sortCarrierName') || '';
  const sortProductName = getQueryParam('sortProductName') || '';
  const page = getQueryParam('page');

  const [pageSize, setPageSize] = useState(queryPageSize || pagination.length);
  const { offset, pageNumber } = getPagination(pageSize, page);
  const { data: productsData, recordCount = 0 } = products?.products || {};

  const navigatePath = useCallback(
    (id = '', childParams = '') => {
      const query = buildQueryParams({
        sortProductName,
        sortCarrierName,
        pageSize,
      });
      const params = `/products/${id}/${childParams}?from=${pageNumber}`;
      return `${params}${query}`;
    },
    [pageNumber, pageSize, sortCarrierName, sortProductName],
  );

  const refreshString = () => {
    if (sortCarrierName) {
      return `Carriers.CarrierName ${sortCarrierName.toUpperCase()}, Products.ProductName ASC, ProductInvOptions.PIOName ASC`;
    }
    if (sortProductName) {
      return `Products.ProductName ${sortProductName.toUpperCase()}, ProductInvOptions.PIOName ASC`;
    }
    return null;
  };

  const refStr = refreshString();

  const getData = useCallback(() => {
    if (token) {
      dispatch(getProducts(token, pageSize, offset, refStr));
    }
    setRefresh(false);
  }, [offset, pageSize, refresh, refStr]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      {
        id: 'expanded-chevron',
        header: '',
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? 'lnr-chevron-down'
              : 'lnr-chevron-right'
          }`;

          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: 'productName',
        header: 'Product Name',
        accessorKey: 'productName',
        enableSorting: true,
      },
      {
        id: 'carrierName',
        header: 'Carrier',
        accessorKey: 'carrierName',
        enableSorting: true,
      },
      {
        id: 'carrierProductCode',
        header: 'Product Code',
        accessorKey: 'carrierProductCode',
      },
      {
        id: 'actions',
        header: '',
        cell: (tableRow) => {
          const { productID, productType } = tableRow?.row?.original || {};
          const isIAProduct = productType === 'Immediate Annuity';
          const gotoEditProductPage = navigatePath(productID, 'edit');
          const gotoAddProductPage = navigatePath(productID, 'pio/add');
          const gotoWRPage = navigatePath(productID, 'wr');
          const gotoAddFactorsPage = navigatePath(productID, 'factor/add');
          return (
            <div className="column-end pr-2 my-1">
              <Link className="mr-2" to={gotoEditProductPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
              <Link className="mr-2" to={gotoAddProductPage}>
                <Badge color="success">Add PIO</Badge>
              </Link>
              {!isIAProduct ? (
                <Link to={gotoWRPage}>
                  <Badge color="success">WR</Badge>
                </Link>
              ) : (
                <Link to={gotoAddFactorsPage}>
                  <Badge color="success">Add Factor</Badge>
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    [navigatePath],
  );

  const piosColumns = useCallback(
    productId => [
      {
        id: 'pioName',
        header: 'PIO Name',
        accessorKey: 'pioName',
      },
      {
        id: 'carrierPIOCode',
        header: 'PIO Code',
        accessorKey: 'carrierPIOCode',
      },
      {
        id: 'pioSelectable',
        header: 'Selectable',
        accessorKey: 'pioSelectable',
      },
      {
        id: 'pioStatus',
        header: 'Status',
        accessorKey: 'pioStatus',
      },
      {
        id: 'pioLastUpdatedOn',
        header: 'Last Updated',
        accessorFn: row => formatDate(row?.pioLastUpdatedOn, 'MM/DD/YY'),
      },
      {
        id: 'edit-view button',
        header: '',
        cell: (tableRow) => {
          const { pioid } = tableRow?.row?.original || {};
          const editPioDetails = navigatePath(productId, `pio/${pioid}/edit`);
          return (
            <div className="column-end pr-2">
              <Link to={editPioDetails}>
                <Badge className="btn-blue">View / Edit</Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigatePath],
  );
  const factorsColumns = useCallback(
    productId => [
      {
        id: 'factorName',
        header: 'Factors Name',
        accessorKey: 'factorName',
      },
      {
        id: 'joint',
        header: 'Joint',
        accessorKey: 'joint',
      },
      {
        id: 'jointPercent',
        header: 'Joint Percent',
        accessorKey: 'jointPercent',
      },
      {
        id: 'refundable',
        header: 'Refundable',
        accessorKey: 'refundable',
      },
      {
        id: 'deferralYears',
        header: 'Deferral years',
        accessorKey: 'deferralYears',
      },
      {
        id: 'selectable',
        header: 'Selectable',
        accessorKey: 'selectable',
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
      },
      {
        id: 'edit-view button',
        header: '',
        cell: (tableRow) => {
          const { productFactorID } = tableRow?.row?.original || {};
          const editFactorsDetails = navigatePath(
            productId,
            `factor/${productFactorID}/edit`,
          );
          return (
            <div className="column-end pr-2">
              <Link to={editFactorsDetails}>
                <Badge className="btn-blue">View / Edit</Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigatePath],
  );

  const renderSubTable = useCallback(
    (subTableprops) => {
      const {
 productType, pios, productFactors, productID,
} = subTableprops.row.original || {};

      const isIAProduct = productType === 'Immediate Annuity';

      const factorColumn = factorsColumns(productID);
      const pioColumn = piosColumns(productID);

      return (
        <Table
          columns={isIAProduct ? factorColumn : pioColumn}
          data={isIAProduct ? productFactors : pios}
          hasNestedTable
        />
      );
    },
    [factorsColumns, piosColumns],
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <Spinner requesting={products?.requesting}>
      <Card>
        <Table
          data={productsData}
          columns={columns}
          renderNestedTable={renderSubTable}
          pageProps={pageProps}
          errStatus={products?.err}
        />
      </Card>
    </Spinner>
  );
};

export default ProductsTable;
