import React from 'react';

import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Badge, Card, CardBody, Table,
} from 'reactstrap';

import CustodiansPlansTableData from './CustodiansPlansTableData';

const { tableRowsData } = CustodiansPlansTableData();

const CustodiansPlansTable = (props) => {
  const { custId } = props;
  // const dispatch = useDispatch();
  // const custodianPlans = useSelector(state => state.custodianPlans);
  // const fltrdCustodianPlans = custodianPlans.result?.filter(plan => plan.custodianId === parseInt(custId, 10));

  // useEffect(() => {
  //   dispatch(getCustodianPlans());
  // }, [dispatch]);

  // if (custodianPlans.loading) {
  //   return (
  //     <div>
  //       <h1 className="text-center text-primary"><span className="spinner-grow">*</span></h1>
  //     </div>
  //   );
  // }

  // if (custodianPlans.err) {
  //   return (
  //     <div>
  //       <p className="text-center text-danger">! {custodianPlans.err}</p>
  //     </div>
  //   );
  // }

  return (
    // <Col md={12} lg={12}>
    <Card>
      <CardBody>
        {/* <div className="card__title">
          <h5 className="bold-text">{t('tables.basic_tables.responsive_table')}</h5>
          <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5>
        </div> */}
        <Table responsive className="table--bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Plan Name</th>
              <th>Federal Tax Id</th>
              <th>Plan Contact Name</th>
              <th>Plan Phone</th>
              <th>Plan Email</th>
              <th>Custodian Plan Code</th>
              <th>Routing Number</th>
              <th>Account Number</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableRowsData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.planName}</td>
                <td>{item.federalTaxId}</td>
                <td>{item.planContactName}</td>
                <td>{item.planPhone}</td>
                <td>{item.planEmail}</td>
                <td>{item.custodianPlanCode}</td>
                <td>{item.routingNumber}</td>
                <td>{item.accountNumber}</td>
                <td>{item.active ? 'Yes' : 'No'}</td>
                {/* <td><Badge color={item.status_resp}>{item.badge_resp}</Badge></td> */}
                <td>
                  <Link to={`/custodians/${custId}/plans/${item.id}/edit`}>
                    <Badge color="primary">View / Edit</Badge>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
    // </Col>
  );
};

CustodiansPlansTable.propTypes = {
  custId: PropTypes.number.isRequired,
};

export default CustodiansPlansTable;
