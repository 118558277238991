import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { cpStateReset } from '@redux/actions/cpActions';
import { getSingleWorkflow, updateWorkflow } from '@redux/actions/wfsActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowsForm from './components/WorkflowsForm';

const EditWorkflow = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const workflows = useSelector(state => state.wfs);
  const { showErrorToast, showSuccessToast } = useToast();
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const page = getQueryParam('from');
  const pageSize = getQueryParam('pageSize');

  const search = {
    searchInput: getQueryParam('searchInput'),
    wfType: getQueryParam('wfType'),
    statusInput: getQueryParam('statusInput'),
    fromDate: getQueryParam('fromDate'),
    toDate: getQueryParam('toDate'),
  };

  const sortWFNumber = getQueryParam('sortWFNumber');
  const sortWType = getQueryParam('sortWType');
  const sortWDate = getQueryParam('sortWDate');
  const sortReAccountNumber = getQueryParam('sortReAccountNumber');

  useEffect(() => {
    dispatch(cpStateReset());
    dispatch(reset());
    if (token) {
      dispatch(getSingleWorkflow(token, id));
    }
    setValid(true);
  }, [id]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    });

    const payloadValues = {
      ...values,
      notes: sanitizeValue(values.notes),
    };

    dispatch(updateWorkflow(token, id, payloadValues));
  };

  const data = workflows?.wf;

  const commonPath = useMemo(() => {
    const {
 fromDate, searchInput, statusInput, toDate, wfType,
} = search || {};
    return buildQueryParams({
      searchInput,
      wfType,
      statusInput,
      fromDate,
      toDate,
      sortWFNumber,
      sortWType,
      sortWDate,
      sortReAccountNumber,
      pageSize,
    });
  }, [
    search,
    sortWFNumber,
    sortWType,
    sortWDate,
    sortReAccountNumber,
    pageSize,
  ]);

  const goBackUrl = useMemo(
    () => `/workflows?page=${page}${commonPath}`,
    [page, commonPath],
  );

  const gotoWorkflowTypeIdUrl = useMemo(
    () => `/workflows/workflowdetail${data?.workflowTypeID}/${id}?from=${page}${commonPath}`,
    [data?.workflowTypeID, id, page, commonPath],
  );

  useEffect(() => {
    if (valid) {
      if (workflows?.err) {
        if (unauthorizedError(workflows)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        const errorMessage = workflows?.err || msgs.error;
        showErrorToast(errorMessage);
      } else if (workflows?.success) {
        showSuccessToast();
        history.push(goBackUrl);
      }
    }
  }, [goBackUrl, valid, workflows]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Workflow Detail</h3>

      {workflows?.requesting && (
        <p className="text-center text-primary mb-3">
          <span className="spinner-border spinner-border-sm" />
        </p>
      )}
      <WorkflowsForm
        id={id}
        data={data}
        goBackUrl={goBackUrl}
        gotoWorkflowTypeIdUrl={gotoWorkflowTypeIdUrl}
        onSubmit={handleForm}
      />
    </Container>
  );
};

export default EditWorkflow;
