import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  wft1008: {},
};

const wft1008Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WFT1008_REQUESTING:
    return {
      ...state, requesting: true, err: '', wft1008: '',
    };

  case ActionTypes.WFT1008_ERROR:
    return {
      ...state, requesting: false, err: payload, wft1008: '',
    };

  case ActionTypes.WFT1008_FETCHED:
    return {
      ...state, requesting: false, err: '', wft1008: payload,
    };

  default:
    return state;
  }
};

export default wft1008Reducer;
