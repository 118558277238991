import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { createCustodian } from '@redux/actions/custodiansActions';

import CustodiansForm from '../CustodiansForm/CustodiansForm';

const defaultData = {
  custodianName: '',
  custodianCode: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
};

const AddCustodian = () => {
  const dispatch = useDispatch();
  const custodians = useSelector(state => state.custodians);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const { token, logout } = useAuth();
  const [valid, setValid] = useState(false);

  const authError = () => {
    logout();
    showErrorToast(msgs.authError);
    history.push('/');
  };

  useEffect(() => {
    if (valid) {
      if (custodians?.err) {
        if (unauthorizedError(custodians)) {
          authError();
        } else {
          showErrorToast(custodians.err || msgs.error);
        }
      } else if (custodians?.success) {
        showSuccessToast();
        history.push('/custodians');
      }
    }
  }, [custodians, valid]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { custodianName, custodianCode } = values || {};

    const isValid = validateRequiredField({
      'Custodian Name': custodianName,
      'Custodian Code': custodianCode,
    });

    if (!isValid) {
      return;
    }

    dispatch(createCustodian(token, values));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Custodian Detail</h3>
      <CustodiansForm
        data={defaultData}
        onSubmit={handleForm}
        redirectUrl="/custodians"
      />
    </Container>
  );
};

export default AddCustodian;
