import React, { useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import { Input, Select, FormFooterActions } from '@atoms';
import { msgs } from '@config/conf';
import {
  filterOptions,
  formatCurrency,
  formatToPercentage,
  splitStringyCurrency,
  validateMaxValue,
  sanitizeValue,
  unauthorizedError,
  splitStringyRate,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getCarriers } from '@redux/actions/carriersActions';
import {
  createProduct,
  getSingleProduct,
  updateProduct,
} from '@redux/actions/productsActions';
import { getProductType } from '@redux/actions/productTypeActions';

const ProductsForm = (props) => {
  const {
 productForm, page, pageSize, sort,
} = props;
  const { id: paramsProductId } = useParams() || {};
  const { buildQueryParams } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const { token, logout } = useAuth();
  const dispatch = useDispatch();

  const carriers = useSelector(state => state.carriers);
  const productType = useSelector(state => state.productType);
  const products = useSelector(state => state.products);

  const isUnauthorized = !carriers?.requesting || !products?.requesting || !productType?.requesting;
  const { control, handleSubmit, reset } = productForm || {};

  const { sortProductName, sortCarrierName } = sort || {};
  const { fieldError } = msgs || {};

  const redirectUrl = useMemo(() => {
    const params = buildQueryParams({
      sortProductName,
      sortCarrierName,
      pageSize,
    });
    return `/products?page=${page}${params}`;
  }, [page, pageSize, sortCarrierName, sortProductName]);

  const productTypeOptions = filterOptions(
    productType?.productType,
    'productTypeID',
    'productTypeName',
  );
  const carriersOptions = filterOptions(
    carriers?.carriers?.data,
    'carrierID',
    'carrierName',
  );

  useEffect(() => {
    if (token) {
      dispatch(getCarriers(token));
      dispatch(getProductType(token));
      if (paramsProductId) {
        dispatch(getSingleProduct(paramsProductId, token));

        const { product } = products || {};
        if (product) {
          const annuityFees = product?.annuityFees * 100;
          const annualBonus = product?.annualBonus * 100;
          const incomeAcceleratedCredit = product?.incomeAcceleratedCredit * 100;
          return reset({
            ...products?.product,
            annuityFees: formatToPercentage(annuityFees),
            annualBonus: formatToPercentage(annualBonus),
            incomeAcceleratedCredit: formatToPercentage(
              incomeAcceleratedCredit,
            ),
            minInitialPurchase:
              product?.minInitialPurchase === 0
                ? ''
                : formatCurrency(product?.minInitialPurchase, 0),
          });
        }
      }
    }
  }, [paramsProductId, products?.product?.productID]);

  const onSavePayload = (formValues) => {
    let payloadValues;
    const {
      productTypeID,
      minInitialPurchase,
      minPolicyIssueAge,
      maxPolicyIssueAge,
      annuityFees,
      annualBonus,
      incomeAcceleratedCredit,
      productNotes,
      bankName,
      routingNumber,
      accountNumber,
    } = formValues || {};

    const commonValues = {
      ...formValues,
      annuityFees: splitStringyRate(annuityFees),
      annualBonus: splitStringyRate(annualBonus),
      incomeAcceleratedCredit: splitStringyRate(incomeAcceleratedCredit),
      minInitialPurchase: Number(splitStringyCurrency(minInitialPurchase)),
      minPolicyIssueAge: Number(splitStringyCurrency(minPolicyIssueAge)),
      maxPolicyIssueAge: Number(splitStringyCurrency(maxPolicyIssueAge)),
      notes: sanitizeValue(productNotes),
    };

    if (paramsProductId) {
      const { productBankName, productRoutingNumber, productAccountNumber } = formValues || {};
      payloadValues = {
        ...commonValues,
        bankName: sanitizeValue(productBankName),
        routingNumber: sanitizeValue(productRoutingNumber),
        accountNumber: sanitizeValue(productAccountNumber),
      };
    } else {
      payloadValues = {
        ...commonValues,
        bankName: sanitizeValue(bankName),
        routingNumber: sanitizeValue(routingNumber),
        accountNumber: sanitizeValue(accountNumber),
        procductTypeID: productTypeID,
      };
    }
    paramsProductId
      ? dispatch(updateProduct(paramsProductId, payloadValues, token))
      : dispatch(createProduct(token, payloadValues));
  };

  if (
    isUnauthorized
    && (unauthorizedError(carriers)
      || unauthorizedError(productType)
      || unauthorizedError(products))
  ) {
    logout();
    enqueueSnackbar(msgs.authError, {
      variant: 'error',
      autoHideDuration: 5000,
    });
    return <Redirect to="/" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="form form--horizontal product_page_wrapper">
          <Select
            name="carrierID"
            control={control}
            label="Carrier"
            placeholder="Select Carrier"
            rules={{ required: fieldError }}
            required
            options={carriersOptions}
            isLoading={carriers?.requesting}
            errorMessage={carriers?.err}
          />
          <Input
            name="productName"
            control={control}
            label="Product Name"
            rules={{ required: fieldError }}
            maxLength={200}
            required
          />
          <Select
            name="productTypeID"
            control={control}
            label="Product Type"
            placeholder="Select Product Type"
            rules={{ required: fieldError }}
            required
            options={productTypeOptions}
            isLoading={productType?.requesting}
            errorMessage={productType?.err}
          />
          <Input
            name="carrierProductCusip"
            control={control}
            label="Product Cusip"
            rules={{ required: fieldError }}
            maxLength={20}
            required
          />
          <Input
            name="carrierProductCode"
            control={control}
            label="Product Code"
            rules={{ required: fieldError }}
            maxLength={100}
            required
          />
          <Input
            name="productIdentifier"
            control={control}
            label="Product Identifier"
            rules={{ required: fieldError }}
            maxLength={100}
            required
          />
          <Input
            name="minInitialPurchase"
            control={control}
            label="Minimum Initial Purchase"
            rules={{ required: fieldError }}
            formatter={{ numericOnly: true, shouldAmount: true }}
            maxLength={20}
            required
          />
          <Input
            name="minPolicyIssueAge"
            control={control}
            label="Minimum Policy Age"
            rules={{ required: fieldError }}
            formatter={{ numericOnly: true }}
            maxLength={5}
            required
          />
          <Input
            name="maxPolicyIssueAge"
            control={control}
            label="Maximum Policy Age"
            rules={{ required: fieldError }}
            formatter={{ numericOnly: true }}
            maxLength={5}
            required
          />
          <Input
            name="annuityFees"
            control={control}
            label="Product Fee"
            rules={{
              required: fieldError,
              validate: value => validateMaxValue(value),
            }}
            formatter={{ numericOnly: true, shouldPercentage: true }}
            maxLength={10}
            required
          />
          <Input
            name="annualBonus"
            control={control}
            label="Annual Bonus"
            rules={{
              required: fieldError,
              validate: value => validateMaxValue(value),
            }}
            formatter={{ numericOnly: true, shouldPercentage: true }}
            maxLength={10}
            required
          />
          <Input
            name="incomeAcceleratedCredit"
            control={control}
            label="Income Accelerated Credit"
            rules={{
              required: fieldError,
              validate: value => validateMaxValue(value, 1000),
            }}
            formatter={{ numericOnly: true, shouldPercentage: true }}
            maxLength={10}
            required
          />
          <Input
            name="productNotes"
            control={control}
            label="Notes"
            type="textarea"
            maxLength={5000}
          />
          <FormFooterActions
            linkProps={[{ path: redirectUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSavePayload) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductsForm;
