import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { getSingleWft10061007 } from '@redux/actions/wft10061007Actions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const additionalFormFields = [
  { label: 'Activity Date', name: 'activityDate' },
  { label: 'Activity Amount', name: 'activityAmount' },
];

const WorkflowTypeID10061007 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, logout } = useAuth();
  const workflowId10061007 = useSelector(state => state.wft10061007);
  const { showErrorToast, showSuccessToast } = useToast();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getSingleWft10061007(token, workflowId));
    }
    setValid(true);
    dispatch(reset());
  }, [workflowId]);

  const data = workflowId10061007?.wft10061007?.data || null;

  useEffect(() => {
    if (valid) {
      if (workflowId10061007?.err) {
        if (unauthorizedError(workflowId10061007)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(workflowId10061007.err || msgs.error);
      } else if (workflowId10061007?.success) {
        showSuccessToast();
        history.push('/workflows');
      }
    }
  }, [valid, workflowId10061007]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Premium Purchase</h3>

      <FormSpinner requesting={workflowId10061007?.requesting} />
      <WorkflowTypeIdForm
        id={workflowId}
        data={data}
        additionalFormFields={additionalFormFields}
      />
    </Container>
  );
};

export default WorkflowTypeID10061007;
