import React, { useState } from 'react';

import { useHistory } from 'react-router';
import { Form, Input, Label } from 'reactstrap';

const PlansTableSearch = ({ filter, setFilter, setSubmitSearch }) => {
  const history = useHistory();

  const handleClear = (e) => {
    e.preventDefault();
    // setFilterType("planName");
    setSubmitSearch(true);
    // history.push("/plans");
    setFilter('');
  };

  return (
    <div className="table__search mb-0">
      <Form
        className="w-100"
        inline
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          // alert(JSON.stringify({ filter }));
          setSubmitSearch(true);
        }}
      >
        {/* <Input
          type="select"
          className="table__search table__search-input mb-0"
          placeholder="Filter By"
          value={filterType}
          onChange={(e) => {
            setFilterType(e.target.value);
          }}
        >
          <option value="planName">Plan Name</option>
          <option value="federalTaxID">Tax ID</option>
        </Input> */}
        <Input
          type="text"
          className="table__search table__search-input mb-0"
          placeholder="Plan Name or Tax ID"
          maxLength="100"
          style={{ maxWidth: '200px', width: '100%' }}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        <Input
          type="submit"
          value="Filter"
          className="btn-blue btn-w text-light table__search table__search-input mb-0"
        />
        {filter && (
          <Input
            type="reset"
            value="Clear"
            onClick={handleClear}
            className="btn-w text-light table__search table__search-input mb-0"
          />
        )}
      </Form>
    </div>
  );
};

export default PlansTableSearch;
