export const FORM_FIELDS = 'FORM_FIELDS';
export const FORM_RESET = 'FORM_RESET';

export const CP_REQUESTING = 'CP_REQUESTING';
export const CP_ERROR = 'CP_ERROR';
export const CP_SUCCESS = 'CP_SUCCESS';

export const EPS_REQUESTING = 'EPS_REQUESTING';
export const EPS_ERROR = 'EPS_ERROR';
export const EPS_FETCHED = 'EPS_FETCHED';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';
export const LOGIN_LOGOUT_ERROR = 'LOGIN_LOGOUT_ERROR';

export const LOGIN_MC_USER_REQUESTING = 'LOGIN_MC_USER_REQUESTING';
export const LOGIN_MC_USER_ERROR = 'LOGIN_MC_USER_ERROR';
export const LOGIN_MC_USER_SUCCESS = 'LOGIN_MC_USER_SUCCESS';
export const LOGOUT_MC_USER_SUCCESS = 'LOGOUT_MC_USER_SUCCESS';
export const LOGIN_MC_USER_LOGOUT = 'LOGIN_MC_USER_LOGOUT';

export const ANNUITY_REQUESTING = 'ANNUITY_REQUESTING';
export const ANNUITY_ERROR = 'ANNUITY_ERROR';
export const ANNUITY_FETCHED = 'ANNUITY_FETCHED';
export const ANNUITY_ALLOCATIONS_UPDATED = 'ANNUITY_ALLOCATIONS_UPDATED';

export const ANNUITY_REQUESTING_DOCUMENTS = 'ANNUITY_REQUESTING_DOCUMENTS';
export const ANNUITY_ERROR_DOCUMENTS = 'ANNUITY_ERROR_DOCUMENTS';
export const ANNUITY_FETCHED_DOCUMENTS = 'ANNUITY_FETCHED_DOCUMENTS';

export const RKS_REQUESTING = 'RKS_REQUESTING';
export const RKS_ERROR = 'RKS_ERROR';
export const RKS_FETCHED = 'RKS_FETCHED';
export const RK_FETCHED = 'RK_FETCHED';
export const RK_ADDED = 'RK_ADDED';
export const RK_UPDATED = 'RK_UPDATED';

export const COUNTRIES_REQUESTING = 'COUNTRIES_REQUESTING';
export const COUNTRIES_ERROR = 'COUNTRIES_ERROR';
export const COUNTRIES_FETCHED = 'COUNTRIES_FETCHED';

export const PLANS_REQUESTING = 'PLANS_REQUESTING';
export const PLANS_ERROR = 'PLANS_ERROR';
export const PLANS_FETCHED = 'PLANS_FETCHED';
export const PLAN_FETCHED = 'PLAN_FETCHED';
export const PLAN_ADDED = 'PLAN_ADDED';
export const PLAN_UPDATED = 'PLAN_UPDATED';

export const PPS_REQUESTING = 'PPS_REQUESTING';
export const PPS_ERROR = 'PPS_ERROR';
export const PP_FETCHED = 'PP_FETCHED';
export const PP_ADDED = 'PP_ADDED';
export const PP_UPDATED = 'PP_UPDATED';

export const PPPIOS_REQUESTING = 'PPPIOS_REQUESTING';
export const PPPIOS_ERROR = 'PPPIOS_ERROR';
export const PPPIOS_FETCHED = 'PPPIOS_FETCHED';
export const PPPIOS_UPDATED = 'PPPIOS_UPDATED';

export const PPFACTOR_REQUESTING = 'PPFACTOR_REQUESTING';
export const PPFACTOR_ERROR = 'PPFACTOR_ERROR';
export const PPFACTOR_FETCHED = 'PPFACTOR_FETCHED';
export const PPFACTOR_UPDATED = 'PPFACTOR_UPDATED';

export const CARRIERS_REQUESTING = 'CARRIERS_REQUESTING';
export const CARRIERS_ERROR = 'CARRIERS_ERROR';
export const CARRIERS_FETCHED = 'CARRIERS_FETCHED';
export const CARRIER_FETCHED = 'CARRIER_FETCHED';
export const CARRIER_ADDED = 'CARRIER_ADDED';
export const CARRIER_UPDATED = 'CARRIER_UPDATED';

export const PRODUCTS_REQUESTING = 'PRODUCTS_REQUESTING';
export const PRODUCTS_ERROR = 'PRODUCTS_ERROR';
export const PRODUCTS_FETCHED = 'PRODUCTS_FETCHED';
export const PRODUCT_FETCHED = 'PRODUCT_FETCHED';
export const PRODUCT_ADDED = 'PRODUCT_ADDED';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';

export const PRODUCT_TYPE_REQUESTING = 'PRODUCT_TYPE_REQUESTING';
export const PRODUCT_TYPE_ERROR = 'PRODUCT_TYPE_ERROR';
export const PRODUCT_TYPE_FETCHED = 'PRODUCT_TYPE_FETCHED';

export const PIO_REQUESTING = 'PIO_REQUESTING';
export const PIO_ERROR = 'PIO_ERROR';
export const PIO_FETCHED = 'PIO_FETCHED';
export const PIO_ADDED = 'PIO_ADDED';
export const PIO_UPDATED = 'PIO_UPDATED';

export const PRODUCT_FACTOR_REQUESTING = 'PRODUCT_FACTOR_REQUESTING';
export const PRODUCT_FACTOR_ERROR = 'PRODUCT_FACTOR_ERROR';
export const PRODUCT_FACTOR_FETCHED = 'PRODUCT_FACTOR_FETCHED';
export const PRODUCT_FACTOR_ADDED = 'PRODUCT_FACTOR_ADDED';
export const PRODUCT_FACTOR_UPDATED = 'PRODUCT_FACTOR_UPDATED';

export const CUSTODIANS_REQUESTING = 'CUSTODIANS_REQUESTING';
export const CUSTODIANS_ERROR = 'CUSToDIANS_ERROR';
export const CUSTODIANS_FETCHED = 'CUSTODIANS_FETCHED';
export const CUSTODIAN_FETCHED = 'CUSTODIAN_FETCHED';
export const CUSTODIAN_ADDED = 'CUSTODIAN_ADDED';
export const CUSTODIAN_UPDATED = 'CUSTODIAN_UPDATED';

export const CUSTODIAN_PLANS_LOADING = 'CUSTODIAN_PLANS_LOADING';
export const CUSTODIAN_PLANS_ERROR = 'CUSTODIAN_PLANS_ERROR';
export const CUSTODIAN_PLANS_LOADED = 'CUSTODIAN_PLANS_LOADED';

export const USERS_REQUESTING = 'USERS_REQUESTING';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_FETCHED = 'USERS_FETCHED';
export const USER_FETCHED = 'USER_FETCHED';
export const USER_ADDED = 'USER_ADDED';
export const USER_UPDATED = 'USER_UPDATED';

export const CONFIG_REQUESTING = 'CONFIG_REQEUSTING';
export const CONFIG_ERROR = 'CONFIG_ERROR';
export const CONFIG_FORM_ERROR = 'CONFIG_FORM_ERROR';
export const CONFIG_FETCHED = 'CONFIG_FETCHED';
export const CONFIG_ADDED = 'CONFIG_ADDED';
export const CONFIG_UPDATED = 'CONFIG_UPDATED';

export const WFTS_REQUESTING = 'WFTS_REQUESTING';
export const WFTS_ERROR = 'WFTS_ERROR';
export const WFTS_FETCHED = 'WFTS_FETCHED';

export const WFS_REQUESTING = 'WFS_REQUESTING';
export const WFS_ERROR = 'WFS_ERROR';
export const WFS_FETCHED = 'WFS_FETCHED';
export const WF_FETCHED = 'WF_FETCHED';
export const WF_ADDED = 'WF_ADDED';
export const WF_UPDATED = 'WF_UPDATED';
export const WF_RESUBMITTED = 'WF_RESUBMITTED';

export const WFT1001_REQUESTING = 'WFT1001_REQUESTING';
export const WFT1001_ERROR = 'WFT1001_ERROR';
export const WFT1001_FETCHED = 'WFT1001_FETCHED';

export const WFT1002_REQUESTING = 'WFT1002_REQUESTING';
export const WFT1002_ERROR = 'WFT1002_ERROR';
export const WFT1002_FETCHED = 'WFT1002_FETCHED';

export const WFT1003_REQUESTING = 'WFT1003_REQUESTING';
export const WFT1003_ERROR = 'WFT1003_ERROR';
export const WFT1003_FETCHED = 'WFT1003_FETCHED';

export const WFT1004_REQUESTING = 'WFT1004_REQUESTING';
export const WFT1004_ERROR = 'WFT1004_ERROR';
export const WFT1004_FETCHED = 'WFT1004_FETCHED';

export const WFT10061007_REQUESTING = 'WFT10061007_REQUESTING';
export const WFT10061007_ERROR = 'WFT10061007_ERROR';
export const WFT10061007_FETCHED = 'WFT10061007_FETCHED';

export const WFT1008_REQUESTING = 'WFT1008_REQUESTING';
export const WFT1008_ERROR = 'WFT1008_ERROR';
export const WFT1008_FETCHED = 'WFT1008_FETCHED';

export const REC_REQUESTING = 'REC_REQUESTING';
export const REC_ERROR = 'REC_ERROR';
export const REC_FETCHED = 'REC_FETCHED';
export const REC_UPDATED = 'REC_UPDATED';

export const ACCOUNTS_REQUESTING = 'ACCOUNTS_REQUESTING';
export const ACCOUNTS_ERROR = 'ACCOUNTS_ERROR';
export const ACCOUNTS_FETCHED = 'ACCOUNTS_FETCHED';
export const ACCOUNT_FETCHED = 'ACCOUNT_FETCHED';
export const ACCOUNT_ADDED = 'ACCOUNT_ADDED';
export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED';

export const ACCOUNT_SUBACCOUNTS_REQUESTING = 'ACCOUNT_SUBACCOUNTS_REQUESTING';
export const ACCOUNT_SUBACCOUNTS_ERROR = 'ACCOUNT_SUBACCOUNTS_ERROR';
export const ACCOUNT_SUBACCOUNTS_FETCHED = 'ACCOUNT_SUBACCOUNT_FETCHED';

export const ACCOUNT_ADDRESSES_REQUESTING = 'ACCOUNT_ADDRESSES_REQUESTING';
export const ACCOUNT_ADDRESSES_ERROR = 'ACCOUNT_ADDRESSES_ERROR';
export const ACCOUNT_ADDRESSES_FETCHED = 'ACCOUNT_ADDRESSES_FETCHED';

export const ACCOUNT_PIOAS_REQUESTING = 'ACCOUNT_PIOAS_REQUESTING';
export const ACCOUNT_PIOAS_ERROR = 'ACCOUNT_PIOAS_ERROR';
export const ACCOUNT_PIOAS_FETCHED = 'ACCOUNT_PIOAS_FETCHED';

export const ACCOUNT_VAULT_REQUESTING = 'ACCOUNT_VAULT_REQUESTING';
export const ACCOUNT_VAULT_ERROR = 'ACCOUNT_VAULT_ERROR';
export const ACCOUNT_VAULT_FETCHED = 'ACCOUNT_VAULT_FETCHED';

export const PAWS_REQUESTING = 'PAWS_REQUESTING';
export const PAWS_ERROR = 'PAWS_ERROR';
export const PAWS_FETCHED = 'PAWS_FETCHED';
export const PAW_FETCHED = 'PAW_FETCHED';

export const CLS_REQUESTING = 'CLS_REQUESTING';
export const CLS_ERROR = 'CLS_ERROR';
export const CLS_FETCHED = 'CLS_FETCHED';
export const CL_FETCHED = 'CL_FETCHED';
export const CL_UPDATED = 'CL_UPDATED';

export const WRS_REQUESTING = 'WRS_REQUESTING';
export const WRS_ERROR = 'WRS_ERROR';
export const WRS_FETCHED = 'WRS_FETCHED';
export const WRS_SAVED = 'WRS_SAVED';

export const DAILY_SETTLEMENT_SUMMARY_REQUESTING = 'DAILY_SETTLEMENT_SUMMARY_REQUESTING';
export const DAILY_SETTLEMENT_SUMMARY_ERROR = 'DAILY_SETTLEMENT_SUMMARY_ERROR';
export const DAILY_SETTLEMENT_SUMMARY_FETCHED = 'DAILY_SETTLEMENT_SUMMARY_FETCHED';

export const ACCOUNT_PIP_RATES_REQUESTING = 'ACCOUNT_PIP_RATES_REQUESTING';
export const ACCOUNT_PIP_RATES_ERROR = 'ACCOUNT_PIP_RATES_ERROR';
export const ACCOUNT_PIP_RATES_FETCHED = 'ACCOUNT_PIP_RATES_FETCHED';
