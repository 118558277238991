import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { formatDate, getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getAccountAddresses } from '@redux/actions/accountAddressesActions';

const addressColumns = [
  {
    id: 'addressType',
    header: 'Type',
    accessorKey: 'addressType',
    enableSorting: false,
  },
  {
    id: 'address1',
    header: 'Address 1',
    accessorKey: 'address1',
    enableSorting: false,
    size: '230px',
  },
  {
    id: 'address2',
    header: 'Address 2',
    accessorKey: 'address2',
    enableSorting: false,
  },
  {
    id: 'city/state/zip',
    header: 'City/State/Zip',
    cell: (tableRow) => {
      const { city = '', stateCode = '', zip = '' } = tableRow?.row?.original || {};
      const cityStateValue = `${city}, ${stateCode} ${zip}`;
      return cityStateValue;
    },
  },
  {
    id: 'country',
    header: 'Country',
    accessorKey: 'country',
    enableSorting: false,
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    enableSorting: false,
  },
  {
    id: 'lastUpdated',
    header: 'Last Updated',
    cell: (tableRow) => {
      const { lastUpdated } = tableRow?.row?.original || {};
      return lastUpdated ? formatDate(lastUpdated) : '';
    },
  },
];

const AddressTable = ({ id }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { getQueryParam } = useQueryParams();
  const accountAddresses = useSelector(state => state.accountAddresses);

  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const pageQuery = getQueryParam('addressesPage');

  const { offset, pageNumber } = getPagination(pageSize, pageQuery) || {};

  const { data: tableData, recordCount } = accountAddresses?.accountAddresses || {};

  useEffect(() => {
    if (token) {
      dispatch(getAccountAddresses(token, id, offset, pageSize));
    }
  }, [offset, pageSize, token, id]);

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
    fromPage: pageNumber,
    pageName: 'addressesPage',
  };

  return (
    <Spinner requesting={accountAddresses?.requesting}>
      <Table
        columns={addressColumns}
        data={tableData}
        pageProps={pageProps}
        status={accountAddresses?.err}
        noPadding
        noBottomBorder
      />
    </Spinner>
  );
};

export default AddressTable;
