import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import {
  getSingleRecordKeeper,
  updateRecordKeeper,
} from '@redux/actions/rksActions';

import RecordKeepersForm from '../RecordKeepersForm/RecordKeepersForm';

const EditRecordKeepers = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const dispatch = useDispatch();
  const recordKeepers = useSelector(state => state.rks);
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const { token, logout } = useAuth();
  const [valid, setValid] = useState(false);

  const page = getQueryParam('from');

  const sort = {
    sortRecordkeeperName: getQueryParam('sortRecordkeeperName') || '',
    sortRKChannel: getQueryParam('sortRKChannel') || '',
    sortStatus: getQueryParam('sortStatus') || '',
  };

  const pageSize = getQueryParam('pageSize') || '';

  useEffect(() => {
    if (token) {
      dispatch(getSingleRecordKeeper(token, id));
    }
    setValid(true);
  }, [id]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { recordkeeperName, extRecordkeeperIdentifier } = values || {};

    const isValid = validateRequiredField({
      'RecordKeeper Name': recordkeeperName,
      'External RecordKeeper Identifier': extRecordkeeperIdentifier,
    });

    if (!isValid) {
      return;
    }

    dispatch(updateRecordKeeper(token, values, id));
  };

  const data = recordKeepers?.rk?.data || {};

  const redirectUrl = useMemo(() => {
    const { sortRKChannel, sortStatus, sortRecordkeeperName } = sort || {};

    const queryParams = buildQueryParams({
      sortRecordkeeperName,
      sortRKChannel,
      sortStatus,
      pageSize,
    });

    return `/recordkeepers?page=${page}${queryParams}`;
  }, [page, sort, pageSize]);

  useEffect(() => {
    if (valid) {
      if (recordKeepers?.err) {
        if (unauthorizedError(recordKeepers)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(recordKeepers.err || msgs.error);
      } else if (recordKeepers?.success) {
        showSuccessToast();
        history.push(redirectUrl);
      }
    }
  }, [recordKeepers, valid, history]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Recordkeeper Detail</h3>

      <FormSpinner requesting={recordKeepers?.requesting} />
      <RecordKeepersForm
        data={data}
        redirectUrl={redirectUrl}
        onSubmit={handleForm}
      />
    </Container>
  );
};

export default EditRecordKeepers;
