import {
 createContext, useContext, useEffect, useState,
} from 'react';

import { Button } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router';

import { logoffAnnuitantToken } from '@redux/actions/annuityActions';
import { logout as logoutUser } from '@redux/actions/loginActions';
import { reset } from '@redux/reducers/formFieldsReducer';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [payload, setPayload] = useState('');
  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  const annuityToken = sessionStorage.getItem('annuityAuthToken') || null;

  // const tm = new Date(payload?.exp);

  // console.log("Context Date", tm);
  // console.log("Context Time", tm.getTime());

  // console.log("Context Current Date", new Date());
  // console.log("Context Current Time", new Date().getTime());

  const getToken = async () => {
    setToken(
      (await localStorage.getItem('authToken'))
        ? await localStorage.getItem('authToken')
        : null,
    );

    setPayload(
      (await localStorage.getItem('authToken'))
        ? jwt_decode(await localStorage.getItem('authToken'))
        : token
          ? jwt_decode(token)
          : null,
    );
    localStorage.getItem('authToken') ? '' : <Redirect to="/" />;
  };

  useEffect(() => {
    getToken();
  }, []);

  const logout = (msg = '') => {
    dispatch(logoutUser());
    localStorage.removeItem('authToken');
    dispatch(reset());
    setPayload('');
    setToken('');
  };

  const annuityLogout = () => {
    const credentials = {
      token: annuityToken,
      loginName: 'annuitysystemuser',
      userType: 'Annuitant',
    };
    dispatch(logoffAnnuitantToken(credentials));
    dispatch(reset());
    sessionStorage.removeItem('annuityAuthToken');
    sessionStorage.removeItem('activeTab');
  };

  const value = {
    user: payload || null,
    setPayload,
    token,
    setToken,
    logout,
    annuityToken,
    annuityLogout,
  };
  // const value  = payload ? payload : null
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
