import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getAnnuity } from '@redux/actions/annuityActions';

import AnnuityTabs from './AnnuityTabs';
import Navbar from './Navbar';

const ViewAccountForm = ({
  token, id, invalidateAnnuitantToken, activeTab, data,
}) => {
  window.onbeforeunload = null;

  const annuityRes = useSelector(state => state.annuity);
  const dispatch = useDispatch();

  const [tabShown, setTabShown] = useState(activeTab);

  const tabClick = (tab) => {
    setTabShown(tab);
  };

  sessionStorage.setItem('userFirstName', data?.[0]?.firstName);

  let total = 0;

  data?.forEach((element) => {
    total += element.policyAccumulationValue;
  });

  return (
    <>
      <Navbar dispatch={dispatch} userFirstName={data?.[0]?.firstName} token={token} invalidateAnnuitantToken={invalidateAnnuitantToken} />
      <AnnuityTabs style={{ marginTop: '50px' }} tabShown={tabShown} data={data} tabClick={tabClick} />
    </>
  );
};

export default ViewAccountForm;
