import React, { useEffect } from 'react';

import { useHistory } from 'react-router';

const REConfig = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/config');
  }, [history]);

  return (
    <div>
      <p className="text-center text-primary mb-3">
        <span className="spinner-border spinner-border-sm" />
      </p>
    </div>
  );
};

export default REConfig;
