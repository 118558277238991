// import PropTypes from 'prop-types';
// import { Button } from 'reactstrap';
// import FirebaseIcon from 'mdi-react/FirebaseIcon';
import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useAuth } from '@contexts/AuthContext';
import { reset } from '@redux/reducers/formFieldsReducer';

import LogInForm from './LogInForm';
// import GoogleAuthBtn from '../AuthBtn/googleAuthBtn';
// import FacebookAuthBtn from '../AuthBtn/fbAuthBtn';

// const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LogIn = () => {
  const { logout } = useAuth();

  if (window.location.pathname === ('/Profile').toLowerCase()) {
    window.location.reload();
  }

  // console.log('====================================');
  // console.log('Login check outside useeffect.');
  // console.log('====================================');

  useEffect(() => {
    // dispatch(reset());
    // console.log('====================================');
    // console.log('Login Check');
    // console.log('====================================');
    localStorage.removeItem('authToken');
    logout();
  }, []);

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              Welcome to
              <span className="account__logo">
                <span className="account__logo-accent"> Retirement Edge</span>
              </span>
            </h3>
            {/* <h4 className="account__subhead subhead">Start your business easily</h4> */}
          </div>
          <LogInForm />
          {/* <div className="account__or">
            <p>Or Easily Using</p>
          </div>
          <div className="account__social">
            <FacebookAuthBtn />
            <GoogleAuthBtn />
            <Button
              className="account__social-btn account__social-btn--firebase"
              onClick={changeIsOpenModalFireBase}
            >
              <FirebaseIcon />
            </Button>
            <Button className="account__social-btn account__social-btn--auth0" onClick={() => loginWithRedirect({})}>
              <img className="customizer__btn-icon" src={auth0Icon} alt="icon" />
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

// LogIn.propTypes = {
//   changeIsOpenModalFireBase: PropTypes.func.isRequired,
// };

export default LogIn;
