import { Container } from 'reactstrap';

import CarrierProductsForm from '../CarrierProductsForm/CarrierProductsForm';

const data = {
  productType: '',
  productName: '',
  extProductCode: '',
  minInitialPurchase: '',
  active: true,
  type: 'add',
};

const AddCarrierProduct = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Add Product</h3>

    <CarrierProductsForm data={data} />
  </Container>
);

export default AddCarrierProduct;
