import React, { useState, useRef } from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const UpdateAnnuityAllocations = ({ handleUpdate, combinedPios }) => {
  const [totalPercent, setTotalPercent] = useState(0);
  const [productInvestmentOptionAllocations, setProductInvestmentOptionAllocations] = useState(combinedPios);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 650,
    },
  }));

  const windowLocationPath = sessionStorage.getItem('window.location.pathname');

  // alert(windowLocationPath); ///View/GenericUserAccount/Summary/1401092283
  // let navigateBackUrl = `/View/Account/Summary/${sessionStorage.getItem("reAccountNumber")}?token=${sessionStorage.getItem("annuityAuthToken")}`;

  // if (windowLocationPath.includes("GenericUserAccount")){}
  let navigateBackUrl = `${windowLocationPath}?token=${sessionStorage.getItem('annuityAuthToken')}`;

  if (windowLocationPath.includes('GenericUser')) {
    navigateBackUrl = `${windowLocationPath}`;
  }

  const inputRef = useRef([]);

  const applyNewRateRate = (e, i) => {
    setTotalPercent(0);
    const cpy = [...productInvestmentOptionAllocations];
    cpy[i].updatedRate = Number(e.target.value);
    setProductInvestmentOptionAllocations(cpy);

    let total = 0;
    cpy.forEach(i => total += i.updatedRate);
    setTotalPercent(parseInt(total));
    inputRef.current[i].focus();

    console.log(total);
  };

  const saveAllocations = () => {
    handleUpdate(productInvestmentOptionAllocations);
  };

  const classes = useStyles();

  const FormFooterRow = () => (
    <React.Fragment>

      <Grid container spacing={2}>
        <Grid item xs={10} />
        <Grid item xs={1}>

          <Paper className={classes.paper} style={{ textAlign: 'right', height: '40px' }}><Typography variant="caption">Total</Typography></Paper>

        </Grid>
        <Grid item xs={1}>

          <Paper className={classes.paper} style={{ textAlign: 'center', height: '40px', width: '100%' }}><Typography style={{ width: '100%' }} variant="button">{totalPercent}</Typography></Paper>

        </Grid>
      </Grid>
    </React.Fragment>
  );

  const FormAction = () => (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <Button href={navigateBackUrl} variant="contained" color="secondary" style={{ width: '100%' }}>Cancel</Button>
        </Grid>
        <Grid item xs={2}>
          <Button disabled={Number(totalPercent) !== 100} variant="contained" onClick={() => { saveAllocations(); }} style={{ backgroundColor: '#7199BB', color: 'white', width: '100%' }}>Save</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const FormHeading = () => (
    <React.Fragment>
      <Grid item xs={10}>
        <Paper className={classes.paper} style={{ backgroundColor: '#7199BB', color: 'white', textAlign: 'left' }}>
          <Typography variant="button">INDEX STRATEGY NAME</Typography>
        </Paper>
      </Grid>
      <Grid item xs={1}>
        <Paper className={classes.paper} style={{ backgroundColor: '#7199BB', color: 'white', textAlign: 'center' }}>
          <Typography variant="button">CURRENT</Typography>
        </Paper>
      </Grid>
      <Grid item xs={1}>
        <Paper className={classes.paper} style={{ backgroundColor: '#7199BB', color: 'white', textAlign: 'center' }}>
          <Typography variant="button">ASSIGN %</Typography>
        </Paper>

      </Grid>

    </React.Fragment>
  );

  return (

    <React.Fragment>
      <CssBaseline />

      <Container>

        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography style={{ marginTop: '20px' }} component="div" gutterBottom>
              You may update your <a target="_blank" rel="noreferrer" href="https://www.allianzlife.com/-/media/Files/Global/documents/2022/03/16/14/18/LIA-002-R04.pdf"> crediting options </a> in your annuity at any time.  Your instructions will take effect on your next contract anniversary.  Note that if you own more than one contract, each contract will be updated with this allocation.
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <span className="row">

              <a target="_blank" rel="noreferrer" href="https://www.allianzlife.com/-/media/Files/Global/documents/2022/03/16/14/18/LIA-002-R04.pdf"><img style={{ width: '95%', height: '75%', margin: '10px' }} src={process.env.PUBLIC_URL + '/img/AZLPIOOptionsLogo.png'} /></a>

            </span>
          </Grid>
        </Grid>

        <Typography variant="h6" component="div" gutterBottom>
          Update Crediting Options
        </Typography>

        <div className={classes.root}>
          <Grid container item spacing={2}>
            <FormHeading />
          </Grid>
          {productInvestmentOptionAllocations.map((row, i) => (

            <Grid container item spacing={2} key={row.pioName}>
              <Grid item xs={10}>
                <Paper className={classes.paper} style={{ textAlign: 'left', height: '40px' }}>
                  <Typography variant="caption"> {row.pioName}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={1}>
                <Paper className={classes.paper} style={{ textAlign: 'center', height: '40px' }}>{row.allocationRate} %</Paper>
              </Grid>
              <Grid item xs={1}>
                <Paper className={classes.paper} style={{ height: '40px' }}>
                  <input
                    style={{
                      width: '100%', border: '0px', outline: '0px', textAlign: 'right',
                    }}
                    max={100}
                    min={0}
                    key={i}
                    ref={el => inputRef.current[i] = el}
                    type="number"
                    value={row.updatedPercent}
                    onChange={e => applyNewRateRate(e, i)}
                  />
                </Paper>
              </Grid>

            </Grid>

          ))}

          <div>
                &nbsp;
          </div>

          <div style={{ marginTop: '10px' }}>
            <FormFooterRow />
          </div>
          <div style={{ marginTop: '20px' }}>
            <FormAction />
          </div>
        </div>

      </Container>

    </React.Fragment>

  );
};

export default UpdateAnnuityAllocations;
