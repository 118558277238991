import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';

import { Input, Table, FormFooterActions } from '@atoms';
import {
  displayNumberFormat,
  formatDateWithoutTime,
  formatFixedDigits,
  formatToPercentage,
} from '@containers/components/helpers';
import useQueryParams from '@hooks/useQueryParams';

const workflowFormFields = [
  { label: 'Workflow Number', name: 'workflowNumber' },
  { label: 'Workflow Type', name: 'workflowType' },
  { label: 'RE Account #', name: 'reAccountNumber' },
  { label: 'Policy #', name: 'policyNumber' },
  { label: 'Participant', name: 'participantName' },
  { label: 'SSN', name: 'ssNumber' },
];

const piosColumns = [
  {
    id: 'pioCode',
    header: 'PIO Code',
    accessorKey: 'carrierPIOCode',
    enableSorting: false,
    size: '50px',
  },
  {
    id: 'pioName',
    header: 'PIO Name',
    accessorKey: 'pioName',
    enableSorting: false,
    size: '250px',
  },
  {
    id: 'pioAllocation',
    header: 'Pio Allocation',
    enableSorting: false,
    size: '100px',
    cell: (tableRow) => {
      const { allocationRate } = tableRow?.row?.original || '';
      return (
        <div>{allocationRate ? formatToPercentage(allocationRate, 0) : ''}</div>
      );
    },
  },
];

let formFields = workflowFormFields;

const WorkflowTypeIdForm = ({
 id, data, additionalFormFields, hasTable,
}) => {
  const { control, reset } = useForm();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const page = getQueryParam('from');

  const search = useMemo(
    () => ({
      searchInput: getQueryParam('searchInput'),
      wfType: getQueryParam('wfType'),
      statusInput: getQueryParam('statusInput'),
      fromDate: getQueryParam('fromDate'),
      toDate: getQueryParam('toDate'),
    }),
    [],
  );

  const sort = useMemo(
    () => ({
      sortWFNumber: getQueryParam('sortWFNumber') || '',
      sortWType: getQueryParam('sortWType') || '',
      sortWDate: getQueryParam('sortWDate') || '',
      sortReAccountNumber: getQueryParam('sortReAccountNumber') || '',
    }),
    [],
  );

  const pageSize = getQueryParam('pageSize');

  useEffect(() => {
    const {
      workflowNumber,
      reAccountNumber,
      dob,
      activityDate,
      activityAmount,
      terminatePolicy,
      dateSettled,
      withdrawalAmount,
      withdrawalPercent,
      netAmount,
      feeAmount,
      mvaAmount,
    } = data || {};
    reset({
      ...data,
      workflowNumber: workflowNumber
        ? formatFixedDigits(12, workflowNumber)
        : '',
      reAccountNumber: reAccountNumber || '',
      dob: dob ? formatDateWithoutTime(dob, 'YYYY-MM-DD') : '',
      activityDate: activityDate ? formatDateWithoutTime(activityDate) : '',
      activityAmount: displayNumberFormat(activityAmount, 2) || '',
      terminatePolicy: terminatePolicy ? 'Yes' : 'No',
      dateSettled: dateSettled
        ? formatDateWithoutTime(dateSettled, 'YYYY-MM-DD')
        : '',
      withdrawalAmount: displayNumberFormat(withdrawalAmount, 2) || '',
      withdrawalPercent: displayNumberFormat(withdrawalPercent, 2) || '',
      netAmount: displayNumberFormat(netAmount, 2) || '',
      feeAmount: displayNumberFormat(feeAmount, 2) || '',
      mvaAmount: displayNumberFormat(mvaAmount, 2) || '',
    });
  }, [data, reset]);

  useEffect(() => {
    if (additionalFormFields?.length) {
      formFields = workflowFormFields.concat(additionalFormFields);
    }
  }, [additionalFormFields?.length]);

  const renderButton = useCallback(() => {
    const {
 searchInput, fromDate, statusInput, toDate, wfType,
} = search || {};
    const {
 sortReAccountNumber, sortWDate, sortWFNumber, sortWType,
} = sort || {};
    const queryParams = buildQueryParams({
      searchInput,
      wfType,
      statusInput,
      fromDate,
      toDate,
      sortWFNumber,
      sortWType,
      sortWDate,
      sortReAccountNumber,
      pageSize,
    });

    return (
      <FormFooterActions
        linkProps={[
          { path: `/workflows/${id}/edit?from=${page}${queryParams}` },
        ]}
      />
    );
  }, [id, page, pageSize, search, sort]);

  return (
    <div>
      <Card className="container_width">
        <CardBody>
          <div className="form form--horizontal form_spacing">
            {formFields?.map(({ label, name }) => (
              <Input
                control={control}
                key={name}
                name={name}
                label={label}
                type="text"
                isReadOnly
              />
            ))}
          </div>
          {!hasTable ? renderButton() : null}
        </CardBody>
      </Card>

      {hasTable ? (
        <Card>
          <CardBody>
            <Table
              data={data?.workflowsecond}
              columns={piosColumns}
              hidePagination
              noPadding
            />
            {renderButton()}
          </CardBody>
        </Card>
      ) : null}
    </div>
  );
};

export default WorkflowTypeIdForm;
