import React from 'react';

import { Link } from 'react-router-dom';

// import TopbarLanguage from './TopbarLanguage';
import TopbarProfile from './TopbarProfile';
import TopbarSidebarButton from './TopbarSidebarButton';
import ToggleTheme from '../customizer/ToggleTheme';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  user,
  changeToLight,
  changeToDark,
  theme,
}) => (
  <div className="topbar">
    <div className="topbar__left">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      {/* <Link className="topbar__logo" to="/dashboard" /> */}
      <Link to="/workflows" className="mt-3">
        <h3>Retirement Edge Admin</h3>
      </Link>
    </div>
    <div className="topbar__right">
      {/* <div className="topbar__right-search">
        <TopbarSearch />
      </div> */}
      <div className="topbar__right-over w-auto">
        {/* <TopbarNotification /> */}
        {/* <TopbarMail new /> */}
        <TopbarProfile user={user} />
        {/* <TopbarLanguage /> */}
        <ToggleTheme
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          theme={theme}
        />
      </div>
    </div>
  </div>
);

export default Topbar;
