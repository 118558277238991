import React, { useEffect, useMemo } from 'react';

import { useSnackbar } from 'notistack';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Input } from 'reactstrap';

import { msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import { formatDate } from '@lib/date';
import { getWfts } from '@redux/actions/wftsActions';

const WorkflowsTableSearch = ({
  searchInput,
  setSearchInput,
  wfType,
  setWfType,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setSubmitSearch,
  statusInput,
  setStatusInput,
}) => {
  const { token, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const workflowTypes = useSelector(state => state.wfts);
  const history = useHistory();

  const { err, requesting, wfts } = workflowTypes;
  const shouldReset = useMemo(
    () => searchInput || wfType || fromDate || toDate || statusInput,
    [searchInput, wfType, fromDate, toDate, statusInput],
  );

  useEffect(() => {
    if (unauthorizedError(workflowTypes)) {
      logout();
      enqueueSnackbar(msgs.authError, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      history.push('/');
    }
  }, [err]);

  useEffect(() => {
    if (token) {
      dispatch(getWfts(token));
    }
  }, []);

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setSearchInput('');
    setWfType('');
    setFromDate('');
    setToDate('');
    setStatusInput('');
  };

  return (
    <div className="table__search mb-0">
      <Form
        inline
        className="w-100"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitSearch(true);
        }}
      >
        <Input
          type="text"
          placeholder="WF#, RE Account Number or Policy Number"
          className="table__search table__search-input mb-0"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          style={{ maxWidth: '275px', width: '100%' }}
          maxLength="40"
        />
        <Input
          type="select"
          placeholder="Workflow Type"
          className="table__search table__search-input mb-0"
          value={wfType}
          onChange={e => setWfType(e.target.value)}
        >
          <option value="" />
          {requesting ? (
            <option value="">Loading Workflow Types...</option>
          ) : null}
          {err ? <option value="">{err}</option> : null}
          {wfts?.data?.map((item, index) => (
            <option value={item.workflowType} key={index}>
              {item.workflowType}
            </option>
          ))}
        </Input>
        <Input
          type="select"
          placeholder="Workflow Type"
          className="table__search table__search-input mb-0"
          value={statusInput}
          onChange={e => setStatusInput(e.target.value)}
        >
          <option value="" />
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Failed">Failed</option>
          <option value="Error">Error</option>
        </Input>
        <DatePicker
          className="form-control table__search table__search-input mb-0"
          selected={fromDate === '' ? '' : new Date(fromDate)}
          onChange={(date) => {
            setFromDate(formatDate(date));
          }}
          placeholderText="From Date (MM/DD/YYYY)"
        />
        <DatePicker
          className="form-control table__search table__search-input mb-0"
          selected={toDate === '' ? '' : new Date(toDate)}
          onChange={(date) => {
            setToDate(formatDate(date));
          }}
          placeholderText="To Date (MM/DD/YYYY)"
        />
        <Input
          type="submit"
          className="btn-blue btn-w text-light table__search table__search-input mb-0"
          value="Filter"
        />
        {shouldReset ? (
          <Input
            type="reset"
            className="btn-w text-light table__search table__search-input mb-0"
            value="Clear"
            onClick={handleClear}
          />
        ) : null}
      </Form>
    </div>
  );
};

export default WorkflowsTableSearch;
