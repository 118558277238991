import React, { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { Spinner, Table, TableDashboard } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getCarriers } from '@redux/actions/carriersActions';
import { cpStateReset } from '@redux/actions/cpActions';
import { reset } from '@redux/reducers/formFieldsReducer';

const Carriers = () => {
  const [refresh, setRefresh] = useState(false);

  const { token } = useAuth();
  const dispatch = useDispatch();
  const carriersData = useSelector(state => state.carriers);
  const { getQueryParam } = useQueryParams();

  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );

  const page = getQueryParam('page');
  const { carriers, err, requesting } = carriersData || {};
  const { data, recordCount } = carriers || {};
  const { offset, pageNumber } = getPagination(pageSize, page);

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getCarriers(token, pageSize, offset));
    }
    setRefresh(false);
  }, [offset, pageSize, refresh]);

  const column = useMemo(
    () => [
      {
        id: 'carrierName',
        header: 'Carrier Name',
        accessorKey: 'carrierName',
      },
      {
        id: 'contactName',
        header: 'Contact Name',
        accessorFn: row => row.contactName || '-',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: row => row.contactPhone || '-',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: row => row.contactEmail || '-',
      },
      {
        id: 'editActions',
        header: '',
        cell: (tableRow) => {
          const { carrierID } = tableRow.row.original || {};
          const navigateToCarrierDetails = `/carriers/${carrierID}/edit?from=${pageNumber}${
            pageSize ? `&pageSize=${pageSize}` : ''
          }`;
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToCarrierDetails}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [pageNumber, pageSize],
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <TableDashboard
      pageTitle="Carriers"
      setRefresh={setRefresh}
      navigatePath="/carriers/add"
    >
      <Spinner requesting={requesting}>
        <Table
          columns={column}
          data={data}
          pageProps={pageProps}
          errStatus={err}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default Carriers;
