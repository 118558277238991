import React, { useState } from 'react';

import { EyeIcon } from '@shared/icons';

import Input from './index';

const PasswordInput = ({ startIcon, InfoTooltipComponent, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordToggle = () => setShowPassword(!showPassword);

  return (
    <Input
      {...rest}
      type={showPassword ? 'text' : 'password'}
      startIcon={startIcon}
      InfoTooltipComponent={InfoTooltipComponent}
      endIcon={(
        <button
          type="button"
          className={`form__form-group-button${showPassword ? ' active' : ''}`}
          style={{ marginLeft: '-10px' }}
          onClick={showPasswordToggle}
        >
          <EyeIcon />
        </button>
      )}
    />
  );
};

export default PasswordInput;
