import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  accountPipRates: {},
};

const accountPipRatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.ACCOUNT_PIP_RATES_REQUESTING:
    return {
      ...state, requesting: true, err: '', accountPipRates: '',
    };

  case ActionTypes.ACCOUNT_PIP_RATES_ERROR:
    return {
      ...state,
      requesting: false,
      err: payload,
      accountPipRates: '',
    };

  case ActionTypes.ACCOUNT_PIP_RATES_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      accountPipRates: payload,
    };

  default:
    return state;
  }
};

export default accountPipRatesReducer;
