import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';

import { Input, Checkbox } from '@atoms';
import { msgs } from '@config/conf';

const RKChannelForm = ({ data }) => {
  const { control, reset, handleSubmit } = useForm();

  useEffect(() => {
    reset(data);
  }, [data]);

  const onSubmit = (values) => {
    alert(JSON.stringify(values));
  };

  const requiredProps = {
    control,
    required: true,
    rules: {
      required: msgs.fieldError,
    },
  };

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          <Input
            {...requiredProps}
            name="rkChannelName"
            label="RK Channel Name"
            placeholder="RK Channel Name"
          />
          <Checkbox
            control={control}
            name="acceptNewRecordKeepers"
            label="Accept New Record Keepers"
          />
          <Checkbox
            control={control}
            name="acceptNewPlans"
            label="Accept New Plans"
          />
          <Checkbox control={control} name="active" label="Active" />
          <div className="w-100 flex justify-content-end">
            <Link to="/rk_channels" className="btn btn-outline-danger">
              Cancel
            </Link>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              {data.type === 'add' ? 'Add Channel' : 'Update Channel'}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RKChannelForm;
