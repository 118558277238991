import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getPlanProductFactors = (token, planProductID) => async (dispatch) => {
  dispatch({ type: ActionTypes.PPFACTOR_REQUESTING });

  await axios
  .get(
    `${conf.serverURL}plans/planproducts/planproductfactors?planProductID=${planProductID}`,
    {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    },
  )
  .then((resp) => {
    dispatch({
      type: ActionTypes.PPFACTOR_FETCHED,
      payload: resp.data,
    });
  })
  .catch((err) => {
    dispatch({
      type: ActionTypes.PPFACTOR_ERROR,
      payload: err.response
        ? err.response.status === 401
          ? err.response.status
          : err.response.data.message
        : err.message,
    });
  });
};

export const updatePlanProductFactors = (token, planProductID, values) => async (dispatch) => {
  dispatch({ type: ActionTypes.PPFACTOR_REQUESTING });

  await axios
    .post(
      `${conf.serverURL}plans/planproducts/planproductfactor?planproductid=${planProductID}`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.PPFACTOR_UPDATED,
        payload: resp.status,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PPFACTOR_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
