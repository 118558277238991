const FormSpinner = ({ requesting = false }) => {
  if (!requesting) {
    return null;
  }
  return (
    <p className="text-center text-primary mb-3">
      <span className="spinner-border spinner-border-sm" />
    </p>
  );
};

export default FormSpinner;
