import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { getSingleWft1004 } from '@redux/actions/wft1004Actions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowTypeIdForm from './WorkflowTypeIdForm';

const WorkflowTypeID1004 = ({
  match: {
    params: { workflowId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, logout } = useAuth();
  const workflowId1004 = useSelector(state => state.wft1004);
  const { showErrorToast, showSuccessToast } = useToast();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getSingleWft1004(token, workflowId));
    }
    setValid(true);
    dispatch(reset());
  }, [workflowId]);

  const data = workflowId1004?.wft1004?.data?.[0] || {};

  useEffect(() => {
    if (valid) {
      if (workflowId1004?.err) {
        if (unauthorizedError(workflowId1004)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(workflowId1004.err || msgs.error);
      } else if (workflowId1004?.success) {
        showSuccessToast();
        history.push('/workflows');
      }
    }
  }, [valid, workflowId1004]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Participant Elections Update</h3>

      <FormSpinner requesting={workflowId1004?.requesting} />
      <WorkflowTypeIdForm id={workflowId} data={data} hasTable />
    </Container>
  );
};

export default WorkflowTypeID1004;
