import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { TableDashboard, Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { cpStateReset } from '@redux/actions/cpActions';
import { getRecordKeepers } from '@redux/actions/rksActions';
import { reset } from '@redux/reducers/formFieldsReducer';

const RecordKeepers = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const recordKeeperData = useSelector(state => state.rks);
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );

  const page = getQueryParam('page');
  const sortRecordkeeperName = getQueryParam('sortRecordkeeperName');
  const sortRKChannel = getQueryParam('sortRKChannel');
  const sortStatus = getQueryParam('sortStatus');
  const { offset, pageNumber } = getPagination(pageSize, page);

  const {
    err,
    requesting,
    rks: { data, recordCount },
  } = recordKeeperData || {};

  const refreshString = useCallback(() => {
    if (sortRecordkeeperName) {
      return `Recordkeepers.RecordkeeperName ${sortRecordkeeperName.toUpperCase()}`;
    }
    if (sortRKChannel) {
      return `Users.UserName ${sortRKChannel.toUpperCase()}, Recordkeepers.RecordkeeperName ASC`;
    }
    if (sortStatus) {
      return `Recordkeepers.Status ${sortStatus.toUpperCase()}, Recordkeepers.RecordkeeperName ASC`;
    }
    return null;
  }, [sortRKChannel, sortRecordkeeperName, sortStatus]);

  const refStr = refreshString();

  useEffect(() => {
    dispatch(reset());
    dispatch(cpStateReset());
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(getRecordKeepers(token, pageSize, offset, refStr));
    }
    setRefresh(false);
  }, [pageSize, offset, refresh, refStr]);

  const navigateToEditPage = useCallback(
    (id = '') => {
      const query = buildQueryParams({
        sortRecordkeeperName,
        sortRKChannel,
        sortStatus,
        pageSize,
      });
      return `/recordkeepers/${id}/edit?from=${pageNumber}${query}`;
    },
    [pageNumber, pageSize, sortRKChannel, sortRecordkeeperName, sortStatus],
  );

  const column = useMemo(
    () => [
      {
        id: 'recordkeeperName',
        header: 'Recordkeeper Name',
        accessorFn: row => row?.recordkeeperName || '-',
        enableSorting: true,
        size: 275,
      },
      {
        id: 'rKChannel',
        header: 'RK Channel',
        accessorFn: row => row?.userName || '-',
        enableSorting: true,
        size: 200,
      },
      {
        id: 'contactName',
        header: 'Contact Name',
        accessorFn: row => row?.contactName || '-',
      },
      {
        id: 'contactPhone',
        header: 'Contact Phone',
        accessorFn: row => row?.contactPhone || '-',
      },
      {
        id: 'contactEmail',
        header: 'Contact Email',
        accessorFn: row => row?.contactEmail || '-',
        size: 200,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: row => row?.status || '-',
        enableSorting: true,
      },
      {
        id: 'editActions',
        header: '',
        cell: (tableRow) => {
          const { recordkeeperID } = tableRow.row.original || {};
          const editRecordkeeperPage = navigateToEditPage(recordkeeperID);
          return (
            <div className="column-end pr-2 my-1">
              <Link to={editRecordkeeperPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [navigateToEditPage],
  );

  const pageProps = useMemo(
    () => ({
      total: recordCount,
      pageSize,
      setPageSize,
      pageNumber,
    }),
    [pageNumber, pageSize, recordCount],
  );

  return (
    <TableDashboard
      pageTitle="Recordkeepers"
      setRefresh={setRefresh}
      navigatePath="/recordkeepers/add"
    >
      <Spinner requesting={requesting}>
        <Table
          columns={column}
          data={data}
          pageProps={pageProps}
          errStatus={err}
        />
      </Spinner>
    </TableDashboard>
  );
};

export default RecordKeepers;
