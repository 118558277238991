import Input from '@atoms/Input';

const EditableCell = ({
  row: { index },
  column: { id },
  table,
  onBlur,
  onChange,
  maxLength,
  form,
  tableName,
  alignRight = false,
  alignMiddle = false,
  numericOnly = false,
  shouldPercentage = false,
}) => {
  const { control } = form || {};

  const handleOnBlur = () => {
    onBlur(index, id, table);
  };

  const inputCellAlignment = alignRight
    ? 'column-end'
    : alignMiddle
    ? 'column-center'
    : 'column-start';

  return (
    <div className="editable-cell w-100 flex align-items-center pr-2" id={id}>
      <Input
        name={`${tableName}.${index}.${id}`}
        control={control}
        formatter={{ numericOnly, allowDecimal: true }}
        onBlur={handleOnBlur}
        onChange={onChange}
        className={`text-dark ${inputCellAlignment}`}
        maxLength={maxLength}
        wrapperClassName="w-full"
      />
      {shouldPercentage ? <span className="percentage-sign">%</span> : <></>}
    </div>
  );
};

export default EditableCell;
