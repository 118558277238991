import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const createPlanProduct = (planId, data, token) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PPS_REQUESTING,
  });

  await axios
    .post(conf.serverURL + `plans/${planId}/planproducts`, data, {
      headers: {
        'Content-Types': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PP_ADDED,
        payload: resp.data.planProductID,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PPS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const getPlanProduct = (ppId, token) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PPS_REQUESTING,
  });

  await axios
    .get(conf.serverURL + `plans/planproducts/${ppId}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PP_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PPS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const updatePlanProduct = (planId, proId, data, token) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PPS_REQUESTING,
  });

  await axios
    .put(conf.serverURL + `plans/${planId}/planproducts/${proId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PP_UPDATED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PPS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
