import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  eps: [],
};

const epsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.EPS_REQUESTING:
    return { ...state, requesting: true, err: '' };

  case ActionTypes.EPS_ERROR:
    return { ...state, requesting: false, err: payload };

  case ActionTypes.EPS_FETCHED:
    return {
      ...state, requesting: false, err: '', eps: payload,
    };

  default:
    return state;
  }
};

export default epsReducer;
