import { domainUrl } from '@config/conf';

export const getHostEnvironment = () => {
  const host = window.location.host.toLowerCase();
  let hostEnvironment = '';
  if (host === domainUrl.local) {
    hostEnvironment = 'local';
  } else if (host === domainUrl.dev) {
    hostEnvironment = 'dev';
  } else if (host === domainUrl.stage) {
    hostEnvironment = 'stage';
  } else if (host === domainUrl.production) {
    hostEnvironment = 'production';
  }
  return hostEnvironment;
};

export const getTarget = () => {
  const host = window.location.host.toLowerCase();
  console.log(host);
  let targetLink;

  if (host === 'localhost:3000') {
    targetLink = host.replace('localhost:3000', 'localhost:3001');
  } else if (host.startsWith('dev-ui')) {
    targetLink = host.replace('dev-ui', 'dev-annuitant-ui');
  } else if (host.startsWith('stage-ui2')) {
    targetLink = host.replace('stage-ui2', 'stage-annuitant-ui');
  } else if (host.startsWith('stage-ui')) {
    targetLink = host.replace('stage-ui', 'stage-annuitant-ui');
  } else if (host.startsWith('happy-grass')) {
    targetLink = 'annuitant-ui.retirement-edge.com';
  } else if (host.startsWith('ui')) {
    targetLink = host.replace('ui', 'annuitant-ui');
  } else {
    targetLink = 'annuitant-ui.retirement-edge.com';
  }

  return targetLink;
};
