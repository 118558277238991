import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import { reset } from '@redux/reducers/formFieldsReducer';

import CarrierProductsTable from './components/CarrierProductsTable';

const CarriersProduct = (props) => {
  const { match: { params: { carrierId } } } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mb-3">Products</h3>
        <Link to="/carriers/products/add" className="btn btn-primary">Add</Link>
      </div>

      <CarrierProductsTable carrierId={carrierId} />
    </Container>
  );
};

CarriersProduct.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      carrierId: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default CarriersProduct;
