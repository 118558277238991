import React from 'react';

import { Container } from 'reactstrap';

import ResubmitWFT1001Form from './components/ResubmitWFT1001Form';

const ResubmitWFT1001 = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">
      Resubmit Participant Information Update
    </h3>
    <ResubmitWFT1001Form />
  </Container>
);

export default ResubmitWFT1001;
