import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  rks: [],
  rk: {},
  success: '',
};

const rksReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.RKS_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.RKS_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.RKS_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      rks: payload,
      rk: '',
      success: '',
    };

  case ActionTypes.RK_FETCHED:
    return {
      ...state, requesting: false, err: '', rk: payload, success: '',
    };

  case ActionTypes.RK_ADDED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  case ActionTypes.RK_UPDATED:
    return {
      ...state, requesting: false, err: '', success: payload,
    };

  default:
    return state;
  }
};

export default rksReducer;
