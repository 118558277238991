import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import CarrierSubaccountsTable from './components/CarrierSubaccountsTable';

const CarrierSubaccounts = (props) => {
  const { match: { params: { carrierId, productId } } } = props;

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mb-3">Subaccounts</h3>
        <Link to="/" className="btn btn-primary">Add Subaccount</Link>
      </div>

      <CarrierSubaccountsTable carrierId={carrierId} productId={productId} />
    </Container>
  );
};

CarrierSubaccounts.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      carrierId: PropTypes.number.isRequired,
      productId: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default CarrierSubaccounts;
