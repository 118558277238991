import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getAnnuity = (token, id, isAnnuitantSystemUser) => async (dispatch) => {
    dispatch({ type: ActionTypes.ANNUITY_REQUESTING });

    await axios
      .get(
        conf.serverURL
          + `reaccounts/annuitydetails/${id}/${isAnnuitantSystemUser}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        },
      )
      .then(async (resp) => {
        dispatch({
          type: ActionTypes.ANNUITY_FETCHED,
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.ANNUITY_ERROR,
          payload: err.response?.data.message || err.message,
        });
      });
  };

export const getAnnuityUserToken = credentials => async (dispatch) => {
  await axios
    .post(conf.serverURL + 'loginAnnuityUser', credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (resp) => {
      dispatch({
        type: ActionTypes.LOGIN_MC_USER_SUCCESS,
        payload: resp.data.token,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.LOGIN_MC_USER_ERROR,
        payload: err.response?.data.message || err.message,
      });
    });
};

export const logoffAnnuitantToken = credentials => async (dispatch) => {
  await axios
    .post(conf.serverURL + 'logout', credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (resp) => {
      const link = document.createElement('a');
      const host = window.location.host.toLowerCase();
      link.href = `${window.location.protocol}//${host}/ViewAccountLogOut`;
      link.click();
    });
};

export const logoffGenericAnnuitantUserToken = credentials => async (dispatch) => {
    await axios
      .post(conf.serverURL + 'logoutGenericAnnuitantUser', credentials, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        const link = document.createElement('a');
        const host = window.location.host.toLowerCase();
        link.href = `${window.location.protocol}//${host}/ViewAccountLogOut`;
        link.click();
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.ANNUITY_ERROR,
          payload: err.response?.data.message || err.message,
        });
      });
  };

export const getProducts = (token, reAccountNumber) => async (dispatch) => {
  dispatch({ type: ActionTypes.ANNUITY_REQUESTING });

  await axios
    .get(conf.serverURL + `reaccounts/getPlanProductPios/${reAccountNumber}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then(async (resp) => {
      // alert(JSON.parse(JSON.stringify(resp.data)));
      dispatch({
        type: ActionTypes.ANNUITY_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      // alert(`getProductsAPI call: ${err}`);
      dispatch({
        type: ActionTypes.ANNUITY_ERROR,
        payload: err.response?.data.message || err.message,
      });
    });
};

export const updateAnnuityProductInvestmentOptionAllocations = (token, data, reAccountNumber, isAnnuitantSystemUser) => async (dispatch) => {
    dispatch({
      type: ActionTypes.ANNUITY_REQUESTING,
    });

    await axios
      .post(
        conf.serverURL
          + `reaccounts/adupdatePIO/${reAccountNumber}/${isAnnuitantSystemUser}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        },
      )
      .then((resp) => {
        dispatch({
          type: ActionTypes.ANNUITY_ALLOCATIONS_UPDATED,
          payload: resp.status,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.ANNUITY_ERROR,
          payload: err.response
            ? err.response.status === 401
              ? err.response.status
              : err.response.data.message
            : err.message,
        });
      });
  };

export const getAnnuityDocuments = (token, reAccountNumber, isAnnuitantSystemUser) => async (dispatch) => {
    dispatch({ type: ActionTypes.ANNUITY_REQUESTING_DOCUMENTS });

    await axios
      .get(
        conf.serverURL
          + `reaccounts/annuitydocumentvault/${reAccountNumber}/${isAnnuitantSystemUser}`,
        {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        },
      )
      .then(async (resp) => {
        dispatch({
          type: ActionTypes.ANNUITY_FETCHED_DOCUMENTS,
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.ANNUITY_ERROR_DOCUMENTS,
          payload: err.response?.data.message || err.message,
        });
      });
  };
