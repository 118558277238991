import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  data: null,
};

const formFieldsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.FORM_FIELDS:
    return { data: payload };

  case ActionTypes.FORM_RESET:
    return { data: null };

  default:
    return state;
  }
};

export const load = data => ({ type: ActionTypes.FORM_FIELDS, payload: data });
export const reset = () => ({ type: ActionTypes.FORM_RESET, payload: null });

export default formFieldsReducer;
