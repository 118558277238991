import React, { useCallback, useEffect } from 'react';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { sanitizeValue } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import {
  getSingleProductFactor,
  updateProductFactor,
} from '@redux/actions/productFactorActions';

import ProductFactorForm from './ProductFactorForm';

const EditProductFactor = (props) => {
  const {
    match: {
      params: { id: productID, productFactorID },
    },
  } = props;

  const editProductFactorForm = useForm({
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  const { token } = useAuth();
  const productFactors = useSelector(state => state.productFactor);

  const { reset, getValues } = editProductFactorForm || {};
  const { err, productFactor, requesting } = productFactors || {};
  const dataStatus = { err, requesting } || {};
  const { lastUpdatedOn, ...otherValues } = productFactor || {};
  const formattedDate = lastUpdatedOn
    ? moment(lastUpdatedOn).format('MM/DD/YY')
    : '';

  useEffect(() => {
    if (productFactorID && token) {
      dispatch(getSingleProductFactor(token, productFactorID));
    }
  }, [dispatch, token]);

  useEffect(() => {
    reset({
      ...getValues(),
      ...otherValues,
      lastUpdatedOn: formattedDate,
      deferralYears: String(otherValues.deferralYears),
      jointPercent: String(otherValues.jointPercent),
    });
  }, [productFactor]);

  const handleSubmit = useCallback(
    (values) => {
      const {
        jointPercent,
        deferralYears,
        factorName,
        factorID,
        refundable,
        joint,
        selectable,
        status,
      } = values || {};
      const payloadValues = {
        deferralYears: Number(deferralYears),
        jointPercent: Number(jointPercent),
        factorName: sanitizeValue(factorName),
        factorID: sanitizeValue(factorID),
        productID,
        refundable,
        selectable,
        status,
        joint,
      };
      dispatch(updateProductFactor(token, productFactorID, payloadValues));
    },
    [dispatch, token, productFactorID, lastUpdatedOn],
  );

  return (
    <div className="flex flex-column align-items-center justify-content-center">
      <h3 className="mb-3">Product Factor Detail</h3>
      <ProductFactorForm
        form={editProductFactorForm}
        onSubmit={handleSubmit}
        productFactorID={productFactorID}
        dataStatus={dataStatus}
        productID={productID}
      />
    </div>
  );
};

export default EditProductFactor;
