import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getAccountPioas = (token, id, offset = 0, limit = 0) => async (dispatch) => {
  dispatch({ type: ActionTypes.ACCOUNT_PIOAS_REQUESTING });

  await axios
    .get(
      conf.serverURL
          + `reaccounts/pioallocations/${id}?Offset=${offset}&Limit=${limit}`,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.ACCOUNT_PIOAS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.ACCOUNT_PIOAS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
