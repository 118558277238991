import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Badge, Container } from 'reactstrap';

import { conf } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import { getCarriers } from '@redux/actions/carriersActions';
import { cpStateReset } from '@redux/actions/cpActions';
import { getCustodians } from '@redux/actions/custodiansActions';
import { getProducts } from '@redux/actions/productsActions';
import { getRecordKeepers } from '@redux/actions/rksActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import WorkflowsTable from './components/WorkflowsTable';

const Workflows = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const { token, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // useEffect(() => {
  //   dispatch(cpStateReset());
  //   dispatch(reset());
  // }, [dispatch]);

  const rks = useSelector(state => state.rks);
  const custodians = useSelector(state => state.custodians);
  const carriers = useSelector(state => state.carriers);
  const products = useSelector(state => state.products);

  const [exporting, setExporting] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvClickRef = useRef();

  useEffect(() => {
    if (token) {
    dispatch(cpStateReset());
    dispatch(reset());

    dispatch(getRecordKeepers(token));
    dispatch(getCustodians(token));
    dispatch(getCarriers(token));
    dispatch(getProducts(token));

    axios
      .get(conf.serverURL + 'rkchannels/get', {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      })
      .then((resp) => {
        localStorage.setItem('rkChannelID', resp?.data?.data[0]?.userID);
      })
      .catch(err => console.log(err));

    // axios
    //   .get(
    //     conf.serverURL +
    //       "operations/cashledger/dailysummary?effectiveDate=" +
    //       new Date().toISOString(),
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         accessToken: token,
    //       },
    //     }
    //   )
    //   .then((resp) => {
    //     localStorage.setItem(
    //       "numPending",
    //       resp?.data?.dailysummary?.numPending
    //     );
    //   })
    //   .catch((err) => console.log(err));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (rks?.rks?.data) {
      localStorage.setItem('recordkeeperID', rks?.rks?.data[0]?.recordkeeperID);
    }
    if (custodians?.custodians?.data) {
      localStorage.setItem(
        'custodianID',
        custodians?.custodians?.data[0]?.custodianID,
      );
    }
    if (products?.products?.data) {
      localStorage.setItem('productID', products?.products?.data[0]?.productID);
    }
    if (carriers?.carriers?.data) {
      localStorage.setItem('carrierID', carriers?.carriers?.data[0]?.carrierID);
    }
  }, [
    carriers?.carriers?.data,
    custodians?.custodians?.data,
    products?.products?.data,
    rks?.rks?.data,
  ]);

  useEffect(() => {
    if (csvData.length && exporting) {
      csvClickRef.current.link.click();
      setExporting(false);
    }
  }, [csvData, exporting]);

  return (
    <Container className="workflow">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-green">Workflows</h3>
        <div>
          <button className="btn btn-success" onClick={() => setRefresh(true)}>
            Refresh
          </button>
          <span
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              setExporting(true);
              await axios
                .get(conf.serverURL + 'workflows/export', {
                  headers: {
                    'Content-Type': 'application/json',
                    accessToken: token,
                  },
                })
                .then((resp) => {
                  const data = resp.data.result;

                  data.forEach((item) => {
                    item.alertSet = item.alertSet ? 'True' : 'False';
                    // setCsvData((currentState) => {
                    //   return [...currentState, item];
                    // });
                  });

                  setCsvData(data);
                  // alert(JSON.stringify(csvData));
                  // if (csvData) {
                  //   csvClickRef.current.link.click();
                  //   setExporting(false);
                  // }
                })
                .catch((err) => {
                  setExporting(false);
                  if (err.response?.status === 401) {
                    logout();
                    // // [TODO: Arun]
                    // eslint-disable-next-line no-undef
                    enqueueSnackbar(msgs.authError, {
                      variant: 'error',
                      autoHideDuration: 5000,
                    });
                    history.push('/');
                  } else {
                    enqueueSnackbar(err.response?.data.message || err.message, {
                      variant: 'error',
                      autoHideDuration: 5000,
                    });
                  }
                });
            }}
          >
            <button className="btn btn-primary">
              {exporting ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                'Export'
              )}
            </button>
          </span>
          <CSVLink
            filename="WorkflowsList.csv"
            style={{ display: 'none' }}
            ref={csvClickRef}
            data={csvData}
          >
            <Badge color="primary">Export</Badge>
          </CSVLink>
          {/* <button className="btn btn-primary">
          Export
        </button> */}
        </div>
      </div>

      <WorkflowsTable refresh={refresh} setRefresh={setRefresh} />
    </Container>
  );
};

export default Workflows;
