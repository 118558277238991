import React from 'react';

import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';

const TableDashboard = ({
  children,
  pageTitle = '',
  setRefresh,
  navigatePath = '',
  navigateLabel = 'Add',
  className = '',
  additionalChild,
}) => (
  <Container>
    <div
      className={`flex justify-content-between align-items-center ${className}`}
    >
      <h3 className="mb-2 text-green">{pageTitle}</h3>
      <div>
        {setRefresh ? (
          <Button color="success" onClick={() => setRefresh(true)}>
            Refresh
          </Button>
        ) : null}
        {navigateLabel && navigatePath ? (
          <Link to={navigatePath} className="btn btn-primary">
            {navigateLabel}
          </Link>
        ) : null}
        {additionalChild || null}
      </div>
    </div>
    {children}
  </Container>
);

export default TableDashboard;
