import React, { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { sanitizeValue } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import { createProductFactor } from '@redux/actions/productFactorActions';

import ProductFactorForm from './ProductFactorForm';

const defaultValues = {
  factorName: '',
  joint: true,
  jointPercent: '0',
  refundable: true,
  productName: '',
  deferralYears: '0',
  selectable: true,
  status: 'Active',
};

const AddProductFactor = (props) => {
  const {
    match: {
      params: { id: productID },
    },
  } = props;
  const productFactorForm = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const dispatch = useDispatch();
  const { token } = useAuth();
  const { reset } = productFactorForm || {};

  const handleSubmit = useCallback(
    (values) => {
      const {
        jointPercent,
        deferralYears,
        factorName,
        refundable,
        joint,
        selectable,
        status,
      } = values || {};

      const payloadValues = {
        deferralYears: Number(deferralYears),
        jointPercent: Number(jointPercent),
        factorName: sanitizeValue(factorName),
        productID,
        refundable,
        joint,
        selectable,
        status,
      };
      if (token) {
        dispatch(createProductFactor(token, payloadValues));
      }
      reset({ ...defaultValues });
    },
    [token, dispatch, defaultValues],
  );

  return (
    <div className="flex flex-column align-items-center justify-content-center">
      <h3 className="mb-3">Product Factor Detail</h3>
      <ProductFactorForm
        form={productFactorForm}
        onSubmit={handleSubmit}
        productID={productID}
      />
    </div>
  );
};

export default AddProductFactor;
