import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { connect, useDispatch, useSelector } from 'react-redux';

import { getProducts, updateAnnuityProductInvestmentOptionAllocations } from '@redux/actions/annuityActions';

import UpdateAnnuityAllocations from './UpdateAnnuityAllocations';

const UpdateAnnuityAllocationsWrapper = () => {
  const dispatch = useDispatch();

  const annuityRes = useSelector(state => state.annuity);
  const allocationData = JSON.parse(JSON.stringify(sessionStorage.getItem('allocationData')));

  const annuityAuthToken = sessionStorage.getItem('annuityAuthToken');
  const reAccountNumber = sessionStorage.getItem('reAccountNumber');

  const windowLocationPath = sessionStorage.getItem('window.location.pathname');
  let navigateBackUrl = `${windowLocationPath}?token=${sessionStorage.getItem('annuityAuthToken')}`;

  if (windowLocationPath.includes('GenericUser')) {
    navigateBackUrl = `${windowLocationPath}`;
  }
  // const navigateBackUrl = `/View/Account/Summary/${reAccountNumber}?token=${annuityAuthToken}`;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getData = () => {
    dispatch(getProducts(annuityAuthToken, reAccountNumber));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUpdate = (productInvestmentOptionAllocations) => {
    // convert the rate to decimal before sending out
    const newAllocations = [...productInvestmentOptionAllocations].filter(a => a.updatedRate > 0);
    newAllocations.forEach(((a => a.allocationRate = a.updatedRate / 100)));

    let isAnnuitantSystemUser = true;
    if (windowLocationPath.includes('GenericUser')) {
      isAnnuitantSystemUser = false;
    }

    dispatch(updateAnnuityProductInvestmentOptionAllocations(annuityAuthToken, newAllocations, reAccountNumber, isAnnuitantSystemUser));
  };

  const navigateBack = () => {
    const link = document.createElement('a');
    link.href = navigateBackUrl;
    link.click();
  };

  if (annuityRes?.allocationsUpdated) {
    enqueueSnackbar('Annuity allocations updated successfully!', {
      variant: 'success',
      autoHideDuration: 7000,
    });

    navigateBack();
  }

  if (annuityRes?.requesting) {
    return (
      <div className="pt-5">
        <h1 className="text-center text-primary">
          <span className="spinner-grow" />
        </h1>
      </div>
    );
  }

  const newList = JSON.parse(allocationData);

  newList?.forEach((r) => {
    delete r.productName;
    r.allocationRate = (r.allocationRate * 100).toFixed(0);
    r.updatedRate = 0;
  });

  if (annuityRes.data?.data === undefined) {
    return (
      <div className="pt-5">
        <h4 className="text-center text-danger">
          <span>An error occurred while updating allocations or Session Expired</span>
        </h4>
      </div>
    );
  }

  const pios = (annuityRes.data?.data.data);

  pios?.forEach((p) => {
    delete p.reAccountNumber;
    delete p.planId;
    p.allocationRate = 0;
    p.updatedRate = 0;
  });

  const combined = [...pios, ...newList];
  console.log(combined);

  const allocatedPios = {};

  newList.forEach((a) => {
    allocatedPios[a.pioid] = a;
  });

  const finalList = pios.map((p) => {
    const pio = allocatedPios[p.pioid];
    if (pio) {
      Object.entries(pio).forEach(([key, value]) => {
        p[key] = value;
      });
    }
    return p;
  });

  console.log(finalList);

  return (
    <React.Fragment>
      <UpdateAnnuityAllocations handleUpdate={handleUpdate} combinedPios={finalList} />
    </React.Fragment>

  );
};

export default UpdateAnnuityAllocationsWrapper;
