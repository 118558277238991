import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  wfts: {},
};

const wftsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WFTS_REQUESTING:
    return { ...state, requesting: true, err: '' };

  case ActionTypes.WFTS_ERROR:
    return { ...state, requesting: false, err: payload };

  case ActionTypes.WFTS_FETCHED:
    return {
      ...state, requesting: false, err: '', wfts: payload,
    };

  default:
    return state;
  }
};

export default wftsReducer;
