import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getProductType = token => async (dispatch) => {
  dispatch({ type: ActionTypes.PRODUCT_TYPE_REQUESTING });

  await axios
    .get(conf.serverURL + 'products/productTypes', {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PRODUCT_TYPE_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PRODUCT_TYPE_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
