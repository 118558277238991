import { useEffect, useRef, useState } from 'react';

import useApiFetch from './useApiFetch';

const useCsvExportButton = () => {
  const { get, isPending } = useApiFetch();
  const csvClickRef = useRef();
  const [csvData, setCsvData] = useState({});

  const getExport = async (endpoint) => {
    const data = await get(endpoint);
    setCsvData(data);
  };

  useEffect(() => {
    if (csvData?.response?.length || csvData?.data?.length) {
      csvClickRef.current?.link?.click();
    }
  }, [csvData]);

  return {
    csvClickRef,
    loadingResponse: isPending,
    csvData,
    getExport,
  };
};

export default useCsvExportButton;
