import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { sanitizeValue, unauthorizedError } from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { cpStateReset } from '@redux/actions/cpActions';
import { getREConfig, updateREConfig } from '@redux/actions/recActions';

import ConfigForm from './components/ConfigForm';

const Config = () => {
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const reConfigData = useSelector(state => state.rec);
  const history = useHistory();
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getREConfig(token));
    }
    dispatch(cpStateReset());
    setValid(true);
  }, []);

  const data = reConfigData?.rec?.data?.data[0] || {};

  useEffect(() => {
    if (valid) {
      if (reConfigData?.err) {
        if (unauthorizedError(reConfigData)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(reConfigData.err || msgs.error);
      } else if (reConfigData?.success) {
        showSuccessToast();
      }
    }
  }, [valid, reConfigData]);

  const handleConfig = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      smtpHost,
      smtpPort,
      smtpUserName,
      smtpPassword,
      tokenTimeoutMins,
    } = values || {};

    const isValid = validateRequiredField({
      smtpHost,
      smtpPort,
      'smtp UserName': smtpUserName,
      'smtp Password': smtpPassword,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      tokenTimeoutMins: Number(tokenTimeoutMins),
      smtpPort: Number(smtpPort),
    };

    dispatch(updateREConfig(token, data?.reConfigID, payloadValues));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center text-green">System Configuration</h3>

      <FormSpinner requesting={reConfigData?.requesting} />
      <ConfigForm data={data} onSubmit={handleConfig} />
    </Container>
  );
};

export default Config;
