import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { connectTheme } from '@contexts/Context';
import { useTheme } from '@contexts/themeContext';
import { ThemeProps } from '@shared/prop-types/ReducerProps';

const ToggleTheme = ({ theme, changeToLight, changeToDark }) => {
  // const { setLight } = useTheme();
  const { setIsLight } = useContext(connectTheme);

  const handleChange = () => {
    setIsLight(prevState => !prevState);
  };

  return (
    <label className="toggle-btn customizer__toggle" style={{ paddingTop: '20px', color: 'yellow' }} htmlFor="theme_toggle">
      <input
        className="toggle-btn__input"
        type="checkbox"
        name="theme_toggle"
        id="theme_toggle"
        checked={theme.className === 'theme-dark'}
        onChange={theme.className === 'theme-dark' ? changeToLight : changeToDark}
        onClick={() => handleChange()}
      />

      <span className="toggle-btn__input-label mr-3" />
      <span className={theme.className === 'theme-dark' ? 'text-light' : 'text-dark'}>
        { theme.className === 'theme-dark' ? ' Dark Theme' : ' Light Theme' }
      </span>
    </label>
  );
};

ToggleTheme.propTypes = {
  theme: ThemeProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
};

export default ToggleTheme;
