import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  splitStringyRate,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { createPIO } from '@redux/actions/pioActions';
import { getSingleProduct } from '@redux/actions/productsActions';

import PIOForm from '../PIOForm/PIOForm';

const AddPIO = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const piosData = useSelector(state => state.pio);
  const products = useSelector(state => state.products);
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();

  const [valid, setValid] = useState(false);
  const [defaultData, setDefaultData] = useState(null);

  const page = getQueryParam('from');
  const pageSize = getQueryParam('pageSize');

  const sort = useMemo(
    () => ({
      sortProductName: getQueryParam('sortProductName'),
      sortCarrierName: getQueryParam('sortCarrierName'),
    }),
    [],
  );

  const redirectToProducts = useMemo(() => {
    const { sortCarrierName, sortProductName } = sort || {};
    const queryParams = buildQueryParams({
      sortProductName,
      sortCarrierName,
      pageSize,
    });
    return `/products?id=${products?.product?.productID}&page=${page}${queryParams}`;
  }, [products?.product?.productID, page, sort, pageSize]);

  useEffect(() => {
    if (token) {
      dispatch(getSingleProduct(id, token));
    }
  }, [id]);

  useEffect(() => {
    if (valid) {
      if (piosData?.err) {
        if (unauthorizedError(piosData)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(piosData.err || msgs.error);
        }
      } else if (piosData?.success) {
        showSuccessToast();
        history.push(redirectToProducts);
      }
    }
  }, [piosData, valid]);

  useEffect(() => {
    if (products?.product) {
      setDefaultData({
        productID: id,
        productName: products?.product?.productName,
        pioName: '',
        carrierPIOCode: '',
        pioMethod: '',
        pioDuration: '',
        pioCreditingRate: '',
        pioParticipationRate: '',
        pioMinRate: '',
        pioMaxRate: '',
        pioSelectable: true,
        status: 'Active',
        fiaCapRate: '',
      });
    }
  }, [products?.product?.productName, id]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      pioName,
      carrierPIOCode,
      pioCreditingRate,
      pioParticipationRate,
      pioMinRate,
      pioMaxRate,
      fiaCapRate,
    } = values || {};

    const isValid = validateRequiredField({
      'Investment Option': pioName,
      'Investment Option Code': carrierPIOCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      pioCreditingRate: splitStringyRate(pioCreditingRate) || '',
      pioParticipationRate: splitStringyRate(pioParticipationRate) || '',
      fiaCapRate: splitStringyRate(fiaCapRate) || '',
      pioMinRate: splitStringyRate(pioMinRate) || '',
      pioMaxRate: splitStringyRate(pioMaxRate) || '',
    };

    dispatch(createPIO(token, payloadValues));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Product Investment Option Detail</h3>
      <PIOForm
        data={defaultData}
        onSubmit={handleForm}
        redirectUrl={redirectToProducts}
      />
    </Container>
  );
};

export default AddPIO;
