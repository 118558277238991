import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import {
 Input, Select, Checkbox, FormFooterActions,
} from '@atoms';
import { msgs } from '@config/conf';
import {
  unauthorizedError,
  validateMaxValue,
  validateZeroValue,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { getSingleProduct } from '@redux/actions/productsActions';

const statusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const { fieldError, authError, error } = msgs || {};
const ProductFactorForm = (props) => {
  const {
    form,
    onSubmit,
    productID = '',
    productFactorID = '',
    dataStatus = {},
  } = props || {};

  const dispatch = useDispatch();
  const history = useHistory();
  const { token, logout } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();

  const { getQueryParam, buildQueryParams } = useQueryParams();
  const products = useSelector(state => state.products);

  const page = getQueryParam('from');
  const sortProductName = getQueryParam('sortProductName');
  const sortCarrierName = getQueryParam('sortCarrierName');
  const pageSize = getQueryParam('pageSize');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
  } = form || {};
  const { err, requesting } = dataStatus;
  const isRequesting = requesting || products?.requesting;
  const errStatus = err || products?.err;

  const goBackUrl = useMemo(() => {
    const queryParams = buildQueryParams({
      sortProductName,
      sortCarrierName,
      pageSize,
    });

    return `/products?${
      productFactorID ? `id=${productID}` : ''
    }&page=${page}${queryParams}`;
  }, [productID, sortProductName, sortCarrierName, pageSize, productFactorID]);

  useEffect(() => {
    if (errStatus) {
      if (err === 401 || unauthorizedError(products)) {
        logout();
        showErrorToast(authError);
        history.push('/');
      }
      showErrorToast(errStatus || error);
    } else if (isSubmitSuccessful) {
      showSuccessToast();
      history.push(goBackUrl);
    }
  }, [errStatus, products?.err, isSubmitSuccessful]);

  useEffect(() => {
    if (token && productID) {
      dispatch(getSingleProduct(productID, token));
    }
  }, [dispatch, token, productID]);

  const response = useMemo(
    () => isRequesting && (
    <p className="text-center text-primary mb-3">
      <span className="spinner-border spinner-border-sm" />
    </p>
      ),
    [isRequesting],
  );

  const commonProps = useMemo(
    () => ({
      control,
      rules: {
        required: fieldError,
      },
    }),
    [control],
  );

  useEffect(() => {
    if (products?.product?.productName) {
      setValue('productName', products?.product?.productName);
    }
  }, [products?.product?.productName]);

  return (
    <Card className="product_factor_form_wrapper">
      {response}
      <CardBody>
        <div className="form form--horizontal product_page_wrapper">
          <Input
            {...commonProps}
            name="productName"
            label="Product Name"
            required
            isReadOnly
          />
          <Input
            {...commonProps}
            name="factorName"
            label="Factor Name"
            required
          />
          <Checkbox {...commonProps} name="joint" label="Joint" required />
          <Input
            control={control}
            rules={{
              validate: value => validateMaxValue(value),
            }}
            name="jointPercent"
            label="Joint Percent"
            formatter={{ numericOnly: true }}
          />
          <Checkbox
            {...commonProps}
            name="refundable"
            required
            label="Refundable"
          />
          <Input
            control={control}
            rules={{
              required: fieldError,
              validate: value => validateZeroValue(value, fieldError),
            }}
            name="deferralYears"
            label="Deferral Years"
            required
            formatter={{ numericOnly: true }}
          />
          <Checkbox control={control} name="selectable" label="Selectable" />
          <Select
            {...commonProps}
            name="status"
            label="Status"
            required
            options={statusOptions}
          />
          {productFactorID && (
            <Input
              control={control}
              name="lastUpdatedOn"
              label="Last Updated"
              isReadOnly
            />
          )}
          <FormFooterActions
            linkProps={[{ path: goBackUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductFactorForm;
