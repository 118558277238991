import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import { sanitizeValue, unauthorizedError } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import { addUser } from '@redux/actions/usersActions';

import UserForm from '../UsersForm/UserForm';

const data = {
  userType: 'Admin',
  userName: '',
  loginName: '',
  userEmail: '',
  userPhone: '',
  status: 'Active',
  ipxHosted: false,
};

const AddUser = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const { token, logout } = useAuth();

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (valid) {
      if (users?.err) {
        if (unauthorizedError(users)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        } else {
          showErrorToast(users.err || msgs.error);
        }
      } else if (users?.success) {
        showSuccessToast();
        history.push('/users');
      }
    }
  }, [users, history, valid]);

  const handleAddUser = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const { userName, loginName } = values || {};

    const isValid = validateRequiredField({
      'User Name': userName,
      'Login Name': loginName,
    });

    if (!isValid) {
      return;
    }

    dispatch(addUser(token, values));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">User Detail</h3>
      <UserForm data={data} redirectUrl="/users" onSubmit={handleAddUser} />
    </Container>
  );
};

export default AddUser;
