import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  wft1003: {},
};

const wft1003Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WFT1003_REQUESTING:
    return {
      ...state, requesting: true, err: '', wft1003: '',
    };

  case ActionTypes.WFT1003_ERROR:
    return {
      ...state, requesting: false, err: payload, wft1003: '',
    };

  case ActionTypes.WFT1003_FETCHED:
    return {
      ...state, requesting: false, err: '', wft1003: payload,
    };

  default:
    return state;
  }
};

export default wft1003Reducer;
