import React from 'react';

import DatePicker from 'react-datepicker';

import { formatDate } from '@lib/date';

const DailySettlementSummaryReportTableSearch = ({ fromDate, setFromDate }) => (
  <div className="table__search mb-0">
    <label className="m-0 mr-3">Date:</label>
    <DatePicker
      name="datePicker"
      className="form-control table__search-input"
      value={formatDate(fromDate)}
      selected={fromDate ? new Date(fromDate) : ''}
      onChange={(date) => {
        setFromDate(formatDate(date));
      }}
      placeholderText="From Date (MM/DD/YYYY)"
    />
  </div>
);

export default DailySettlementSummaryReportTableSearch;
