import DOMPurify from 'dompurify';
import moment from 'moment';

export const splitStringyCurrency = (value = 0) => {
  const formattedValue = value.toString();
  let sepDollar = formattedValue.split(' ');
  if (sepDollar[0] === '$') {
    sepDollar = sepDollar[1];
  } else if (formattedValue.includes('$')) {
    sepDollar = formattedValue.replace('$', '');
  } else {
    sepDollar = sepDollar[0];
  }
  const sepSeperator = sepDollar.split(',');
  return Number(sepSeperator.join(''));
};

export const splitStringyNumber = (value) => {
  const sep = value?.toString().split(',');
  return Number(sep?.join(''));
};

export const splitStringyRate = (value) => {
  const sepPer = value?.toString().split('%');
  const sep = sepPer.toString().split(',');
  return Number(sep.join('') / 100);
};

export const formatDate = (dt, string = 'MM/DD/YYYY HH:mm:ss') => {
  if (dt) {
    return moment(dt, string).format('MM/DD/YYYY hh:mm A');
  }
  return '';
};

export const formatDateWithTime = (date) => {
  if (date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY hh:mm A');
  }
  return '';
};

export const formatDateWithoutTime = (dt, string) => {
  if (dt) {
    return moment(dt, string).format(string || 'MM/DD/YYYY');
  }
  return '';
};

// export const formatDate = (dt, addOrSubtract = null) => {
//   let d = new Date(dt);
//   if (addOrSubtract === "add") {
//     d.setHours(d.getHours() + conf.timeOffset);
//   } else if (addOrSubtract === "subtract") {
//     d.setHours(d.getHours() - conf.timeOffset);
//   }
//   let date = new Intl.DateTimeFormat("en-US", {
//     month: "2-digit",
//     day: "2-digit",
//     year: "2-digit",
//     hour: "2-digit",
//     hour12: true,
//     minute: "2-digit",
//   }).format(d);

//   date = date.split(",");
//   return date.join("");
// };

export const formatFixedDigits = (len, val) => {
  const valen = val?.toString().length;
  const ln = parseInt(len) - valen;
  let zeros = '';
  for (let i = 0; i < ln; i += 1) {
    zeros += '0';
  }
  return zeros + val + '';
};

export const taxIdFormat = (value) => {
  const str = value ? value.toString().trim() : '';
  return str.length > 2 ? str.substr(0, 2) + '-' + str.substr(2, 7) : str;
};

export const ssnFormat = value => (value
    ? value?.substr(0, 3)
      + '-'
      + value?.substr(3, 2)
      + '-'
      + value?.substr(5, 4)
    : '');
export const unauthorizedError = error => error?.err === 401;
export const sanitizeValue = value => (value ? DOMPurify.sanitize(value, { ALLOWED_TAGS: [] }).trim() : '');

export const phoneFormat = value => '('
  + value?.substr(0, 3)
  + ') '
  + value?.substr(3, 3)
  + '-'
  + value?.substr(6, 4);

export const displayNumberFormat = (value, dec = 2) => {
  const spl = value?.toLocaleString().split('.');
  if (spl) {
    let remDec = 0;
    if (spl[1]) {
      remDec = dec - spl[1].length;
    } else {
      spl[1] = '';
      remDec = dec;
    }
    let zeros = '';
    for (let i = 0; i < remDec; i += 1) {
      zeros += '0';
    }
    return spl[0] + '.' + spl[1] + zeros;
  }
  return '0.00';
};

export const currencyStringFormat = (len, val) => {
  if (val === '0.00') {
    return '0.00'.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const num = val?.toString().split('.');
  const valen = num[0]?.toString().length;
  const ln = parseInt(len) - valen;
  let zeros = '';
  for (let i = 0; i < ln; i += 1) {
    zeros += '0';
  }
  return (zeros + val + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrency = (value, decimalPlace = 2) => {
  const dollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalPlace,
  });
  return value ? dollar.format(value) : dollar.format(0);
};

export const formatToPercentage = (value, decimalPlace = 2) => {
  const percentage = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: decimalPlace,
    maximumFractionDigits: decimalPlace,
  });
  const formattedNumber = value ? parseFloat(value / 100) : '';
  return percentage.format(formattedNumber);
};

export const validateMaxValue = (value, maxValue = 100) => {
  if (parseFloat(value) > maxValue) {
    return `Must be less than or equal to ${maxValue}.`;
  }
  return true;
};

export const validateZeroValue = (value, validationMessage) => {
  if (Number(value) === 0) {
    return validationMessage;
  }
  return true;
};

export const filterOptions = (data, valueKey, labelKey) => data?.map(item => ({
    value: item?.[valueKey],
    label: item?.[labelKey],
  })) || [];

export const getPagination = (pageSize, pageQuery) => {
  const pageNumber = pageQuery ? parseInt(pageQuery, 10) : 1;
  const offset = (pageNumber - 1) * pageSize;

  return { pageNumber, offset };
};

export const sortBy = (arr, key) => {
  const length = arr.length;

  for (let i = 0; i < length - 1; i += 1) {
    let minIndex = i;

    for (let j = i + 1; j < length; j += 1) {
      if (arr[j][key] < arr[minIndex][key]) {
        minIndex = j;
      }
    }

    if (minIndex !== i) {
      [arr[i], arr[minIndex]] = [arr[minIndex], arr[i]];
    }
  }
  return arr;
};
