import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  productType: [],
  success: '',
};

const productTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PRODUCT_TYPE_REQUESTING:
      return {
        ...state,
        requesting: true,
        err: '',
        success: '',
      };
    case ActionTypes.PRODUCT_TYPE_ERROR:
      return {
        ...state,
        requesting: false,
        err: payload,
        success: '',
      };

    case ActionTypes.PRODUCT_TYPE_FETCHED:
      return {
        ...state,
        requesting: false,
        err: '',
        productType: payload,
        success: '',
      };
    default:
      return state;
  }
};

export default productTypeReducer;
