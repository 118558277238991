import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  countries: {},
};

const countriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.COUNTRIES_REQUESTING:
    return { ...state, requesting: true, err: '' };

  case ActionTypes.COUNTRIES_ERROR:
    return { ...state, requesting: false, err: payload };

  case ActionTypes.COUNTRIES_FETCHED:
    return {
      ...state, requesting: false, err: '', countries: payload,
    };

  default:
    return state;
  }
};

export default countriesReducer;
