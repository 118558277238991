import React from 'react';

import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import AnnuityPieChart from './AnnuityPieChart';
import updateAllocations from './NavigateToUpdateAllocationsPage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: '"Manrope", "-apple-system", "BlinkMacSystemFont", sans-serif',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    color: 'white',
    height: '55px',
  },
  abar: {
    backgroundColor: '#7199BB',
    height: '30px',
    Align: 'center',
    borderRadius: '5px',
  },
  tbar: {
    minHeight: '32px',
    alignItems: 'center',
  },
  bx: {
    fontFamily: '"Manrope", "-apple-system", "BlinkMacSystemFont", sans-serif',
  },
  sup: {
    top: '-0.5',
  },
  btnfr: {
    float: 'right',
    backgroundColor: '#005A9C',
    color: 'white',
  },
}));

const getChartData = data => data?.map((a) => {
    const container = {};

    container.id = a.pioName;
    container.label = `${a.pioName} - ${Math.round(
      a.allocationRate * 100,
      2,
    )}%`;
    container.value = `${Math.round(a.allocationRate * 100, 2)}`;

    return container;
  });

const getChartLegendData = data => data?.map((a) => {
    const container = {};

    container.id = a.pioName;
    container.label = `${
      a.pioName
    } <br /> <Typography variant="h6">${Math.round(
      a.allocationRate * 100,
      2,
    )}%</Typography>`;

    return { label: data?.allocationRate, id: `${data.pioName}` };
  });

const AllocationMix = (props) => {
  const classes = useStyles();
  const { data = [] } = props;

  const chartData = getChartData(data);
  const chartLegendData = getChartLegendData(data);

  sessionStorage.setItem('allocationData', JSON.stringify(data));

  const showChangeCreditingOptionsLink = () => {
    const managedFlag = sessionStorage.getItem('managedFlag');
    return managedFlag?.toLowerCase() !== 'false'; // ==='1'? false:true;
  };

  return (
    <>
      <Grid container direction="col" spacing={5}>
        <Grid item xs={12} sm={12} mt={5}>
          <Box mt={5}>
            <AnnuityPieChart
              data={chartData}
              secondaryLegendData={chartLegendData}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={5}>
        <Grid item xs={12}>
          <Button
            onClick={updateAllocations}
            variant="contained"
            className={`${classes.btnfr} ${
              showChangeCreditingOptionsLink() ? 'd-none' : 'd-block'
            } `}
            flexGrow
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AllocationMix;
