import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';

import { Input, Checkbox, Select } from '@atoms';
import { msgs } from '@config/conf';

const productTypeOptions = [
  { value: 'type1', label: 'Type 1' },
  { value: 'type2', label: 'Type 2' },
];

const CarrierProductsForm = ({ data, carrierId }) => {
  const { control, reset, handleSubmit } = useForm();

  const requiredProps = {
    control,
    required: true,
    rules: {
      required: msgs.fieldError,
    },
  };

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onSubmit = (values) => {
    alert(JSON.stringify(values));
  };

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          <Select
            {...requiredProps}
            name="productType"
            label="Product Type"
            placeholder="Select Product Type"
            options={productTypeOptions}
          />
          <Input
            {...requiredProps}
            name="productName"
            label="Product Name"
            placeholder="Product Name"
          />
          <Input
            {...requiredProps}
            name="extProductCode"
            label="Ext Product Code"
            placeholder="Ext Product Code"
          />
          <Input
            {...requiredProps}
            name="minInitialPurchase"
            label="Min Initial Purchase"
            placeholder="Min Initial Purchase"
          />
          <Checkbox control={control} name="active" label="Active" />

          <div className="w-100 flex justify-content-end">
            <Link
              to={
                data.type === 'add'
                  ? '/carriers'
                  : `/carriers/${carrierId}/products`
              }
              className="btn btn-outline-danger"
            >
              Cancel
            </Link>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              {data.type === 'add' ? 'Add Product' : 'Update Product'}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CarrierProductsForm;
