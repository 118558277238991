import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getPlanProduct, updatePlanProduct } from '@redux/actions/ppsActions';

import PlanProductForm from '../components/PlanProductForm';

const EditPlanProduct = (props) => {
  const editPlanProductForm = useForm({
    mode: 'onChange',
  });

  const {
    match: {
      params: { planId, productId: planProductId },
    },
  } = props;

  const { token, logout } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const [valid, setValid] = useState(false);
  const planProducts = useSelector(state => state.pps);

  const pageNumber = getQueryParam('from');
  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');
  const pageSize = getQueryParam('pageSize');

  const { authError, error, success } = msgs || {};

  const queryParams = buildQueryParams({
    search,
    sortPlanName,
    sortStatus,
    pageSize,
  });

  const navigateToPlans = `/plans?page=${pageNumber}&id=${planId}${queryParams}`;

  useEffect(() => {
    if (token) {
      dispatch(getPlanProduct(planProductId, token));
    }
    setValid(true);
  }, [planProductId]);

  useEffect(() => {
    if (valid) {
      if (planProducts?.err) {
        if (unauthorizedError(planProducts)) {
          logout();
          enqueueSnackbar(authError, {
            variant: 'error',
            autoHideDuration: 5000,
          });
          history.push('/');
        }
        enqueueSnackbar(planProducts?.err || error, {
          variant: 'error',
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
      } else if (planProducts?.success) {
        enqueueSnackbar(success, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push(navigateToPlans);
      }
    }
  }, [planProducts, valid, navigateToPlans]);

  const handleEditForm = useCallback(
    (values) => {
      if (token) {
        dispatch(updatePlanProduct(planId, planProductId, values, token));
      }
      values.carrierPlanIdentifier = sanitizeValue(
        values.carrierPlanIdentifier,
      );
      setValid(true);
    },
    [planId, planProductId],
  );

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Product Detail</h3>
      <PlanProductForm
        form={editPlanProductForm}
        data={planProducts}
        planProductId={planProductId}
        planId={planId}
        onSubmit={handleEditForm}
        loadingResponse={planProducts?.requesting}
      />
    </Container>
  );
};

export default EditPlanProduct;
