import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';

import App from './containers/App/App';
import { getConfig } from './containers/Authorize/config';
import * as serviceWorker from './serviceWorker';
import history from './utils/history';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname,
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </React.Fragment>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
