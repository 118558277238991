import React from 'react';

import { Modal as ReactStrapModal, ButtonToolbar, Button } from 'reactstrap';

const variantClasses = {
  primary: 'modal-dialog--primary',
  success: 'modal-dialog--success',
  warning: 'modal-dialog--warning',
  danger: 'modal-dialog--danger',
  colored: 'modal-dialog--colored',
};

const Modal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    content,
    title = 'Attention',
    variant = 'danger',
    iconName = 'lnr-flag',
    modalButtons,
  } = props || {};
  const toggle = () => setIsModalOpen(!isModalOpen);

  return (
    <ReactStrapModal
      isOpen={isModalOpen}
      toggle={toggle}
      className={variantClasses?.[variant]}
    >
      <div className="modal__header">
        <span className={`lnr ${iconName} modal__title-icon`} />
        <h4 className="text-modal  modal__title">{title}!</h4>
      </div>
      <div className="modal__body">{content}</div>
      <ButtonToolbar className="modal__footer">
        {modalButtons?.map((props) => {
          const { name, onClick, color } = props || {};
          return (
            <Button
              key={name}
              color={color}
              onClick={onClick}
              className="modal_btn"
            >
              {name}
            </Button>
          );
        })}
      </ButtonToolbar>
    </ReactStrapModal>
  );
};

export default Modal;
