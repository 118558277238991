import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomInput, Container } from 'reactstrap';

import { Spinner, Table, FormFooterActions } from '@atoms';
import { msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import {
  getPlanProductFactors,
  updatePlanProductFactors,
} from '@redux/actions/planProductFactorActions';

const PlanFactors = ({
  match: {
    params: { planProductId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const planProductFactorData = useSelector(state => state.ppFactor);

  const [productFactorIds, setProductFactorIds] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const planId = getQueryParam('planId');
  const page = getQueryParam('from');
  const search = getQueryParam('search');
  const sortPlanName = getQueryParam('sortPlanName');
  const sortStatus = getQueryParam('sortStatus');

  const { err, planProductFactor, requesting } = planProductFactorData || {};

  useEffect(() => {
    if (token) {
      dispatch(getPlanProductFactors(token, planProductId));
    }
  }, [token, dispatch, planProductId]);

  useEffect(() => {
    const selectedFactors = planProductFactor?.data?.filter(
      item => item.selected,
    );
    if (selectedFactors?.length) {
      setProductFactorIds((prevIds) => {
        const currentIds = prevIds.map(factor => factor.productFactorID);
        const newSelectedIds = selectedFactors.map(
          item => item.productFactorID,
        );

        const updatedIds = [
          ...selectedFactors
            .filter(item => !currentIds.includes(item.productFactorID))
            .map(item => ({ productFactorID: item.productFactorID })),
          ...prevIds.filter(factor => newSelectedIds.includes(factor.productFactorID)),
        ];
        return updatedIds;
      });
    } else {
      setProductFactorIds([]);
    }
  }, [planProductFactor]);

  const filteredPlanProductFactor = useMemo(
    () => planProductFactor?.data?.filter(data => data?.status === 'Active'),
    [planProductFactorData],
  );

  const query = buildQueryParams({
    search,
    sortPlanName,
    sortStatus,
  });

  const navigateToPlans = `/plans?page=${page}&id=${planId}${query}`;

  useEffect(() => {
    if (submitting) {
      enqueueSnackbar(msgs.success, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push(navigateToPlans);
    }
  }, [submitting, navigateToPlans]);

  const handleCheckboxChange = (productFactorID) => {
    setProductFactorIds((prevIds) => {
      const isSelected = prevIds.some(
        val => val.productFactorID === productFactorID,
      );
      if (isSelected) {
        return prevIds.filter(id => id.productFactorID !== productFactorID);
      }
      return [...prevIds, { productFactorID }];
    });
  };

  const columns = useMemo(
    () => [
      {
        id: 'factorName',
        header: <div className="pl-4 ml-3">Factors</div>,
        size: '75%',
        cell: ({
          row: {
            original: { productFactorID, factorName },
          },
        }) => {
          const isSelectedFactor = productFactorIds.some(
            val => val.productFactorID === productFactorID,
          );
          return (
            <div key={productFactorID} className="flex">
              <CustomInput
                type="checkbox"
                name={`factorID-${productFactorID}`}
                id={productFactorID}
                onChange={() => handleCheckboxChange(productFactorID)}
                checked={isSelectedFactor}
              />
              <span className="ml-3">{factorName}</span>
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
      },
    ],
    [productFactorIds],
  );

  const handleClick = useCallback(() => {
    if (!productFactorIds.length) {
      enqueueSnackbar('At least one Factor must be selected.', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } else {
      token
        && dispatch(
          updatePlanProductFactors(token, planProductId, productFactorIds),
        );
      setSubmitting(true);
    }
  }, [productFactorIds, token, dispatch, planProductId, enqueueSnackbar]);

  const tableFooter = useMemo(() => (
    <FormFooterActions
      linkProps={[{ path: navigateToPlans }]}
      buttonProps={[{ onClick: handleClick }]}
    />
    ), [handleClick, navigateToPlans]);

  return (
    <Container>
      <h3 className="mb-3">Factors</h3>
      <Spinner requesting={requesting}>
        <Table
          columns={columns}
          data={filteredPlanProductFactor}
          hidePagination
          footer={tableFooter}
          errStatus={err}
        />
      </Spinner>
    </Container>
  );
};

export default PlanFactors;
