import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  wft1004: {},
};

const wft1004Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WFT1004_REQUESTING:
    return {
      ...state, requesting: true, err: '', wft1004: '',
    };

  case ActionTypes.WFT1004_ERROR:
    return {
      ...state, requesting: false, err: payload, wft1004: '',
    };

  case ActionTypes.WFT1004_FETCHED:
    return {
      ...state, requesting: false, err: '', wft1004: payload,
    };

  default:
    return state;
  }
};

export default wft1004Reducer;
