import React, { useEffect } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';

import { conf, msgs } from '@config/conf';
import { unauthorizedError } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';

import ProductsForm from '../ProductsForm/ProductsForm';

const defaultValues = {
  carrierID: localStorage.getItem('carrierID'),
  carrierProductCode: '',
  carrierProductCusip: '',
  productName: '',
  procductTypeID: '',
  minInitialPurchase: '',
  minPolicyIssueAge: '',
  maxPolicyIssueAge: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '',
  notes: '',
  annuityFees: '0.00%',
  productIdentifier: '',
  annualBonus: '0.00%',
  incomeAcceleratedCredit: '0.00%',
};

const AddProduct = () => {
  const addProductForm = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const history = useHistory();
  const products = useSelector(state => state.products);

  useEffect(() => {
    if (products?.err) {
      if (unauthorizedError(products)) {
        logout();
        enqueueSnackbar(msgs.authError, {
          variant: 'error',
          autoHideDuration: 5000,
        });
        history.push('/');
      } else {
        enqueueSnackbar(products.err || msgs.error, {
          variant: 'error',
          autoHideDuration: 5000,
          preventDuplicate: true,
        });
      }
    } else if (products?.success) {
      axios
        .get(conf.serverURL + 'products-and-pios/get', {
          headers: {
            'Content-Type': 'application/json',
            accessToken: token,
          },
        })
        .then((resp) => {
          localStorage.setItem('productID', resp?.data?.data[0]?.productID);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
            enqueueSnackbar(msgs.authError, {
              variant: 'error',
              autoHideDuration: 5000,
            });
            history.push('/');
          } else {
            console.log('Product Err: ', err);
          }
        });
      enqueueSnackbar(msgs.success, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      history.push('/products');
    }
  }, [products, history, dispatch]);

  return (
    <Container>
      <h3 className="mb-3 text-center">Product Detail</h3>
      <div className="product_wrapper">
        <ProductsForm productForm={addProductForm} />
      </div>
    </Container>
  );
};

export default AddProduct;
