import React from 'react';

import { Redirect, Route } from 'react-router';

import { useAuth } from '@contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authToken = localStorage.getItem('authToken');
  // const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={props => (authToken ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
