import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getWithdrawalRates = (token, prodId) => async (dispatch) => {
  dispatch({ type: ActionTypes.WRS_REQUESTING });

  await axios
    .get(conf.serverURL + `products/wr/${prodId}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({ type: ActionTypes.WRS_FETCHED, payload: resp.data });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.WRS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const saveWithdrawalRates = (token, prodId, data) => async (dispatch) => {
  dispatch({ type: ActionTypes.WRS_REQUESTING });

  await axios
    .put(conf.serverURL + `products/wr/${prodId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({ type: ActionTypes.WRS_SAVED, payload: resp.data });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.WRS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
