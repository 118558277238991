import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import { logoffGenericAnnuitantUserToken, getAnnuity } from '@redux/actions/annuityActions';

import ViewAccountForm from './components/ViewAccountForm';

const ViewGenericUserAccountSummary = (props) => {
  const dispatch = useDispatch();

  const {
    match: {
      params: { id },
    },
  } = props;

  const annuityRes = useSelector(state => state.annuity);

  // const params = new URLSearchParams(window.location.search);
  // const token = decodeURI(params.get('token'));
  // const reAccountNumber = window.location.pathname.split('/')[4];

  // sessionStorage.setItem("reAccountNumber", reAccountNumber);
  // sessionStorage.setItem("annuityAuthToken", token);

  sessionStorage.setItem('annuityAuthToken', sessionStorage.getItem('accessToken')); // referenced in AnnuityDocumentVault and UpdateAnnuityAllocations pages

  const token = sessionStorage.getItem('accessToken');
  const reAccountNumber = sessionStorage.getItem('reAccountNumber');

  const getData = () => {
    dispatch(getAnnuity(token, reAccountNumber, false));
  };

  useEffect(() => {
    getData();
  }, []);

  if (annuityRes?.requesting) {
    return (
      <div className="pt-5">
        <h1 className="text-center text-primary">
          <span className="spinner-grow" />
        </h1>
      </div>
    );
  }

  if (annuityRes?.err) {
    return (
      <div className="pt-5">
        <h3 className="text-center text-danger">
          <span>{annuityRes?.err}</span>
        </h3>
      </div>
    );
  }

  const numberOfPolicies = annuityRes.data.data.length;

  let activeTab = Number(sessionStorage.getItem('activeTab'));

  if (!activeTab) activeTab = (numberOfPolicies > 1 ? 0 : 1);

  const invalidateAnnuitantToken = () => {
    sessionStorage.setItem('annuityAuthToken', null);
    sessionStorage.removeItem('activeTab');
    const credentials = {
      token,
      loginName: 'GenericAnnuitantUser',
      userType: 'Annuitant',
      reAccountNumber,
    };

    dispatch(logoffGenericAnnuitantUserToken(credentials));
  };

  return (
    <Container>
      <ViewAccountForm token={token} id={id} invalidateAnnuitantToken={invalidateAnnuitantToken} activeTab={activeTab} data={annuityRes.data.data} />
    </Container>
  );
};

export default ViewGenericUserAccountSummary;
