import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  err: '',
  token: '',
};

const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.LOGIN_ERROR:
    return { ...state, err: payload, token: '' };

  case ActionTypes.LOGIN_SUCCESS:
    return { ...state, err: '', token: payload };

  case ActionTypes.LOGIN_LOGOUT:
    return { ...state, err: '', token: '' };

  case ActionTypes.LOGIN_LOGOUT_ERROR:
    return { ...state, err: payload, token: '' };

  default:
    return state;
  }
};

export default loginReducer;
