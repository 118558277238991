import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { Input, Select, FormFooterActions } from '@atoms';
import { msgs } from '@config/conf';
import { filterOptions } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import { getUsers } from '@redux/actions/usersActions';

const ConfigForm = ({ data, onSubmit }) => {
  const { control, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const { token } = useAuth();
  const users = useSelector(state => state.users);

  useEffect(() => {
    if (token) {
      dispatch(getUsers(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const requiredProps = {
    control,
    required: true,
    rules: { required: msgs.fieldError },
  };

  const userOptions = useMemo(
    () => filterOptions(users?.users?.data, 'userID', 'userName'),
    [users?.users?.data],
  );

  const validateTokenTimeout = (value) => {
    if (value < 1) {
      return 'Should be greater than 0.';
    }
    if (value > 60) {
      return 'Should be less than or equal to 60.';
    }
    return true;
  };

  const formFooterProps = {
    linkProps: [{ labelName: 'Cancel', path: '/config/re' }],
    buttonProps: [{ onClick: handleSubmit(onSubmit) }],
  };

  return (
    <div>
      <Card className="container_width">
        <CardBody>
          <h4 className="mb-2 font-weight-bolder">Token</h4>
          <div className="form form--horizontal form_spacing">
            <Input
              {...requiredProps}
              name="tokenTimeoutMins"
              label="Token Timeout (Minutes)"
              maxLength={2}
              rules={{
                required: msgs.fieldError,
                validate: validateTokenTimeout,
              }}
            />
            <h4 className="mt-3 mb-2 font-weight-bolder">SMTP</h4>
            <Input
              {...requiredProps}
              name="smtpHost"
              label="SMTP Host"
              maxLength={500}
            />
            <Input
              {...requiredProps}
              name="smtpPort"
              label="SMTP Port"
              maxLength={10}
            />
            <Input
              {...requiredProps}
              name="smtpUserName"
              label="SMTP Username"
              maxLength={100}
            />
            <Input
              {...requiredProps}
              name="smtpPassword"
              label="SMTP Password"
              maxLength={100}
            />
            <h4 className="mt-3 mb-2 font-weight-bolder">System</h4>
            <Select
              {...requiredProps}
              label="System User"
              name="systemUserID"
              isLoading={users?.requesting}
              errorMessage={users?.err}
              options={userOptions}
            />
            <FormFooterActions {...formFooterProps} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ConfigForm;
