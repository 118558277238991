import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: false,
  err: '',
  wft1002: {},
};

const wft1002Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.WFT1002_REQUESTING:
    return {
      ...state, requesting: true, err: '', wft1002: '',
    };

  case ActionTypes.WFT1002_ERROR:
    return {
      ...state, requesting: false, err: payload, wft1002: '',
    };

  case ActionTypes.WFT1002_FETCHED:
    return {
      ...state, requesting: false, err: '', wft1002: payload,
    };

  default:
    return state;
  }
};

export default wft1002Reducer;
