import { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { FormFooterActions, Input } from '@atoms';
import {
  formatCurrency,
  formatDate,
  formatDateWithoutTime,
  ssnFormat,
} from '@containers/components/helpers';

import AccountInformation from './AccountInformation';
import AddressTable from './AddressTable';
import DocumentVaultTable from './DocumentVaultTable';
import PioElectionsTable from './PioElectionsTable';
import PipRatesTable from './PipRatesTable';
import PolicyInformation from './PolicyInformation';
import SubAccountsTable from './SubAccountsTable';

const accountsTabs = [
  'Account Information',
  'Policy Information',
  'Subaccounts',
  'Addresses',
  'PIO Elections',
  'PIP Rates',
  'Document Vault',
];

const accountFormFields = [
  { label: 'Account Number', name: 'reAccountNumber' },
  { label: 'Account Status', name: 'accountStatus' },
  { label: 'Participant Name', name: 'participantName' },
  { label: 'SSN', name: 'ssNumber' },
  { label: 'Plan Name', name: 'planName' },
  { label: 'Product Name', name: 'productName' },
  { label: 'Tax Code', name: 'taxCode' },
];

const EditAccountForm = ({
 data, id, from, commonPath, onSubmit,
}) => {
  const accountsForm = useForm();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');

  const { control, reset, handleSubmit } = accountsForm;

  const navigateToNewTxnPage = () => history.push(`/accounts/${id}/withdrawal/create?from=${from}${commonPath}`);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const formattedCurrencyValue = (field, value) => ({
      [field]: value && value !== 0 ? formatCurrency(value) : '$ 0.00',
    });

    if (data) {
      const formattedData = {
        ...data,
        ssNumber: ssnFormat(data.ssNumber),

        // account information
        dob: formatDateWithoutTime(data.dob),
        accountCreatedON: formatDate(data.accountCreatedON),
        custodianAccountDate: formatDate(data.custodianAccountDate),

        // policy information
        policyPOVDate: formatDateWithoutTime(data.policyPOVDate),
        policyApplicationDate: formatDateWithoutTime(
          data.policyApplicationDate,
        ),
        policyIssueDate: formatDateWithoutTime(data.policyIssueDate),
        policySurrenderDate: formatDateWithoutTime(data.policySurrenderDate),
        policySurrenderFeeExpDate: formatDateWithoutTime(
          data.policySurrenderFeeExpDate,
        ),
        policyTerminationDate: formatDateWithoutTime(
          data.policyTerminationDate,
        ),
        policyMaturityDate: formatDateWithoutTime(data.policyMaturityDate),
        policyMaxAnnuitizationDate: formatDateWithoutTime(
          data.policyMaxAnnuitizationDate,
        ),
        policyAnnuitizationDate: formatDateWithoutTime(
          data.policyAnnuitizationDate,
        ),
        policyRepatriationDate: formatDateWithoutTime(
          data.policyRepatriationDate,
        ),

        ...formattedCurrencyValue(
          'policyAccumulationValue',
          data?.policyAccumulationValue || 0,
        ),
        ...formattedCurrencyValue(
          'policyGuaranteedBenefitBase',
          data?.policyGuaranteedBenefitBase || 0,
        ),
        ...formattedCurrencyValue('policyRMDValue', data?.policyRMDValue || 0),
        ...formattedCurrencyValue(
          'policySurrenderValue',
          data?.policySurrenderValue || 0,
        ),
        ...formattedCurrencyValue(
          'policyYearEndValue',
          data?.policyYearEndValue || 0,
        ),
        ...formattedCurrencyValue(
          'policyActuarialPV',
          data?.policyActuarialPV || 0,
        ),
        ...formattedCurrencyValue(
          'policyTotalPremiumPaid',
          data?.policyTotalPremiumPaid || 0,
        ),
        ...formattedCurrencyValue(
          'policyTotalWithdrawals',
          data?.policyTotalWithdrawals || 0,
        ),
        ...formattedCurrencyValue('povAdjustment', data?.povAdjustment || 0),
      };

      reset(formattedData);
    }
  }, [reset, data]);

  const tabContents = [
    <AccountInformation control={control} />,
    <PolicyInformation control={control} />,
    <SubAccountsTable id={id} />,
    <AddressTable id={id} />,
    <PioElectionsTable id={id} />,
    <PipRatesTable id={id} />,
    <DocumentVaultTable id={id} />,
  ];

  const buttonActions = isAccountsTab => ({
    buttonProps: [
      isAccountsTab
        ? [
            { labelName: 'New Txn', onClick: navigateToNewTxnPage },
            { labelName: 'Save', onClick: handleSubmit(onSubmit) },
          ]
        : [],
    ],
  });

  return (
    <div className="form form--horizontal">
      <Card>
        <CardBody className="px-1 py-3">
          {accountFormFields?.map(({ label, name }) => (
            <Input
              className="mb-2"
              key={label}
              control={control}
              name={name}
              isReadOnly
              label={label}
            />
          ))}
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="tabs tabs--justify tabs--bordered-top">
            <div className="tabs__wrap">
              <Nav tabs>
                {accountsTabs?.map((tab, index) => (
                  <NavItem key={tab}>
                    <NavLink
                      className={classnames({
                        active: activeTab === `${index + 1}`,
                      })}
                      onClick={() => toggle(`${index + 1}`)}
                    >
                      {tab}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={activeTab}>
                {tabContents?.map((component, index) => {
                  const isAccountsTab = index === 0;
                  return (
                    <TabPane
                      tabId={`${index + 1}`}
                      key={`${component} ${index}`}
                    >
                      <div>
                        {component}
                        <FormFooterActions
                          linkProps={[
                            { path: `/accounts?page=${from}${commonPath}` },
                          ]}
                          {...buttonActions(isAccountsTab)}
                        />
                      </div>
                    </TabPane>
                  );
                })}
              </TabContent>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditAccountForm;
