import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Badge, Container } from 'reactstrap';

import { conf } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import { cpStateReset } from '@redux/actions/cpActions';
import { reset } from '@redux/reducers/formFieldsReducer';

import AccountTable from './components/AccountTable';

const Accounts = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const [exportSearch, setExportSearch] = useState('');
  const [exportOrderBy, setExportOrderBy] = useState('');

  const { token, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [exporting, setExporting] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvClickRef = useRef();

  useEffect(() => {
    dispatch(cpStateReset());
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (csvData.length && exporting) {
      csvClickRef.current.link.click();
      setExporting(false);
    }
  }, [csvData, exporting]);

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="text-green">Retirement Edge Accounts</h3>
          <div>
            <button
              className="btn btn-success"
              onClick={() => setRefresh(true)}
            >
              Refresh
            </button>
            <span
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                setExporting(true);
                await axios
                  .get(
                    conf.serverURL
                      + 'reaccounts/export?search='
                      + exportSearch
                      + '&orderBy='
                      + exportOrderBy,
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        accessToken: token,
                      },
                    },
                  )
                  .then((resp) => {
                    // const data = resp.data.response;

                    // data.forEach((item) => {
                    //   item.alertSet = item.alertSet ? "True" : "False";
                    //   // setCsvData((currentState) => {
                    //   //   return [...currentState, item];
                    //   // });
                    // });

                    setCsvData(resp.data.response);
                    // alert(JSON.stringify(csvData));
                    // if (csvData) {
                    //   csvClickRef.current.link.click();
                    //   setExporting(false);
                    // }
                  })
                  .catch((err) => {
                    setExporting(false);
                    if (err.response?.status === 401) {
                      logout();
                      // // [TODO: Arun]
                      // eslint-disable-next-line no-undef
                      enqueueSnackbar(msgs.authError, {
                        variant: 'error',
                        autoHideDuration: 5000,
                      });
                      history.push('/');
                    } else {
                      enqueueSnackbar(
                        err.response?.data.message || err.message,
                        {
                          variant: 'error',
                          autoHideDuration: 5000,
                        },
                      );
                    }
                  });
              }}
            >
              <button className="btn btn-primary">
                {exporting ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  'Export'
                )}
              </button>
            </span>
            <CSVLink
              filename="REAccountsList.csv"
              style={{ display: 'none' }}
              ref={csvClickRef}
              data={csvData}
            >
              <Badge color="primary">Export</Badge>
            </CSVLink>
          </div>
        </div>
        <AccountTable
          refresh={refresh}
          setRefresh={setRefresh}
          setExportSearch={setExportSearch}
          setExportOrderBy={setExportOrderBy}
        />
      </Container>
    </div>
  );
};

export default Accounts;
