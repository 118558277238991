import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  error: '',
  cls: {},
  cl: {},
  success: '',
};

const clsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.CLS_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.CLS_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.CLS_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      cls: payload,
      cl: '',
      success: '',
    };

  case ActionTypes.CL_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      cl: payload,
      success: '',
    };

  case ActionTypes.CL_UPDATED:
    return {
      ...state,
      requesting: false,
      err: '',
      success: payload,
    };

  default:
    return state;
  }
};

export default clsReducer;
