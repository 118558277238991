import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { sanitizeNumber } from '@lib/number';
import { getSinglePlan, updatePlan } from '@redux/actions/plansActions';

import PlanForm from '../PlanForm/PlanForm';

const EditPlans = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { token, logout } = useAuth();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  const plans = useSelector(state => state.plans);
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const page = getQueryParam('from');
  const search = getQueryParam('search');

  const sort = {
    sortPlanName: getQueryParam('sortPlanName'),
    sortStatus: getQueryParam('sortStatus'),
  };

  const pageSize = getQueryParam('pageSize');

  useEffect(() => {
    if (token) {
      dispatch(getSinglePlan(id, token));
    }
    setValid(true);
  }, [id]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      planName,
      carrierPlanIdentifier,
      address1,
      city,
      zip,
      custodialTpaCode,
      custodialTrustAccount,
      rkAccount,
      federalTaxID,
    } = values;

    const isValid = validateRequiredField({
      'plan Name': planName,
      'plan Identifier': carrierPlanIdentifier,
      'Address 1': address1,
      City: city,
      Zip: zip,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      federalTaxID: federalTaxID ? sanitizeNumber(federalTaxID) : '',
      custodialTpaCode: !custodialTpaCode?.length ? null : custodialTpaCode,
      custodialTrustAccount: !custodialTrustAccount?.length
        ? null
        : custodialTrustAccount,
      rkAccount: !rkAccount?.length ? null : rkAccount,
    };

    dispatch(updatePlan(id, payloadValues, token));
  };

  const data = plans?.plan || {};

  const redirectUrl = useMemo(() => {
    const { sortPlanName, sortStatus } = sort || {};
    const queryParams = buildQueryParams({
      search,
      sortPlanName,
      sortStatus,
      pageSize,
    });
    return `/plans?page=${page}${queryParams}`;
  }, [page, search, sort, pageSize]);

  useEffect(() => {
    if (valid) {
      if (plans?.err) {
        if (unauthorizedError(plans)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(plans.err || msgs.error);
      } else if (plans?.success) {
        showSuccessToast();
        history.push(redirectUrl);
      }
    }
  }, [valid, plans, history]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Plan Detail</h3>

      <FormSpinner requesting={plans?.requesting} />
      <PlanForm onSubmit={handleForm} redirectUrl={redirectUrl} data={data} />
    </Container>
  );
};

export default EditPlans;
