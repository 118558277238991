import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import {
  sanitizeValue,
  splitStringyRate,
  unauthorizedError,
} from '@containers/components/helpers';
import { FormSpinner } from '@containers/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { getSinglePIO, updatePIO } from '@redux/actions/pioActions';

import PIOForm from '../PIOForm/PIOForm';

const EditPIO = (props) => {
  const {
    match: {
      params: { id, pioId },
    },
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, logout } = useAuth();
  const pio = useSelector(state => state.pio);
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const { showErrorToast, showSuccessToast, validateRequiredField } = useToast();

  const [valid, setValid] = useState(false);

  const page = getQueryParam('from');
  const pageSize = getQueryParam('pageSize');

  const sort = useMemo(() => (
    {
      sortProductName: getQueryParam('sortProductName'),
      sortCarrierName: getQueryParam('sortCarrierName'),
    }
  ), []);

  useEffect(() => {
    if (token) {
      dispatch(getSinglePIO(token, pioId));
    }
    setValid(true);
  }, [pioId]);

  const data = pio?.pio;

  const redirectToProducts = useMemo(
    () => {
      const { sortCarrierName, sortProductName } = sort || {};
      const queryParams = buildQueryParams({ sortProductName, sortCarrierName, pageSize });

      return `/products?id=${id}&page=${page}${queryParams}`;
    },
    [id, page, sort, pageSize],
  );

  useEffect(() => {
    if (valid) {
      if (pio?.err) {
        if (unauthorizedError(pio)) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(pio.err || msgs.error);
      } else if (pio?.success) {
        showSuccessToast();
        history.push(redirectToProducts);
      }
    }
  }, [pio, valid, history]);

  const handleForm = (values) => {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === 'string') {
        values[key] = sanitizeValue(values[key]);
      }
    });

    const {
      pioName,
      carrierPIOCode,
      pioCreditingRate,
      pioParticipationRate,
      pioMinRate,
      pioMaxRate,
      fiaCapRate,
    } = values || {};

    const isValid = validateRequiredField({
      'Investment Option': pioName,
      'Investment Option Code': carrierPIOCode,
    });

    if (!isValid) {
      return;
    }

    const payloadValues = {
      ...values,
      pioCreditingRate: splitStringyRate(pioCreditingRate) || '',
      pioParticipationRate: splitStringyRate(pioParticipationRate) || '',
      fiaCapRate: splitStringyRate(fiaCapRate) || '',
      pioMinRate: splitStringyRate(pioMinRate) || '',
      pioMaxRate: splitStringyRate(pioMaxRate) || '',
    };

    dispatch(updatePIO(token, pioId, payloadValues));
    setValid(true);
  };

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Product Investment Option Detail</h3>

      <FormSpinner requesting={pio?.requesting} />
      <PIOForm
        data={data}
        onSubmit={handleForm}
        redirectUrl={redirectToProducts}
      />
    </Container>
  );
};

export default EditPIO;
