import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';

import { Input, Checkbox } from '@atoms';
import { msgs } from '@config/conf';

const EditCustodianPlanForm = ({ data, custodianId }) => {
  const { control, reset, handleSubmit } = useForm();

  const requiredProps = {
    control,
    required: true,
    rules: {
      required: msgs.fieldError,
    },
  };

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onSubmit = (values) => {
    alert(JSON.stringify(values));
  };

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          <Input
            {...requiredProps}
            label="Plan Name"
            name="planName"
            placeholder="Plan Name"
          />
          <Input
            {...requiredProps}
            label="Federal Tax Id"
            name="federalTaxId"
            placeholder="Federal Tax Id"
          />
          <Input
            {...requiredProps}
            label="Plan Contact Name"
            name="planContactName"
            placeholder="Plan Contact Name"
          />
          <Input
            control={control}
            label="Plan Phone"
            name="planPhone"
            placeholder="Plan Phone"
          />
          <Input
            control={control}
            label="Plan Email"
            name="planEmail"
            placeholder="Plan Email"
          />
          <Input
            control={control}
            label="Custodian Plan Code"
            name="custodianPlanCode"
            placeholder="Custodian Plan Code"
          />
          <Input
            {...requiredProps}
            label="Routing Number"
            name="routingNumber"
            placeholder="Routing Number"
          />
          <Input
            {...requiredProps}
            label="Account Number"
            name="accountNumber"
            placeholder="Account Number"
          />
          <Checkbox control={control} name="active" label="Active" />
          <div className="w-100 flex justify-content-end">
            <Link
              to={`/custodians/${custodianId}/plans`}
              className="btn btn-outline-danger"
            >
              Cancel
            </Link>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>
              Update Plan
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default EditCustodianPlanForm;
