import axios from 'axios';

import { conf } from '@config/conf';

import * as ActionTypes from './actionTypes';

export const getPaws = (
  token,
  limit = 0,
  offset = 0,
  orderBy = null,
  search = null,
  status = null,
  custodianID = null,
) => async (dispatch) => {
  dispatch({ type: ActionTypes.PAWS_REQUESTING });

  await axios
    .get(
      conf.serverURL
          + `operations/pandw?Limit=${limit}&Offset=${offset}${
            !orderBy ? '' : '&OrderBy=' + orderBy
          }${!search ? '' : '&search=' + search}${
            !status ? '' : '&status=' + status
          }${!custodianID ? '' : '&custodianID=' + custodianID}`,
      {
        headers: {
          'Content-Type': 'application/json',
          accessToken: token,
        },
      },
    )
    .then((resp) => {
      dispatch({
        type: ActionTypes.PAWS_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PAWS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};

export const getSinglePaW = (token, id) => async (dispatch) => {
  await axios
    .get(conf.serverURL + `operations/pandw/detail/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        accessToken: token,
      },
    })
    .then((resp) => {
      dispatch({
        type: ActionTypes.PAW_FETCHED,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.PAWS_ERROR,
        payload: err.response
          ? err.response.status === 401
            ? err.response.status
            : err.response.data.message
          : err.message,
      });
    });
};
