import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { msgs } from '@config/conf';
import FormSpinner from '@containers/FormSpinner/FormSpinner';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import useToast from '@hooks/useToast';
import { getSinglePaW } from '@redux/actions/pawsActions';

import EditPandWsForm from './component/EditPandWsForm';

const EditPandWs = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const purchaseWithdrawals = useSelector(state => state.paws);
  const { token, logout } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();

  const [valid, setValid] = useState(false);

  const page = getQueryParam('page') || 1;
  const search = {
    pwSearch: getQueryParam('pwSearch') || '',
    pwStatus: getQueryParam('pwStatus') || '',
    pwCustodian: getQueryParam('pwCustodian') || '',
  };

  useEffect(() => {
    if (token) {
      dispatch(getSinglePaW(token, id));
      setValid(true);
    }
  }, [id]);

  const data = purchaseWithdrawals?.paw?.data || {};

  const redirectUrl = useMemo(() => {
    const { pwCustodian, pwSearch, pwStatus } = search || {};
    const queryParams = buildQueryParams({
      pwSearch,
      pwStatus,
      pwCustodian,
    });

    return `/purchases-and-withdrawals?from=${page}${queryParams}`;
  }, [page, search]);

  useEffect(() => {
    if (valid) {
      if (purchaseWithdrawals?.err) {
        if (purchaseWithdrawals.err === 401) {
          logout();
          showErrorToast(msgs.authError);
          history.push('/');
        }
        showErrorToast(purchaseWithdrawals.err || msgs.error);
      } else if (purchaseWithdrawals?.success) {
        showSuccessToast();
        history.push(redirectUrl);
      }
    }
  }, [valid, purchaseWithdrawals]);

  return (
    <Container className="form_wrapper">
      <h3 className="mb-3 text-center">Purchases &amp; Withdrawals Detail</h3>

      <FormSpinner requesting={purchaseWithdrawals?.requesting} />

      <EditPandWsForm data={data} redirectUrl={redirectUrl} />
    </Container>
  );
};

export default EditPandWs;
