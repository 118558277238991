import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Card, CardBody, Badge } from 'reactstrap';

import { Spinner, Table } from '@atoms';
import { pagination } from '@config/conf';
import { getPagination } from '@containers/components/helpers';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { formatDate, getRelativeDate } from '@lib/date';
import { formatFixedDigits } from '@lib/number';
import { getWorkflows } from '@redux/actions/wfsActions';

import WorkflowsTableSearch from './WorkflowsTableSearch';

const workflowLogsColumns = [
  {
    id: 'workflowsLogDate',
    header: 'Log Date',
    accessorFn: row => formatDate(row?.workflowsLogDate, 'MM/DD/YY hh:mm A'),
  },
  {
    id: 'workflowsLogStatus',
    header: 'Status',
    accessorFn: row => `${row?.workflowsLogStatus} / ${row?.workflowsLogSubStatus}`,
  },
  {
    id: 'workflowsLogStatusMessage',
    header: 'Message',
    accessorKey: 'workflowsLogStatusMessage',
  },
];

const WorkflowLogTable = (subTableProps) => {
  const { workflowLogs } = subTableProps.row.original || {};
  return (
    <Table columns={workflowLogsColumns} data={workflowLogs} hasNestedTable />
  );
};

const defaultFromDate = getRelativeDate(7, 'days', 'previous');
const defaultToDate = formatDate();

const WorkflowsTable = (props) => {
  const { refresh, setRefresh } = props || {};

  const { token } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getQueryParam, buildQueryParams } = useQueryParams();
  const workflowData = useSelector(state => state.wfs);

  const [pageSize, setPageSize] = useState(
    getQueryParam('pageSize') || pagination.length,
  );
  const [searchInput, setSearchInput] = useState(
    getQueryParam('searchInput') || '',
  );
  const [wfType, setWfType] = useState(getQueryParam('wfType') || '');
  const [fromDate, setFromDate] = useState(
    getQueryParam('fromDate') || defaultFromDate,
  );
  const [toDate, setToDate] = useState(
    getQueryParam('toDate') || defaultToDate,
  );
  const [statusInput, setStatusInput] = useState(
    getQueryParam('statusInput') || '',
  );
  const [submitSearch, setSubmitSearch] = useState(false);

  const page = getQueryParam('page') || '';
  const sortWFNumber = getQueryParam('sortWFNumber') || '';
  const sortWType = getQueryParam('sortWType') || '';
  const sortWDate = getQueryParam('sortWDate') || '';
  const sortReAccountNumber = getQueryParam('sortReAccountNumber') || '';
  const { offset, pageNumber } = getPagination(pageSize, page) || {};

  const {
    err,
    requesting,
    wfs: { data, recordCount },
  } = workflowData || {};

  const refreshString = useCallback(() => {
    if (sortWType) {
      return `WorkflowTypes.WorkflowType ${sortWType.toUpperCase()}, Workflows.WorkflowNumber ASC, WorkflowsLog.LogDate ASC`;
    }
    if (sortWFNumber) {
      return `Workflows.WorkflowNumber ${sortWFNumber.toUpperCase()}, WorkflowsLog.LogDate ASC`;
    }
    if (sortWDate) {
      return `Workflows.WorkflowStartDate ${sortWDate.toUpperCase()}, Workflows.WorkflowNumber ASC, WorkflowsLog.LogDate ASC`;
    }
    if (sortReAccountNumber) {
      return `REAccounts.REAccountNumber ${sortReAccountNumber.toUpperCase()}, Workflows.WorkflowNumber ASC, WorkflowsLog.LogDate ASC`;
    }
    return '';
  }, [sortWType, sortWFNumber, sortWDate, sortReAccountNumber]);

  const refStr = refreshString();

  const redirectUrl = useCallback(
    (workflowID = '') => {
      const queryPath = buildQueryParams({
        searchInput,
        wfType,
        statusInput,
        fromDate,
        toDate,
        sortWFNumber,
        sortWType,
        sortWDate,
        sortReAccountNumber,
        pageSize,
      });
      return `/workflows${
        workflowID ? `/${workflowID}/edit?from=${pageNumber}` : '?page=1'
      }${queryPath}`;
    },
    [
      fromDate,
      pageNumber,
      pageSize,
      searchInput,
      sortReAccountNumber,
      sortWDate,
      sortWFNumber,
      sortWType,
      statusInput,
      toDate,
      wfType,
    ],
  );

  useEffect(() => {
    if (submitSearch) {
      const pageReload = redirectUrl();
      history.push(pageReload);
    } else if (token) {
      dispatch(
        getWorkflows(
          token,
          pageSize,
          offset,
          refStr,
          searchInput,
          wfType,
          fromDate,
          toDate,
          statusInput,
        ),
      );
    }
    setSubmitSearch(false);
    setRefresh(false);
  }, [offset, pageSize, refStr, refresh, submitSearch]);

  const workflowsColumns = useMemo(
    () => [
      {
        id: 'expanded-chevron',
        header: '',
        size: 0,
        cell: (tableRow) => {
          const { expandedRowIds, row } = tableRow || {};
          const className = `lnr ${
            expandedRowIds?.includes(row.id)
              ? 'lnr-chevron-down'
              : 'lnr-chevron-right'
          }`;
          return (
            <div className="expanded_chevron">
              <i className={className} />
            </div>
          );
        },
      },
      {
        id: 'alert',
        header: '',
        size: 0,
        cell: (tableRow) => {
          const { alertSet } = tableRow?.row?.original || {};
          return alertSet ? (
            <div
              className="bg-danger text-danger mb-0 rounded-circle mt-1"
              style={{
                height: '10px',
                width: '10px',
              }}
            />
          ) : null;
        },
      },
      {
        id: 'wFNumber',
        enableSorting: true,
        header: 'WF#',
        accessorFn: row => formatFixedDigits(row?.workflowNumber, 12),
      },
      {
        id: 'wDate',
        enableSorting: true,
        header: 'Start Date',
        accessorFn: row => formatDate(row?.workflowStartDate, 'MM/DD/YY hh:mm A'),
      },
      {
        id: 'wType',
        enableSorting: true,
        header: 'Type',
        accessorKey: 'workflowType',
        size: 250,
      },
      {
        id: 'reAccountNumber',
        enableSorting: true,
        header: 'Account',
        accessorFn: row => row?.reAccountNumber || '-',
      },
      {
        id: 'policyNumber',
        header: 'Policy',
        accessorFn: row => row?.policyNumber || '-',
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: row => `${row?.status || '-'} / ${row?.subStatus || '-'}`,
      },
      {
        id: 'actions',
        header: '',
        cell: (tableRow) => {
          const { workflowID } = tableRow?.row?.original || {};
          const navigateToEditPage = redirectUrl(workflowID);
          return (
            <div className="column-end pr-2 my-1">
              <Link to={navigateToEditPage}>
                <Badge className="btn-blue btn-badge align-middle">
                  View / Edit
                </Badge>
              </Link>
            </div>
          );
        },
      },
    ],
    [redirectUrl],
  );

  const pageProps = {
    total: recordCount,
    pageSize,
    setPageSize,
    pageNumber,
  };

  return (
    <Spinner requesting={requesting}>
      <Card className="pb-2">
        <CardBody className="py-2 bg-green">
          <WorkflowsTableSearch
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            wfType={wfType}
            setWfType={setWfType}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            statusInput={statusInput}
            setStatusInput={setStatusInput}
            setSubmitSearch={setSubmitSearch}
          />
        </CardBody>
      </Card>
      <Table
        columns={workflowsColumns}
        data={data}
        renderNestedTable={WorkflowLogTable}
        errStatus={err}
        pageProps={pageProps}
      />
    </Spinner>
  );
};

export default WorkflowsTable;
