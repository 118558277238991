import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Card, CardBody } from 'reactstrap';

import {
 Input, Select, Checkbox, FormFooterActions,
} from '@atoms';
import { msgs, statusOptions } from '@config/conf';
import {
  formatDateWithoutTime,
  formatToPercentage,
  validateMaxValue,
} from '@containers/components/helpers';

const PiosFormFields = [
  {
    label: 'Product Name',
    name: 'productName',
    isRequired: true,
    readOnly: true,
  },
  {
    label: 'Investment Option Name',
    name: 'pioName',
    maxLength: 200,
    isRequired: true,
  },
  {
    label: 'Investment Option Code',
    name: 'carrierPIOCode',
    maxLength: 50,
    isRequired: true,
  },
  {
    label: 'Method',
    name: 'pioMethod',
    maxLength: 50,
  },
  {
    label: 'Duration',
    name: 'pioDuration',
    maxLength: 50,
  },
  {
    label: 'Crediting Rate',
    name: 'pioCreditingRate',
    maxLength: 10,
    isRequired: true,
    type: 'percentage',
  },
  {
    label: 'Participation Rate',
    name: 'pioParticipationRate',
    maxLength: 10,
    isRequired: true,
    type: 'percentage',
    percentageMaxValue: 1000,
  },
  {
    label: 'Cap Rate',
    name: 'fiaCapRate',
    maxLength: 10,
    isRequired: true,
    type: 'percentage',
    formatter: { shouldPercentage: true, numericOnly: true },
  },
  {
    label: 'Minimum Rate',
    name: 'pioMinRate',
    maxLength: 10,
    isRequired: true,
    type: 'percentage',
    formatter: { shouldPercentage: true, numericOnly: true },
  },
  {
    label: 'Maximum Rate',
    name: 'pioMaxRate',
    maxLength: 10,
    isRequired: true,
    type: 'percentage',
    formatter: { shouldPercentage: true, numericOnly: true },
  },
];

const PioForm = ({ data, redirectUrl, onSubmit }) => {
  const { control, reset, handleSubmit } = useForm();

  const hasPioId = data?.pioid;

  const fieldPercentage = (fieldName) => {
    if (hasPioId) {
      if (fieldName) {
        return formatToPercentage(fieldName * 100);
      }
      return 'NA';
    }
    return '';
  };

  useEffect(() => {
    const {
      pioCreditingRate,
      pioParticipationRate,
      pioMinRate,
      pioMaxRate,
      fiaCapRate,
      pioLastUpdatedOn,
    } = data || {};

    reset({
      ...data,
      pioCreditingRate: fieldPercentage(pioCreditingRate),
      pioParticipationRate: fieldPercentage(pioParticipationRate),
      pioMinRate: fieldPercentage(pioMinRate),
      pioMaxRate: fieldPercentage(pioMaxRate),
      fiaCapRate: fieldPercentage(fiaCapRate),
      pioLastUpdatedOn: pioLastUpdatedOn
        ? formatDateWithoutTime(pioLastUpdatedOn)
        : '',
    });
  }, [reset, data]);

  const requiredProps = {
    control,
    required: true,
    rules: { required: msgs.fieldError },
  };

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          {PiosFormFields?.map(
            ({
              label,
              name,
              readOnly,
              maxLength,
              isRequired,
              type,
              percentageMaxValue,
            }) => {
              const commonProps = isRequired ? requiredProps : { control };

              if (type === 'percentage') {
                return (
                  <Input
                    key={name}
                    name={name}
                    label={label}
                    control={control}
                    maxLength={maxLength}
                    required={isRequired}
                    formatter={{ shouldPercentage: true, numericOnly: true }}
                    rules={{
                      required: msgs.fieldError,
                      validate: value => validateMaxValue(value, percentageMaxValue || 100),
                    }}
                  />
                );
              }
              return (
                <Input
                  key={name}
                  name={name}
                  label={label}
                  isReadOnly={readOnly}
                  maxLength={maxLength}
                  {...commonProps}
                />
              );
            },
          )}
          <Checkbox control={control} label="Selectable" name="pioSelectable" />
          <Select
            control={control}
            label="Status"
            name="status"
            options={statusOptions}
          />
          {hasPioId ? (
            <Input
              control={control}
              label="Last Updated"
              name="pioLastUpdatedOn"
              isReadOnly
            />
          ) : null}
          <FormFooterActions
            linkProps={[{ path: redirectUrl }]}
            buttonProps={[{ onClick: handleSubmit(onSubmit) }]}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PioForm;
