import React, { useEffect, useRef, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import { Spinner } from '@atoms';
import { INACTIVE_TIMEOUT } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useQueryParams from '@hooks/useQueryParams';
import { getAnnuity } from '@redux/actions/annuityActions';

import ViewAccountForm from './components/ViewAccountForm';

const ViewAccountSummary = ({
  match: {
    params: { id: accountNumber },
  },
}) => {
  const { annuityToken, annuityLogout } = useAuth();
  const dispatch = useDispatch();
  const annuityRes = useSelector(state => state.annuity);
  const history = useHistory();

  const { getQueryParam } = useQueryParams();
  const idleTimerRef = useRef(null);
  const [showError, setShowError] = useState(false);

  const {
    err,
    requesting,
    data: { data: annuityData = [] } = {},
  } = annuityRes || {};

  const token = getQueryParam('token');
  const decodedToken = decodeURI(token);
  const isInvalidSession = requesting && sessionStorage.length === 0 && !token;
  const hasAuthError = err === 'Unauthorized';

  const numberOfPolicies = annuityData?.length;
  let activeTab = Number(sessionStorage.getItem('activeTab'));

  const handleInvalidToken = () => {
    sessionStorage.setItem('validToken', false);
    annuityLogout();
  };

  useEffect(() => {
    if (hasAuthError) {
      handleInvalidToken();
    } else if (isInvalidSession) {
      setShowError(true);
    }

    const handleStorageChange = (event) => {
      if (event.storageArea === sessionStorage) {
        if (!sessionStorage.annuityAuthToken) {
          handleInvalidToken();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [hasAuthError, isInvalidSession]);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('annuityAuthToken', decodedToken);
      sessionStorage.setItem('reAccountNumber', accountNumber);
      sessionStorage.setItem('validToken', true);
      // default the managedFlag to false and terminationFlag to true for admin user so that they can see all links and buttons.
      sessionStorage.setItem('managedFlag', false);
      sessionStorage.setItem('terminationFlag', true);
      history.push(`/View/Account/Summary/${accountNumber}`);
    } else if (annuityToken) {
      dispatch(getAnnuity(annuityToken, accountNumber, true));
    }
  }, []);

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: INACTIVE_TIMEOUT,
    onIdle: () => {
      handleInvalidToken();
    },
  });

  if (!activeTab) {
    activeTab = numberOfPolicies > 1 ? 0 : 1;
  }

  if (hasAuthError) {
    return null;
  }

  return (
    <div idleTimer={idleTimer}>
      {!hasAuthError && (err || showError) ? (
        <div className="pt-5">
          <h3 className="text-center text-danger">
            {err || (showError && 'Invalid token')}
          </h3>
        </div>
      ) : (
        <Spinner requesting={requesting}>
          <Container>
            <ViewAccountForm
              token={annuityToken}
              id={accountNumber}
              invalidateAnnuitantToken={annuityLogout}
              activeTab={activeTab}
              data={annuityData}
            />
          </Container>
        </Spinner>
      )}
    </div>
  );
};

export default ViewAccountSummary;
