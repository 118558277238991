import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  dailySettlementSummary: {},
  success: '',
};

const dailySettlementSummaryReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
  case ActionTypes.DAILY_SETTLEMENT_SUMMARY_REQUESTING:
    return {
      ...state, requesting: true, err: '', success: '',
    };

  case ActionTypes.DAILY_SETTLEMENT_SUMMARY_ERROR:
    return {
      ...state, requesting: false, err: payload, success: '',
    };

  case ActionTypes.DAILY_SETTLEMENT_SUMMARY_FETCHED:
    return {
      ...state,
      requesting: false,
      err: '',
      dailySettlementSummary: payload,
      success: '',
    };

  default:
    return state;
  }
};

export default dailySettlementSummaryReducer;
