import React from 'react';

// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Badge, Table,
} from 'reactstrap';

import BasicTableData from './BasicTableData';

const { tableHeaderResponsiveData, tableRowsData } = BasicTableData();

const CarrierSubaccountsTable = ({ carrierId, productId }) => (
  // <Col md={12} lg={12}>
  <Card>
    <CardBody>
      {/* <div className="card__title">
          <h5 className="bold-text">{t('tables.basic_tables.responsive_table')}</h5>
          <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5>
        </div> */}
      <Table responsive className="table--bordered">
        <thead>
          <tr>
            {tableHeaderResponsiveData.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRowsData.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.first}</td>
              <td>{item.last}</td>
              <td>{item.username}</td>
              <td>{item.age}</td>
              <td>{item.date}</td>
              <td>{item.location}</td>
              {/* <td><Badge color={item.status_resp}>{item.badge_resp}</Badge></td> */}
              <td>
                <Link to={`/carriers/${carrierId}/products/${productId}/subaccounts/${item.id}`}>
                  <Badge color="primary">View / Edit</Badge>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
  // </Col>
);

CarrierSubaccountsTable.propTypes = {
  carrierId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
};

export default CarrierSubaccountsTable;
