import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';

import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import Container from 'reactstrap/lib/Container';

import { GROWTH_RATE, RETIREMENT_AGE } from '@config/conf';
import { formatCurrency } from '@containers/components/helpers';
import useApiFetch from '@hooks/useApiFetch';
import { calculateAge, formatDate } from '@lib/date';

import LeftSideIncomeProjections from './LeftSideIncomeProjections';
import RightSideIncomeProjections from './RightSideIncomeProjections';
import { getAsOfDate } from '../helpers';

const IncomeProjectionsDetails = ({ policyData }) => {
  const { get } = useApiFetch();
  const [lifetimeIncomeAnnuityData, setLifetimeIncomeAnnuityData] = useState({});

  const {
 dob, policyIssueDate, anniversaryBenefitBase, reAccountNumber,
} = policyData || {};

  const validParticipantAge = useMemo(() => {
    const currentAge = calculateAge(dob);
    return currentAge < RETIREMENT_AGE ? RETIREMENT_AGE : currentAge + 1;
  }, [dob]);

  const incomeBenefitForm = useForm({
    mode: 'onChange',
    defaultValues: {
      participantAge: validParticipantAge,
      spouseAge: RETIREMENT_AGE,
    },
  });

  const { spouseAge, participantAge, incomePaymentType } = incomeBenefitForm.watch();

  const getLIAincomeCalculation = useCallback(async () => {
    const isValid = reAccountNumber && participantAge >= 60 && spouseAge >= 60;
    const payloadValues = {
      ReAccountNumber: reAccountNumber,
      ParticipantDOB: formatDate(dob, 'YYYY-MM-DD'),
      ParticipantRetAge: parseInt(participantAge),
      SpouseRetAge: parseInt(spouseAge),
      PaymentType: incomePaymentType ? 'Joint' : 'Single',
      AnniversaryBenefitBase: anniversaryBenefitBase,
      GrowthRate: GROWTH_RATE,
    };

    const response = isValid
      ? await get('reaccounts/liaincomecalc', payloadValues)
      : {};
      setLifetimeIncomeAnnuityData(response);
  }, [incomePaymentType, participantAge, spouseAge, reAccountNumber]);

  useEffect(() => {
    getLIAincomeCalculation();
  }, [getLIAincomeCalculation]);

  const asOfDate = useMemo(
    () => getAsOfDate(dob, policyIssueDate),
    [dob, policyIssueDate],
  );

  return (
    <Container className="p-0">
      <Typography variant="h6" className="p-3">
        Lifetime Income Projections
      </Typography>
      <div className="flex">
        <LeftSideIncomeProjections
          form={incomeBenefitForm}
          incomeCalculationData={lifetimeIncomeAnnuityData}
          policyData={policyData}
        />
        <div className="border" />
        <RightSideIncomeProjections
          form={incomeBenefitForm}
          incomeCalculationData={lifetimeIncomeAnnuityData}
        />
      </div>
      <div className="p-1 mt-3">
        <p className="d-block lh-1">
          Each year, on your birthday, Allianz recalculates the Personal
          Lifetime Withdrawal Percentage based on activity in your account.
          Therefore, the Lifetime Income Value, Lifetime Withdrawal Percentage
          and projections above use the values from your last anniversary (or
          initial contribution if annuity is less than a year old). Withdrawals,
          contributions and interest earned are reflected in the projections at
          the next anniversary. The Personal Lifetime Withdrawal Percentage is
          multiplied by the Lifetime Income Value to determine the guaranteed
          retirement income amount payable each year.{' '}
          <span className="font-weight-bold font-italic">
            Your Lifetime Income Value as of {asOfDate} is{' '}
            {formatCurrency(anniversaryBenefitBase)} which is used together with
            the Personal Lifetime Withdrawal Percentage to calculate your
            projected monthly income above.
          </span>
        </p>
      </div>
    </Container>
  );
};

export default IncomeProjectionsDetails;
