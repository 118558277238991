import * as ActionTypes from '../actions/actionTypes';

const initialState = {
  requesting: true,
  err: '',
  config: [],
  formError: '',
  success: '',
};

const configReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ActionTypes.CONFIG_REQUESTING:
    return {
      ...state, requesting: true,
    };

  case ActionTypes.CONFIG_ERROR:
    return {
      ...state, requesting: false, err: payload,
    };

  case ActionTypes.CONFIG_FETCHED:
    return {
      ...state, requesting: false, err: '', config: payload, success: '',
    };

  case ActionTypes.CONFIG_FORM_ERROR:
    return {
      ...state, requesting: false, err: '', success: '', formError: payload,
    };

  case ActionTypes.CONFIG_ADDED:
    return {
      ...state, requesting: false, config: payload, success: 'Config Added Successfully',
    };

  case ActionTypes.CONFIG_UPDATED:
    return {
      ...state, requesting: false, config: payload, success: 'Config Updated Successfully',
    };

  default:
    return state;
  }
};

export default configReducer;
