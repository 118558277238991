import { Container } from 'reactstrap';

import ImportPandWsForm from './components/ImportPandWsForm';

const data = {
  custodianID: localStorage.getItem('custodianID'),
};

const ImportPandWs = () => (
  <Container className="form_wrapper">
    <h3 className="mb-3 text-center">Import Withdrawals & Purchases</h3>

    <ImportPandWsForm data={data} />
  </Container>
);

export default ImportPandWs;
