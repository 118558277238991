import { Controller } from 'react-hook-form';

const IncomePaymentTypeToggle = ({ name, control, defaultValue }) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <>
        <p className="text-right font-weight-bold mr-2">Income Payment Type</p>
        <div className="annuity-switch">
          <p className="font-weight-bold">Single</p>
          <label className="toggle-btn customizer__toggle">
            <input {...field} className="toggle-btn__input" type="checkbox" />
            <span className="toggle-btn__input-label" />
          </label>
          <p className="font-weight-bold m-0">Joint</p>
        </div>
      </>
    )}
  />
);

export default IncomePaymentTypeToggle;
