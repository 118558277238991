import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';

import { PasswordInput } from '@atoms';
import { msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import useToast from '@hooks/useToast';
import {
  changePassword as passwordChange,
} from '@redux/actions/cpActions';

const InfoTooltipComponent = ({ id }) => (
  <>
    <span className="lnr lnr-question-circle text-dark ml-1" id={id} />
    <UncontrolledTooltip placement="right" target={id}>
      <p className="mb-0 text-left">Password</p>
      <ul>
        <li className="text-left">Must be in between 8 to 20 characters.</li>
        <li className="text-left">
          Must contain lowercase letters, uppercase letters, numbers and special
          characters from !@#$%^&*().
        </li>
      </ul>
    </UncontrolledTooltip>
  </>
);

const ChangePasswordForm = () => {
  const { control, handleSubmit, watch } = useForm();
  const { user, token, logout } = useAuth();
  const { showErrorToast, showSuccessToast } = useToast();

  const changePassword = useSelector(state => state.cp);
  const history = useHistory();
  const dispatch = useDispatch();

  const newPassword = watch('newPassword');

  useEffect(() => {
    if (changePassword?.err) {
      if (changePassword?.err === 401) {
        logout();
        showErrorToast(msgs.authError);
        history.push('/');
      } else {
        showErrorToast(changePassword.err || msgs.error);
      }
    } else if (changePassword?.success) {
      showSuccessToast();
      history.push('/workflows');
    }
  }, [changePassword]);

  const handleForm = (values) => {
    dispatch(passwordChange(user.UserId, token, values));
  };

  const requiredProps = {
    control,
    required: true,
  };

  const validatePassword = useCallback(
    (value, type) => {
      if (value.length < 8) {
        return 'Must be greater or equal to 8 characters.';
      }
      if (value.length > 20) {
        return 'Must be greater or equal to 20 characters.';
      }
      if (type === 'confirm' && newPassword !== value) {
        return 'Must match your new password.';
      }
      return true;
    },
    [newPassword],
  );

  return (
    <Card className="container_width">
      <CardBody>
        <div className="form form--horizontal form_spacing">
          <PasswordInput
            {...requiredProps}
            name="currentPassword"
            label="Current Password"
            rules={{ required: msgs.fieldError }}
          />
          <PasswordInput
            {...requiredProps}
            name="newPassword"
            label="New Password"
            InfoTooltipComponent={<InfoTooltipComponent id="new_password" />}
            rules={{
              required: msgs.fieldError,
              validate: validatePassword,
            }}
          />
          <PasswordInput
            {...requiredProps}
            name="confirmNewPassword"
            label="Confirm New Password"
            InfoTooltipComponent={<InfoTooltipComponent id="confirm_new_password" />}
            rules={{
              required: msgs.fieldError,
              validate: value => validatePassword(value, 'confirm'),
            }}
          />
          <div className="w-100 flex justify-content-between">
            <Link to="/workflows" className="btn btn-outline-danger">
              Cancel
            </Link>
            <button
              onClick={handleSubmit(handleForm)}
              className="btn btn-primary"
            >
              Change Password
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChangePasswordForm;
