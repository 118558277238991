import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Input } from 'reactstrap';

import { msgs } from '@config/conf';
import { useAuth } from '@contexts/AuthContext';
import { getCustodians } from '@redux/actions/custodiansActions';

const PurchasesWithdrawalsSearch = ({
  pwSearch,
  setPwSearch,
  pwStatus,
  setPwStatus,
  pwCustodian,
  setPwCustodian,
  setSubmitSearch,
}) => {
  const [valid, setValid] = useState(false);
  const { token, logout } = useAuth();
  const dispatch = useDispatch();
  const custodians = useSelector(state => state.custodians);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    if (valid) {
      if (custodians?.err === 401) {
        logout();
        enqueueSnackbar(msgs.authError, {
          variant: 'error',
          autoHideDuration: 5000,
        });
        history.push('/');
      }
    }
  }, [dispatch, custodians, valid, history]);

  useEffect(() => {
    dispatch(getCustodians(token));
    setValid(true);
  }, [dispatch]);

  const handleClear = (e) => {
    e.preventDefault();
    setSubmitSearch(true);
    setPwSearch('');
    setPwStatus('');
    setPwCustodian('');
  };
  return (
    <div className="table__search mb-0">
      <Form
        inline
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitSearch(true);
        }}
      >
        <Input
          type="text"
          placeholder="WF#"
          maxLength="20"
          className="table__search table__search-input mb-0"
          value={pwSearch}
          onChange={e => setPwSearch(e.target.value)}
        />
        <Input
          type="select"
          className="table__search table__search-input mb-0"
          value={pwStatus}
          onChange={e => setPwStatus(e.target.value)}
        >
          <option value="" />
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Failed">Failed</option>
          <option value="Error">Error</option>
        </Input>
        <Input
          type="select"
          className="table__search table__search-input mb-0"
          value={pwCustodian}
          onChange={e => setPwCustodian(e.target.value)}
        >
          <option value="" />
          {valid && custodians?.requesting && (
            <option value="">Loading Custodians...</option>
          )}
          {valid && custodians?.err && (
            <option value="">{custodians.err}</option>
          )}
          {valid
            && custodians?.custodians?.data?.map((item, index) => (
              <option value={item.custodianID} key={index}>
                {item.custodianName}
              </option>
            ))}
        </Input>
        <Input
          type="submit"
          className="btn-blue btn-w text-light table__search table__search-input mb-0"
          value="Filter"
        />
        {(pwSearch || pwStatus || pwCustodian) && (
          <Input
            type="reset"
            className="btn-w text-light table__search table__search-input mb-0"
            value="Clear"
            onClick={handleClear}
          />
        )}
      </Form>
    </div>
  );
};

export default PurchasesWithdrawalsSearch;
